@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/variables';

.SubscriptionWrapper {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;

  @media (max-width: $breakpoint-tablet-max) {
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
  }
}

.SubscriptionForm {
  @media (min-width: $breakpoint-tablet-min) {
    max-width: 60%;
    flex: 60% 0 0;
  }
}

.SubscriptionSection {
  margin: $spacing-unit 0 2 * $spacing-unit;
}

.SubscriptionSectionTitle {
  display: flex;
  align-items: center;
  font-size: pxrem(20);
  padding: 0;
  margin: 0 0 3 * $spacing-unit;
}

$orderNumberSize: $spacing-unit * 4.5;

.OrderNumber {
  flex: $orderNumberSize 0 0;
  min-width: $orderNumberSize;
  width: $orderNumberSize;
  height: $orderNumberSize;
  margin-right: $spacing-unit * 2;

  border: 2px solid $color-primary-dark;
  color: $color-primary-dark;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $orderNumberSize * 0.5;
  font-weight: 800;
  border-radius: 50%;
  line-height: 1em;
  font-variant-numeric: tabular-nums;
}

// # Billing form section
.FormFieldContext {
  font-size: pxrem(14);
  line-height: 1.5;
  color: $color-neutral-dark;
  margin-top: $spacing-unit;

  a {
    text-decoration: underline;
  }

  .plainLink {
    color: inherit;

    &:hover {
      color: $color-primary;
    }
  }

  svg {
    top: 2px;
    position: relative;
    font-size: 0.75rem;
    margin-left: 0.1rem;
  }
}

.SubscriptionActions {
  @media (min-width: $breakpoint-tablet-min) {
    display: flex;
    align-items: center;
  }

  .formError {
    animation: subtle-drop 0.4s;

    @media (min-width: $breakpoint-tablet-min) {
      margin: 0 0 0 2rem;
    }
  }

  .formErrorTitle {
    font-size: 1.15em;
    margin-bottom: 0.1em;
    font-weight: 700;
  }
}

.divider {
  margin: 4 * $spacing-unit 0 2 * $spacing-unit;
  background: $color-neutral-lighter;
}

.formError {
  color: $color-error;
  font-size: pxrem(14);
  font-weight: 500;
  line-height: 1.25rem;
  margin-top: $spacing-unit;
}

.errorField {
  label {
    color: $color-error;
  }

  input[type='text'],
  input[type='email'],
  input[type='password'],
  textarea,
  select {
    border-color: #ef9a9a;
    background-color: #ffebee;
  }
}

.cardDetailsGrid {
  animation: subtle-drop 0.4s;
}

.SubscriptionOptions {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem 0 0;

  @media (min-width: $breakpoint-mobile-min) {
    margin: -0.5rem -0.5rem 0;
  }
}

.SubscriptionOption {
  align-items: center;
  border: 1px solid $color-neutral-lighter;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex: 100% 0 0;
  height: auto;
  justify-content: flex-start;
  line-height: initial;
  margin: $spacing-unit 0 0;
  padding: 2 * $spacing-unit;
  transition: border 0.1s;
  width: 100%;

  @media (min-width: $breakpoint-mobile-min) {
    margin: $spacing-unit;
    width: calc(50% - 1rem);
    flex: calc(50% - 1rem) 0 0;
    padding: $spacing-unit 2 * $spacing-unit;
  }

  &:hover {
    border-color: $color-neutral;
  }

  &.selected {
    border-color: $color-primary;
    border-style: solid;
    box-shadow: inset 0 0 0 1px $color-primary;
  }

  input {
    display: none;
  }

  .radioReplacement {
    height: $spacing-unit * 3;
    width: $spacing-unit * 3;
    border-radius: 50%;
    border: 3px solid $color-neutral-lighter;
    margin-right: $spacing-unit * 2;
    position: relative;

    @media (min-width: $breakpoint-mobile-min) {
      margin-right: $spacing-unit * 2;
    }

    &:after {
      background: #fff;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      transform: scale(0.5);
      transition: all 0.15s ease-in-out;
    }
  }

  &.selected .radioReplacement:after {
    background: $color-primary;
    transform: scale(1);
    transition: all 0.05s ease-in-out;
  }

  .content {
    display: flex;
    flex: 1 0 0;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }

  .name {
    display: block;
    font-size: pxrem(18);
    font-weight: 700;
    line-height: $spacing-unit * 2.5;
    margin-bottom: 0.1rem;
    color: $color-neutral-dark;

    @media (min-width: $breakpoint-mobile-min) {
      font-size: pxrem(18);
      line-height: $spacing-unit * 3;
    }
  }

  .description {
    font-weight: 400;
    color: $color-neutral-dark;
    font-size: pxrem(13);
    line-height: $spacing-unit * 2.5;

    @media (min-width: $breakpoint-mobile-min) {
      font-size: pxrem(15);
    }
  }

  .saveTag {
    background: #2aa051;
    border-radius: 30px;
    color: #fff;
    font-size: pxrem(16);
    font-weight: 700;
    margin-right: 0.25rem;
    padding: 0.4rem 0.75rem;
  }
}
