@import 'styles/variables';

.AccountWrapper {
  max-width: 50rem;
  margin: 0 auto;
}

.AccountSection {
  padding-bottom: 5 * $spacing-unit;
}
