@import 'styles/variables';
@import 'styles/mixins';

.browser {
  background: $color-neutral-lighter;
  border: 5px solid $color-neutral-lighter;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  height: 0;
  padding-top: calc(65% + 25px);

  &.carousel,
  &.embed_slideshow,
  &.slideshow {
    padding-top: calc(58% + 25px);
  }

  @include breakpoint($breakpoint-mobile-min) {
    padding-top: calc(58% + 25px);
  }

  @media (max-width: $breakpoint-tablet-max) {
    // preview scroll-prevent-layer
    &::after {
      background: transparent;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 24px;
      z-index: 2;
    }
  }
}

.iframe {
  background: #fff;
  border: none;
  height: 1024px;
  left: 0;
  margin: 10px 0 0 0;
  position: absolute;
  top: 15px;
  transform-origin: 0 0;
  transform: scale(0.3);
  width: calc(100% * (10 / 3));

  @include breakpoint(500px) {
    transform: scale(0.4);
    width: calc(100% * (10 / 4) + 10px);
  }

  @include breakpoint($breakpoint-mobile-min) {
    transform: scale(0.35);
    width: calc(100% * (10 / 3.5) + 10px);
  }

  @media (min-width: $breakpoint-tablet-min) {
    transform: scale(0.375);
    width: calc(100% * (10 / 3.75) + 10px);
  }

  .carousel & {
    transform: scale(0.4);
    width: calc(100% * (10 / 4));
    padding-top: 10%;

    @include breakpoint(500px) {
      transform: scale(0.5);
      width: calc(100% * (10 / 5));
      padding-top: calc(50% - (100% / 2.5));
    }

    @include breakpoint($breakpoint-mobile-min) {
      transform: scale(0.4);
      width: calc(100% * (10 / 4));
      padding-top: calc(50% - (100% / 3));
    }

    @media (min-width: $breakpoint-tablet-min) {
      transform: scale(0.5);
      width: calc(100% * (10 / 5));
      padding-top: calc(50% - (100% / 2.75));
    }
  }

  .embed_slideshow & {
    height: calc(100% / 0.3 - 60px);

    @include breakpoint(500px) {
      height: calc(100% / 0.4 - 50px);
    }

    @include breakpoint($breakpoint-mobile-min) {
      height: calc(100% / 0.35 - 60px);
    }

    @media (min-width: $breakpoint-tablet-min) {
      height: calc(100% / 0.375 - 60px);
    }
  }

  // non-browser design
  .slideshow & {
    transform: none !important;
    width: 100% !important;
    height: 100% !important;
    transform: none !important;
    top: 0;
    margin: 0;
  }
}

.browserButtons {
  position: absolute;
  left: 9px;
  top: 5px;

  span {
    background: $color-neutral-light;
    border-radius: 50%;
    content: '';
    display: block;
    height: 11px;
    position: absolute;
    top: 0;
    width: 11px;

    &:first-child {
      left: 0;
    }

    &:nth-child(2) {
      left: 16px;
    }

    &:nth-child(3) {
      left: 32px;
    }
  }
}
