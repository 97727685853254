@use 'sass:math';

@mixin breakpoint($point, $type: 'min') {
  $modifier: 0;

  @if $type == 'max' {
    $modifier: 1;
  }

  @media (#{$type}-width: $point - $modifier) {
    @content;
  }
}

@function pxrem($px, $basePx: 16) {
  @return math.div($px, $basePx) * 1rem;
}
