@import 'styles/variables.scss';

@keyframes cookie-banner-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fl-cookie-banner {
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.66);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2147483003;
}

.fl-cookie-banner__scroll {
  position: static;

  flex: 0 0 auto;
  width: 100%;
  max-height: 100%;

  overflow-y: auto;
  height: 100%;

  @media (min-width: 1024px) {
    height: auto;
    padding: 1.5rem 0;
  }
}

.fl-cookie-banner__popup {
  animation: cookie-banner-fade-in 0.3s;

  background: #fff;
  border-radius: 8px;
  padding: 1rem;
  font-size: 0.9rem;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.07), 0 6px 12px rgba(0, 0, 0, 0.1), 0 12px 24px rgba(0, 0, 0, 0.14);

  width: 100%;
  max-width: calc(100% - 1rem);
  min-height: 300px;
  margin: 0.5rem auto auto;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    height: 100%;
    height: calc(100% - 1rem);
  }

  @media (min-width: 1024px) {
    margin: auto;
    font-size: 1.25rem;
    padding: 2rem;
    max-width: 800px;
  }
}

.fl-cookie-banner__content {
  @media (max-width: 1023px) {
    flex: 1 0 0;
    overflow-y: auto;
    padding: 0 1rem;
    margin: 0 -1rem;
  }
}

.fl-cookie-banner__footer {
  @media (max-width: 1023px) {
    margin: 0 -1rem;
    padding: 0 1rem;
    border-top: 1px solid $color-neutral-lighter;
  }
}

.fl-cookie-banner__text {
  margin-bottom: 1rem;
  font-size: 1em;
  line-height: 1.45;
  color: $color-neutral-dark;

  a {
    text-decoration: underline;
    color: inherit;

    &:hover {
      color: $color-primary;
    }
  }
}

.fl-cookie-banner__title {
  margin: 0 0 0.5rem;
  font-weight: 900;
  font-size: 1.5em;

  @media (min-width: 1024px) {
    margin: 0 0 1rem;
  }
}

.fl-cookie-banner__buttons {
  margin: 0.5rem 0 0;

  display: flex;
  flex-direction: column;

  .fl-cookie-banner__button {
    margin-top: 1rem;
  }

  @media (min-width: 1024px) {
    margin: 2rem 0 0;

    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;

    .fl-cookie-banner__button {
      margin-left: 1rem;
      margin-top: 0;
      width: auto;
    }
  }
}

.fl-cookie-banner__edit {
  margin: 0.5rem 0 0;

  @media (min-width: 1024px) {
    margin: 2rem 0 0;
  }
}

.fl-cookie-banner__edit__field {
  margin: 0 0 0.75rem;
  display: block;
}

.fl-cookie-banner__edit__field__label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.fl-cookie-banner__edit__field__label__input {
  margin: 0 0.75rem 0 0;
  width: 1rem;
  display: flex;
  align-items: center;
}

.fl-cookie-banner__consent {
  width: 1rem;
  height: 1rem;
}

.fl-cookie-banner__edit__field__label__text {
  display: block;
  font-weight: bold;
  font-size: 1rem;
}

.fl-cookie-banner__edit__field__text {
  font-size: 0.9em;
  line-height: 1.4;
  display: block;
  color: $color-neutral-dark;
  margin-left: 1.75rem;

  @media (min-width: 1024px) {
    font-size: 0.8em;
  }

  a {
    text-decoration: underline;
    color: $color-neutral-dark;

    &:hover {
      color: $color-primary;
    }
  }
}

.fl-cookie-banner__edit__buttons {
  display: flex;
  flex-direction: column-reverse;

  .button.button--success {
    background: #2bc48c !important;
    border-color: darken(#2bc48c, 3%) !important;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

.fl-cookie-banner__button {
  -webkit-font-smoothing: antialiased;
  width: 100%;
  text-align: center;
  border: none;
  border-radius: 4px;
  padding: 0.9rem 1.7rem;
  color: #fff;
  cursor: pointer;
  margin-top: 1rem;
  font-weight: 600;
  font-size: 1rem;
  background: #0069e2;
  border: 1px solid #0069e2;

  &:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 5px #1869ba;
  }

  &.fl-cookie-banner__button--plain {
    background: #fff;
    color: #0069e2;
  }

  @media (min-width: 1024px) {
    width: auto;
    margin-top: 0;
    margin-left: 1rem;
  }
}
