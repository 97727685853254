@import 'styles/variables';
@import 'styles/mixins';

.alert {
  background: $color-neutral-lighter;
  border-radius: 0.5rem;
  padding: $spacing-unit $spacing-unit * 2;
  margin: $spacing-unit * 2 0;

  font-size: pxrem(14);
  line-height: 1.2rem;
  font-weight: 500;
  color: $color-neutral-dark;
  display: flex;
  align-items: center;
  justify-content: center;

  &[data-alert-variant='danger'] {
    background: #fbe9e7;
    border: 1px solid #ead7d1;
    color: $color-error-dark;
  }
}

.alertIcon {
  width: 1rem;
  height: 1rem;
  margin-right: $spacing-unit * 1.5;
}
