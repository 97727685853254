@import 'styles/variables.scss';
@import 'styles/animations.scss';
@import 'styles/mixins.scss';

.notificationCenter {
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 7rem;
  left: 0;
  right: 0;
  pointer-events: none;
  text-align: right;

  @include breakpoint($breakpoint-mobile-min) {
    left: 1rem;
    right: 1rem;
  }
}

.notification {
  pointer-events: initial;
  position: relative;

  padding: 0.75em 2em;
  margin: 0 1rem 1rem;
  max-width: pxrem(400);

  font-weight: 600;
  font-size: pxrem(14);
  line-height: 1.5;

  @include breakpoint($breakpoint-mobile-min) {
    margin-left: auto;
    margin-right: 2.5rem;
  }

  text-align: center;
  animation: slide-from-right 0.4s;
  background: #fff;
  color: #fff;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.075);

  &.info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #0077b3;
  }

  &.success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
  }

  &.error {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
  }

  &:hover .close {
    opacity: 1;
  }
}

.content {
  display: flex;
  align-items: center;
  max-width: pxrem(500);
  margin: 0 auto;

  svg {
    margin-right: 0.3rem;
    position: relative;
    top: -1px;
  }
}

.close {
  width: pxrem(50);
  height: pxrem(50);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -1 * pxrem(50);
  background: transparent;
  border: none;
  color: inherit;
  opacity: 0.2;
  font-size: 2em;
  cursor: pointer;
  outline: none;

  &:hover {
    opacity: 1;
  }

  svg {
    height: 40%;
    width: 40%;
  }
}
