@import 'styles/variables';

.success {
  pointer-events: none;
  touch-action: none;
}

.icon {
  display: inline-block;
  margin: -2px 2px -2px -4px !important;
  height: 1.1rem !important;
  width: 1.1rem !important;
  top: 0;
  position: relative;
}

.iconSmall {
  @extend .icon;
  height: .5rem !important;
  margin: 0 2px 0 -4px !important;
  width: .5rem !important;
  top: -1px;
  position: relative;
}
