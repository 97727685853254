@use 'sass:math';

@import 'styles/animations';
@import 'styles/variables';

.loader {
  text-align: center;
  margin: 10% auto;
}

.editorPage {
  max-width: 740px;
}

.slideshowUrl {
  background-color: #d9edf7;
  border-radius: 0.25rem;
  border: 1px solid darken(#d9edf7, 5%);

  position: relative;
  min-height: 3rem;
  margin: $spacing-unit 0 2 * $spacing-unit;
}

.slideshowUrlLink {
  padding: $spacing-unit;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3rem;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: underline;
  color: $color-primary;

  &:hover {
    color: $color-primary-dark;
  }
}

.slideshowUrlLinkIcon {
  margin-left: $spacing-unit;
}
