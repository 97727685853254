@use 'sass:math';

@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/variables';

.loader {
  text-align: center;
  margin: 10% auto;
}

.displayPage {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;

  max-width: 98rem;
}

.prioritizedOption {
  border: 1px solid $color-primary !important;
  box-shadow: 0 5px 15px rgba($color-neutral-darker, 0.1) !important;

  @media (min-width: 768px) {
    margin-right: 1rem;
  }

  &:hover {
    border: 1px solid $color-primary-dark !important;
  }
}

.mainHeading {
  font-size: math.div(32, 16) * 1rem;
  color: $color-neutral-darker;
  font-weight: 800;
  text-align: center;
  margin: 0 0 $spacing-unit;
  line-height: 1.25em;

  @media (max-width: $breakpoint-tablet-max) {
    font-size: math.div(28, 16) * 1rem;
  }

  @media (max-width: $breakpoint-mobile-max) {
    font-size: math.div(24, 16) * 1rem;
  }
}

.reliefStressText {
  color: $color-neutral-dark;
  text-align: center;
  font-size: math.div(18, 16) * 1rem;
  margin: 0;
  line-height: 1.25em;

  @media (max-width: $breakpoint-mobile-max) {
    font-size: math.div(15, 16) * 1rem;
  }
}

.back {
  margin: 2rem auto 1rem;
  text-align: center;

  @media (min-width: $breakpoint-mobile-min) and (min-height: 55rem) {
    margin: 3rem auto 1rem;
  }
}

.backLink {
  animation: fade-in 0.5s;
  align-items: center;
  display: inline-flex;

  background: transparent;
  border: 0;
  border-radius: 0.25rem;
  color: $color-neutral;
  cursor: pointer;
  font-size: 1em;
  font-weight: 500;
  padding: 0.65em 1.2em;
  text-decoration: none;
  transition: all 0.1s;
  outline: 0;
  user-select: none;

  &:hover,
  &:active {
    text-decoration: none;
    color: $color-primary;
  }
}
