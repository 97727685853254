@import 'styles/mixins';
@import 'styles/variables';

$mobile-breakpoint: 660px;

.invitationForm {
  > * + * {
    margin: $spacing-unit * 1.5 0 0;
  }

  @media (min-width: $mobile-breakpoint) {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    > * + * {
      margin: 0 0 0 $spacing-unit * 1.5;
    }
  }
}

.invitationForm__emailField {
  flex: 1 0 auto;
}

.invitationForm__roleField {
  flex: 0 0 13ch;
}

.invitationForm__sectionField {
  flex: 0 0 13ch;
}

.invitationForm__actionField {
  flex: 0 0 auto;
}

.rolesExplained {
  display: flex;
  flex-wrap: wrap;
  margin: 0;

  dt {
    color: $color-neutral-dark;
    font-weight: 700;
    flex: 0 0 6em;
    flex-basis: 6em;
    width: 6em;
  }

  dd {
    margin: 0;
    padding: 0;
    flex: 1 0 calc(100% - 6em);
  }
}
