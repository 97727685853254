@use 'sass:math';

@import 'styles/animations';
@import 'styles/variables';

.loader {
  text-align: center;
  margin: 10% auto;
}

.displayPage {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;

  max-width: 98rem;
}

.displayOptions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.displayListWrap {
  margin: 5 * $spacing-unit auto 2 * $spacing-unit;
  animation: fade-in 0.5s;
  max-width: 120 * $spacing-unit;

  @media (min-width: $breakpoint-tablet-min) and (min-height: 50rem) {
    margin-top: 7 * $spacing-unit;
  }
}

.createButton {
  min-width: math.div(220, 16) * 1rem;
}

.createAnotherEmbedIntro {
  max-width: 120 * $spacing-unit;
  animation: fade-in 0.2s;
  margin: $spacing-unit * 4 auto $spacing-unit * 4;
  text-align: left;
  padding: 0 $spacing-unit;

  display: flex;
  flex-direction: row-reverse;

  .rightCol,
  .leftCol {
    flex: 50% 0 0;
    width: 50%;
  }

  .rightCol {
    margin-right: 3.5rem;
    margin-left: 0rem;
  }

  .mockups {
    position: relative;
    min-height: 325px;

    animation: subtle-drop 0.5s;
    user-select: none;
  }

  .mockup {
    position: absolute;
    height: 255px;
    overflow: hidden;
    width: 255px;
    box-shadow: 0px 0px 4px $color-neutral-lighter, -5px 7px 18px rgba(0, 0, 50, 0.15);

    opacity: 1;
    transform: perspective(76vw) rotateY(16deg) rotate(-3deg) skew(-3deg, 1deg) translate3d(7rem, 0, 1px) scale(1.25);
    z-index: 2;

    &:nth-child(2) {
      opacity: 0.7;
      transform: perspective(75vw) rotateY(16deg) rotate(-3deg) skew(-3deg, 1deg) translate3d(0rem, 0, 0) scale(1);
      z-index: 1;
    }

    &:nth-child(3) {
      opacity: 0.85;
      transform: perspective(75vw) rotateY(16deg) rotate(-3deg) skew(-3deg, 1deg) translate3d(14rem, 0, 0) scale(1.05);
      z-index: 1;
    }
  }

  // Overrides <DisplayMockup /> styles...
  .displayMockupOverride {
    // carousel background
    &.carousel,
    &.carousel > div {
      background: $color-neutral-lighter;
    }

    // carousel posts larger
    &.carousel > div > div {
      min-width: 38%;
      flex: 38% 0;
    }

    div {
      animation-delay: 0s !important;
    }

    // hacky resize of carousel navg button
    &:after {
      opacity: 0.75;
      transform: scale(0.6);
      transform-origin: 100% 0;
    }
  }

  .intro {
    margin: 0 0 $spacing-unit * 2 0;
    color: $color-neutral-darker;
  }

  @media (min-width: $breakpoint-tablet-min) and (max-height: 50rem) {
    margin: $spacing-unit * 2 auto $spacing-unit * 2;

    .mockups {
      min-height: 290px;
    }
  }

  // Mobile styles
  @media (max-width: $breakpoint-tablet-max) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin: 0;

    .rightCol {
      max-width: 400px;
      margin: 0 0 1.5rem;
      padding-left: 0rem;
    }

    .leftCol {
      text-align: center;
      max-width: 600px;
    }

    .mockups {
      min-height: 250px;
    }

    .mockup {
      width: 210px;
      height: 210px;
    }

    .rightCol,
    .leftCol {
      width: 100%;
      flex: 1 0 0;
    }
  }

  @media (max-width: $breakpoint-mobile-max) {
    max-width: none;
    padding: 0 $spacing-unit;

    .rightCol,
    .leftCol {
      width: 100%;
      flex: 1 0 0;
    }

    .leftCol {
      z-index: 1;
    }

    .mockups {
      margin: 0;
      padding: 0;
      min-height: 200px;
      display: flex;
      justify-content: center;
    }

    .mockup {
      width: 75vw;
      height: 55vw;
      position: relative;
      transform: none;
      margin-left: 0 auto;
      display: none;
      box-shadow: 0px 0px 2px $color-neutral-lighter, 0 5px 15px $color-neutral-lighter;

      &:first-child {
        display: block;
        transform: none;
      }
    }

    .intro {
      font-size: 0.9rem;
    }
  }
}

.newDisplayStyleAvailableNotice {
  display: none;

  @media (min-width: 768px) {
    display: block;
    text-align: center;
  }
}

.newDisplayStyleAvailableNotice__wrapper {
  display: inline-block;
}
