// Colors

@use 'sass:math';

// Brand colors 2024
$color-primary: #1D4ED8;
$color-primary-lighter: #E1EEFE;
$color-primary-light: #71AAF4;
$color-primary-dark: #0047A4;
$color-primary-darker:  #002351;

$color-neutral: #667B99;
$color-neutral-lighter: #F1F4F9;
$color-neutral-light: #C2CAD6;
$color-neutral-dark: #424F62;
$color-neutral-darker:  #1F2937;

$color-secondary: #07A672;
$color-secondary-lighter: #DEF7EF;
$color-secondary-light: #88DDC1;
$color-secondary-dark: #0E8160;
$color-secondary-darker: #00513E;

$color-error: #DB273F;
$color-error-lighter: #FFEBEB;
$color-error-light: #F5A3AE;
$color-error-dark: #B8142A;
$color-error-darker: #660002;

$color-purple: #A855F7;
$color-purple-lighter: #F1E0FF;
$color-purple-light: #B775F0;
$color-purple-dark: #6E14B8;
$color-purple-darker: #3A0066;

$color-warning: #F8AD46;
$color-warning-lighter: #FEF6EB;
$color-warning-light: #FABE6B;
$color-warning-dark: #945805;
$color-warning-darker: #4F2F03;

$color-pink:  #DB2777;
$color-pink-lighter: #FFE0EF;
$color-pink-light: #F075B0;
$color-pink-dark: #B81463;
$color-pink-darker: #66003C;

$color-brand-facebook: #087fec;
$color-brand-instagram: #d73776;
$color-brand-linkedin: #027ab2;
$color-brand-mastodon: #5d50e7;
$color-brand-twitter: #1fa4ed;
$color-brand-yammer: #0072c6;
$color-brand-youtube: #ff0000;

// Spacing

$spacing-unit: math.div(8, 16) * 1rem;

// Breakpoints

$breakpoint-tablet-max: 1024px;
$breakpoint-mobile-max: 767px;

$breakpoint-tablet-min: $breakpoint-tablet-max + 1px;
$breakpoint-mobile-min: $breakpoint-mobile-max + 1px;

// Fixed sizes

$header-height: 12 * $spacing-unit;
$header-height-mobile: 10 * $spacing-unit;

$footer-height: 7 * $spacing-unit;
$footer-height-mobile: 3.5 * $spacing-unit;

// Embed

$breakpoint-embed-pip-mode: 800px;
$breakpoint-embed-inline-columns: 1500px;

@font-face {
  font-family: 'Figtree';
  font-weight: 100 900;
  font-style: normal;
  src: url('~@flockler/fl-assets/fonts/figtree-variable-wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Figtree';
  font-weight: 100 900;
  font-style: italic;
  src: url('~@flockler/fl-assets/fonts/figtree-italic-variable-wght.ttf') format('truetype');
}
