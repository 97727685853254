@use 'sass:math';

@import 'styles/variables';

.footerNotice {
  background: $color-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: $footer-height;
  padding: $spacing-unit;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;

  @media (max-width: $breakpoint-mobile-max) {
    height: $footer-height-mobile;
    font-size: math.div(11, 16) * 1rem;
    line-height: 1.2;
    padding: $spacing-unit * 0.5;
  }
}

.footerNoticeCancelled {
  background: $color-error-dark;
}

.footerNoticeMobile {
  opacity: 0.8;
}
