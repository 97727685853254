@use 'sass:math';

@import 'styles/animations';
@import 'styles/variables';
@import 'styles/mixins';

@keyframes toPipMode {
  0% {
    margin: -100px 100px 0 0;
    opacity: 0;
  }

  100% {
    margin: 0;
    opacity: 1;
  }
}

.preview {
  display: block;
  margin: 4 * $spacing-unit 0;

  &.pipMode {
    @media (min-width: $breakpoint-embed-pip-mode) {
      background: #f8f8f8;
      border-radius: 10px;
      min-height: 500px;
      position: relative;
      z-index: 2;
    }

    .previewArea {
      @media (min-width: $breakpoint-embed-pip-mode) {
        animation: toPipMode 0.25s ease-in-out;
        background: $color-neutral-lighter;
        border-radius: 10px;
        position: fixed;
        right: 15px;
        top: 105px;

        z-index: 3;
        padding-top: 0;

        height: 289px;
        width: 423px;

        &:after {
          display: none;
        }
      }

      @media (min-width: 1090px) {
        height: 368px;
        width: 548px;
      }

      @media (min-width: 1850px) and (min-width: 800px) {
        right: calc(50% - 915px);
      }
    }

    .previewFooter {
      @media (min-width: $breakpoint-embed-pip-mode) {
        bottom: 0;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        position: absolute;
        width: 100%;
        z-index: 3;
      }

      @media (min-width: 1090px) {
        padding: 15px;
      }
    }

    @media (min-width: $breakpoint-embed-pip-mode) {
      .unsavedChanges {
        animation: subtle-drop 0.15s;
        display: inline-block;
        color: $color-error-dark;
        font-size: 0.75em;
        font-weight: 400;
        line-height: 20px;
        margin-left: 2 * $spacing-unit;
      }
    }
  }
}

.previewArea {
  position: relative;
  background: #eceff1;
  border-radius: 10px;
  position: relative;
  height: 0;
  margin-bottom: 24px;
  padding: 58% 0 0;

  &::after {
    background: #cfd8dc;
    border-radius: 0 0 5px 5px;
    bottom: -10px;
    content: '';
    height: 10px;
    left: 50%;
    margin-left: -20%;
    position: absolute;
    width: 40%;
  }
}

.previewFooter {
  display: none;
}

.unsavedChanges {
  display: none;
}

.previewLink {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  float: right;
  position: relative;
  z-index: 2;

  padding: 0 2 * $spacing-unit;
  height: 4 * $spacing-unit;
  line-height: 4 * $spacing-unit;
  font-size: math.div(13, 16) * 1rem;
  font-weight: 500;
  border-radius: 4px;

  color: #fff;
  background-image: linear-gradient(to bottom, #308fce, #1869ba);
  border: 1px solid #1869ba;

  &.disabled {
    background: $color-neutral-light;
    border-color: darken($color-neutral-light, 4%);
    pointer-events: none;
    touch-action: none;
  }

  &:hover {
    background-image: linear-gradient(to bottom, darken(#308fce, 3%), darken(#1869ba, 3%));
    border: 1px solid darken(#1869ba, 3%);
  }

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    text-decoration: none;
  }
}

.previewLinkIcon {
  height: 0.85rem;
  margin-left: $spacing-unit;
  top: -1px;
  position: relative;
  width: auto;
}
