@import 'styles/animations';
@import 'styles/variables';

.planChangeModalContent {
  width: 43rem;
}

.planChangeFeatureList {
  margin: $spacing-unit * 2 0 0;
  text-align: left;
}

.planChangeFeatureListItem {
  margin: 0 0 0.25rem;
  padding-left: 1.5rem;
  position: relative;

  strong {
    font-weight: 600;
  }

  .checkIcon {
    color: $color-secondary;
    margin-right: 0.25rem;
    position: absolute;
    top: 3px;
    left: 0;
  }
}

.planChangeFeatureListItemHighlighted {
  font-weight: bold;
  color: $color-secondary-dark;
}

.updateError {
  animation: subtle-drop 0.2s;
  margin: 2 * $spacing-unit 0 0;
  color: $color-error;
  font-size: 1rem;
  font-weight: 500;
}

.updateErrorLink {
  font-weight: 600;
  color: $color-error;
  text-decoration: underline;
}
