@use 'sass:math';

@import 'styles/animations';
@import 'styles/variables';

.AddonLabel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 3.5rem;
  position: relative;
  cursor: pointer;
  height: 2rem;
  line-height: initial;
  margin-bottom: 0.5 * $spacing-unit;
  user-select: none;

  input[type='checkbox'] {
    display: none;
  }

  &:hover .checkbox:not(.checked) {
    border-color: $color-neutral;
  }
}

.checkbox {
  align-items: center;
  background: #fff;
  border-radius: 20px;
  display: flex;
  height: 20px;
  justify-content: center;
  margin: 0 2rem 0 0;
  width: 20px;
  transition: all 0.1s;
  border: 2px solid $color-neutral-light;
  border-radius: 0.25rem;
  position: absolute;
  left: 0.4rem;
  top: 0.35rem;

  &.checked {
    background: $color-secondary;
    border-color: $color-secondary;

    &::after {
      border-color: #fff;
      border-style: solid;
      border-width: 0 2px 2px 0;
      content: '';
      display: block;
      height: 12px;
      left: 5px;
      position: absolute;
      top: 0;
      transform: rotate(45deg);
      width: 6px;
    }
  }
}

.AddonLabelName {
  font-size: math.div(18, 16) * 1rem;
  font-weight: bold;
}

.AddonLabelHelpText {
  font-size: math.div(14, 16) * 1rem;
  color: $color-neutral;
  font-weight: 400;
  margin-left: 1.5rem;
  position: relative;
  top: 1px;
}

.AddonDescription {
  color: $color-neutral-dark;
  padding: 0 0 0 3.5rem;
  font-size: math.div(14, 16) * 1rem;
  line-height: 1.45;
}
