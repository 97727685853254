@use 'sass:math';

@import 'styles/variables';

.NoticeBox {
  background: $color-primary-lighter;
  border: 1px solid $color-primary;
  border-radius: $spacing-unit * 0.75;
  color: $color-primary-dark;
  display: flex;
  font-size: math.div(14, 16) * 1rem;
  font-weight: 600;
  min-height: calc(#{3 * $spacing-unit} - 2px);
  line-height: calc(#{3 * $spacing-unit} - 2px);
  padding: 1.75 * $spacing-unit;
  max-width: 100%;
  flex-basis: 100%;

  &[data-notice-size='small'] {
    padding: calc(#{1.75 * $spacing-unit} - 2px) 2 * $spacing-unit;
  }

  &[data-notice-size='large'] {
    padding: 2 * $spacing-unit 3 * $spacing-unit;

    @media (max-width: $breakpoint-mobile-max) {
      padding: 2 * $spacing-unit;
    }
  }

  &[data-notice-type='success'] {
    background: $color-secondary-lighter;
    border-color: $color-secondary;
    color: $color-secondary-dark;
  }

  &[data-notice-type='danger'] {
    background: $color-error-lighter;
    border-color: $color-error;
    color: $color-error-dark;
  }

  &[data-notice-type='warning'] {
    background: $color-warning-lighter;
    border-color: $color-warning;
    color: $color-warning-dark;
  }

  &[data-notice-type='naked'] {
    background: transparent;
    border-color: transparent;
    padding: 0;
  }
}

.NoticeBoxIcon {
  flex-grow: 0;
  flex-shrink: 0;
  display: block;
  height: 2 * $spacing-unit;
  margin-right: 1.5 * $spacing-unit;
  margin-top: $spacing-unit * 0.25;
  width: 2 * $spacing-unit;

  [data-notice-type='naked'] &,
  [data-notice-type='neutral'] & {
    color: $color-primary-dark;
  }

  [data-notice-type='success'] & {
    color: $color-secondary;
  }

  [data-notice-type='danger'] & {
    color: $color-error;
  }

  [data-notice-type='warning'] & {
    color: $color-warning;
  }
}
