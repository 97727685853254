@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/variables';

.analyticsPage {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;

  max-width: pxrem(1100);
}

.mainHeading {
  font-size: pxrem(38);
  color: $color-neutral-darker;
  font-weight: 800;
  text-align: left;
  margin: 0 0 $spacing-unit;
  line-height: 1.25em;

  @media (max-width: $breakpoint-tablet-max) {
    font-size: pxrem(28);
  }

  @media (max-width: $breakpoint-mobile-max) {
    font-size: pxrem(24);
  }
}

.header {
  margin: 4 * $spacing-unit 0 6 * $spacing-unit;
  display: flex;
  justify-content: space-between;

  @media (max-width: $breakpoint-mobile-max) {
    flex-direction: column-reverse;
  }
}

.headerTextCol {
  flex: 45% 0 0;
  width: 45%;
  margin-right: 3%;

  @media (max-width: $breakpoint-mobile-max) {
    flex: 100% 0 0;
    width: 100%;
  }
}

.headerVisualizationCol {
  flex: 1 0 0;
  min-height: pxrem(250);
  padding-left: 0;
  position: relative;
  z-index: 1;

  @media (prefers-reduced-motion: no-preference) {
    animation: subtle-drop 0.65s ease-in-out;
  }

  @media (max-width: $breakpoint-mobile-max) {
    min-height: 0;
  }
}

.headerVisualization {
  align-items: center;
  animation-fill-mode: forwards;
  background: #fff;
  border-radius: 15px;
  border: 1px solid $color-neutral-lighter;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 5px 10px 35px rgba(229, 237, 245, 0.6);
  display: block;
  display: flex;
  height: auto;
  justify-content: center;
  margin: 0;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  padding: 5px;
  position: relative;
  top: -10px;
  right: 10px;
  transform-origin: 50% 0%;
  transform: perspective(75vw) rotateY(-11deg) rotate(4deg) skew(4deg, -4deg);
  width: 100%;

  @media (max-width: $breakpoint-mobile-max) {
    transform: none;
    width: 100%;
    max-width: 500px;
    height: auto;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
}

.intro {
  font-size: pxrem(20);
  line-height: 1.45;
  margin: 2 * $spacing-unit 0 4 * $spacing-unit;

  @media (max-width: $breakpoint-mobile-max) {
    font-size: pxrem(18);
    margin-top: 4 * $spacing-unit;
  }
}

.featureCards {
  display: flex;
  flex-wrap: wrap;
  padding: $spacing-unit 0;
  margin: 0 (-$spacing-unit);
}

.featureCard {
  width: calc(50% - #{2 * $spacing-unit});
  margin: $spacing-unit;
  line-height: 1.4;
  font-weight: 500;
  font-size: pxrem(15);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04), 0 1px 20px rgba(239, 242, 245, 1);
  padding: 1.5rem 2rem;
  border-radius: 13px;
  position: relative;
  display: flex;
  transform: translateY(0);
  transition: transform 0.8s ease-in-out;

  @media (max-width: $breakpoint-tablet-max) {
    width: 100%;
    padding: 1rem;
  }

  @media (pointer: fine) and (prefers-reduced-motion: no-preference) {
    &:hover {
      transform: translateY(-$spacing-unit * 0.5);
      transition: transform 0.15s ease-in-out;
    }
  }

  svg {
    flex: 70px 0 0;
    width: 70px;
    height: 70px;
    min-width: 70px;
    color: $color-primary;
    margin-right: 3 * $spacing-unit;

    @media (max-width: $breakpoint-tablet-max) {
      flex: 50px 0 0;
      width: 50px;
      height: 50px;
      min-width: 50px;
      margin-right: 2 * $spacing-unit;
    }
  }
}

.featureCardTitle {
  color: $color-neutral-darker;
  font-weight: 600;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.featureCardDescription {
  color: $color-neutral;
  margin-top: $spacing-unit * 0.5;
}

.footerActions {
  margin-top: $spacing-unit * 4;

  @media (min-width: $breakpoint-mobile-min) {
    display: none;
  }
}
