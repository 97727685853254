@import 'styles/variables';
@import 'styles/animations';

.icon {
  display: inline-block;
  position: relative;
  margin: -5px 2px 0px -4px !important;
  top: 1px;

  height: 1.2em !important;
  width: 1.2em !important;
}

.copyMessage {
  animation: subtle-drop 0.2s;
  display: inline-block;
}
