@import 'styles/animations';
@import 'styles/variables';

@keyframes display-option-animation {
  0% {
    opacity: 0;
    transform: translate3d(0, $spacing-unit, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.displayOption {
  opacity: 0;
  transform: translate3d(0, $spacing-unit, 0);
  animation: display-option-animation 0.5s ease-in-out;
  animation-fill-mode: forwards;

  &:nth-child(1) {
    animation-delay: 0.1s;
  }

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.3s;
  }

  &:nth-child(n + 4) {
    animation-delay: 0.4s;
  }
}

.displayImg {
  opacity: 1;
  transition: all 0.3s ease-in-out;

  @media (min-width: 65rem) {
    opacity: 0.8;

    .displayOption:hover & {
      box-shadow: 0 15px 15px rgba(0, 0, 0, 0.085);
      position: relative;
      z-index: 99;
      opacity: 1;

      transform: translate3d(0, -1em, 0) scale(1.1);
    }

    .displayOption:first-child &,
    .displayOption:last-child & {
      transform: perspective(75vw);
    }

    .displayOption:first-child:hover & {
      transform: perspective(75vw) rotateY(16deg) rotate(-3deg) skew(-3deg, 1deg) translate3d(-0.5em, -1em, 0)
        scale(1.1);
    }

    .displayOption:last-child:hover & {
      transform: perspective(75vw) rotateY(-16deg) rotate(3deg) skew(3deg, -1deg) translate3d(0.5em, -1em, 0) scale(1.1);
    }
  }
}
