@import 'styles/animations';
@import 'styles/variables';
@import 'styles/mixins';

.SubscriptionSection {
  display: block;
}

.formError {
  color: $color-error;
  font-size: pxrem(14);
  font-weight: 500;
  line-height: 1.25rem;
  margin-top: $spacing-unit;
}

.errorField {
  label {
    color: $color-error;
  }

  input[type='text'],
  input[type='email'],
  input[type='password'],
  textarea,
  select {
    border-color: #ef9a9a;
    background-color: #ffebee;
  }
}
