@use 'sass:math';

@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/variables';

.wrap {
  align-items: center;
  display: flex;
  margin: 0 0 1.75 * $spacing-unit;
  position: relative;

  @include breakpoint($breakpoint-mobile-max, 'max') {
    flex-wrap: wrap;
  }

  .tooltip {
    width: 100%;

    @include breakpoint($breakpoint-mobile-max) {
      display: none;

      a {
        color: #fff;
      }
    }
  }

  &:hover .tooltip {
    @include breakpoint($breakpoint-mobile-max) {
      display: block;
      animation: fade-in 0.1s;
    }
  }

  &:hover .tooltipIcon {
    color: $color-neutral;
  }
}

.input {
  width: 54px;
}

.label {
  cursor: pointer; // if it's clickable with for-id bind
  line-height: 1.3;
  margin: 0;
  padding-left: 0.6rem;
  position: relative;
  top: 1px;
  width: auto;
  vertical-align: middle;
  height: auto;
  display: flex;
  align-items: center;
}

.tooltipIcon {
  display: none;

  @include breakpoint($breakpoint-mobile-max) {
    display: inline-block;
    color: $color-neutral-light;
    cursor: help;
    width: math.div(14, 16) * 1rem;
    margin: 1px 0 0 $spacing-unit;
    transition: color 0.1s;
  }
}

.tooltipImage {
  display: none;

  @include breakpoint($breakpoint-mobile-max) {
    width: calc(100% + 1em);
    border-radius: 3px 3px;
    position: relative;
    margin-bottom: 0.5em;
    top: -0.5em;
    left: -0.5em;
    display: block;
  }
}
