@import 'styles/animations';
@import 'styles/variables';

.ctaButtonCustomizer {
  position: relative;
}

.validationError {
  color: $color-error-dark;
  font-weight: bold;
}

.smallSelect {
  max-width: 10rem;
}

.previewButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 2px 9px rgba(0, 0, 0, 0.1);
  background: #1562c4;
  border: none;
  border-radius: 100px;
  color: #fff;
  padding: 0.4em 1em 0.5em;
  font-size: 0.9rem;
  font-weight: 500;
  white-space: nowrap;

  svg {
    margin-right: 0.5em;
  }
}

.previewField {
  @media (min-width: 1500px) {
    margin-top: 1.5rem;
  }

  @media (min-width: 1600px) {
    margin-top: 0;
    position: absolute;
    right: 145px;
    top: 2.75rem;
    width: 180px;

    .previewFieldTitle {
      display: block;
    }
  }
}
