@import 'styles/variables';
@import 'styles/mixins';

.label {
  display: block;
  margin: 3px 0 0;
  vertical-align: top;
  width: 100%;
  height: auto;
  min-height: 2rem;
  line-height: 2rem;

  @include breakpoint($breakpoint-embed-inline-columns) {
    margin-top: 0;
    display: inline-block;
    width: 30%;
  }
}
