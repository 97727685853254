@use 'sass:math';

@import 'styles/variables';
@import 'styles/animations';

.tagList {
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin: 0;
  min-height: 31px;
  padding: 0;

  border: 1px solid #b0bec5;
  border-radius: 3px;
}

.tagWrap {
  animation: fade-in 0.2s ease-out;
  background-color: transparent;
  color: $color-primary;
  font-size: math.div(14, 16) * 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 0 0.1rem;
  padding: 0 1.5em 0 0.5em;
  height: 2rem;
  display: flex;
  align-items: center;
  border: none;
  position: relative;
}

.tagWrap + .tagWrap::before {
  color: rgb(176, 190, 197);
  content: 'or';
  font-size: math.div(12, 16) * 1rem;
  font-weight: 600;
  margin-right: 1rem;
  text-transform: uppercase;
}

.tagRemoveButton {
  background: transparent;
  border-radius: 100%;
  border: none;
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 600;
  height: 2rem;
  line-height: 1;
  outline: none;
  padding: 0;
  position: absolute;
  right: -2px;
  text-align: center;
  top: 1px;
  user-select: none;
  width: 1.5rem;

  &:hover {
    color: $color-error;
  }
}

.animate {
  animation-name: fade-in;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: 2;
}
