@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/variables';

.error {
  border: none;

  @media (max-width: $breakpoint-mobile-max) {
    font-size: pxrem(13);
    line-height: 1.4;
  }
}

.readMoreLink {
  display: inline-block;
  color: $color-error-dark;
  text-decoration: underline;
  font-weight: 600;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    color: $color-error-darker;
  }

  svg {
    margin-left: 5px;
    display: inline-block;
    height: 0.8em;
    width: 0.8em;
    position: relative;
    top: -2px;
    opacity: 0.7;
  }
}

.supportLink {
  color: $color-error-dark;
  text-decoration: underline;
}
