@use 'sass:math';

@import 'styles/variables';
@import 'styles/animations';

.loader {
  text-align: center;
  margin: 10% auto;
}

.page {
  max-width: 78rem;
}

.cols {
  display: flex;
  flex-direction: column;
  padding: $spacing-unit;

  .rightCol {
    margin-top: 2rem;
  }

  @media (min-width: $breakpoint-mobile-min) {
    display: block;
    .leftCol {
      width: 50%;
      padding-right: $spacing-unit * 2;
      float: left;
    }

    .rightCol {
      margin-top: 0;
      width: 50%;
      float: right;
    }
  }

  @media (min-width: $breakpoint-tablet-min) {
    .leftCol {
      width: 55%;
      padding-right: $spacing-unit * 4;
    }

    .rightCol {
      width: 45%;
    }
  }

  // clearfix
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.preview {
  margin-top: 2 * $spacing-unit;
}

.slideshowUrl {
  background-color: #d9edf7;
  border-radius: 0.5rem;

  position: relative;
  min-height: 3rem;
  margin: 2 * $spacing-unit 0;
}

.slideshowUrlLink {
  padding: $spacing-unit;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3rem;
  font-size: math.div(14, 16) * 1rem;
  font-weight: 500;
  text-decoration: underline;
  color: $color-primary;

  &:hover {
    color: $color-primary-dark;
  }
}

.slideshowUrlLinkIcon {
  margin-left: $spacing-unit;
}

.actions {
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-tablet-min) {
    flex-direction: row;
  }
}

.actionButton {
  align-items: center;
  background: transparent;
  border: 0;
  color: $color-primary;
  cursor: pointer;
  display: flex;
  font-size: math.div(13, 16) * 1rem;
  font-weight: 600;
  justify-self: center;
  outline: none;
  padding: $spacing-unit 0;
  text-decoration: none;
  transition: color 0.1s;

  &:not(:last-child) {
    margin-right: $spacing-unit * 4;
  }

  &:hover {
    color: darken($color-primary, 5%);
  }
}

.primaryActionButton {
  color: $color-secondary;

  &:hover {
    color: $color-secondary-dark;
  }
}

.actionIcon {
  color: currentColor;
  border-radius: 50%;
  width: math.div(20, 16) * 1rem;
  height: math.div(20, 16) * 1rem;
  display: inline-block;
  margin-right: math.div(6, 16) * 1rem;
  opacity: 0.85; // Compensate to visually match the text color
}

.instructions {
  margin-top: 5 * $spacing-unit;

  .highlight {
    background: #fffdc8;
    display: inline;
  }

  p {
    margin: 2 * $spacing-unit 0 2 * $spacing-unit;
    line-height: 1.3;
  }

  a {
    text-decoration: underline;
  }
}

.navlink {
  display: inline-block;

  @media (min-width: $breakpoint-tablet-min) {
    margin-top: 2.5rem;
  }

  &:after {
    content: '»';
    display: inline-block;
    margin: 0 0 0 5px;
  }
}
