@import 'styles/variables';
@import 'styles/mixins';

.OptionButtonGroup {
  font-size: pxrem(11);
  text-transform: uppercase;
  display: flex;
  align-items: center;

  @media (max-width: $breakpoint-mobile-max) {
    width: 100%;
    justify-content: flex-start;
  }
}

.OptionButtonGroupLabel {
  color: $color-neutral-darker;
  font-weight: 600;
  display: inline-block;
  margin-right: 1.5 * $spacing-unit;
  letter-spacing: 0.05em;

  @media (max-width: $breakpoint-mobile-max) {
    min-width: 100px;
    text-align: left;
  }
}

.OptionButtonGroupButtons {
  display: flex;
  align-items: center;
}

.OptionButtonGroupButton {
  padding: 1px 2 * $spacing-unit 0;
  display: flex;
  align-items: center;
  font-size: pxrem(11);
  letter-spacing: 0.05em;
  cursor: pointer;
  height: pxrem(31);
  border-radius: 0 5px 5px 0;
  border-width: 1px 0;
  border-style: solid;
  border-color: $color-neutral-lighter;
  background: linear-gradient(0deg, $color-neutral-lighter, $color-neutral-lighter);
  user-select: none;
  color: $color-neutral-dark;
  overflow: hidden;

  input {
    display: none;
  }

  &:first-of-type {
    border-radius: 5px 0 0 5px;
    border-width: 1px 0 1px 1px;
  }

  &:last-of-type {
    border-width: 1px 1px 1px 0;
  }

  &:hover:not(.selected) {
    color: $color-primary-dark;
  }

  transition: color 0.075s, background 0.075s;

  &.selected {
    background: linear-gradient(0deg, $color-primary-dark, lighten($color-primary-dark, 4%));
    border-color: $color-primary-dark;
    color: #fff;
    text-shadow: 0 1px rgba(0, 0, 0, 0.1);
    cursor: default;
  }
}
