@import 'styles/variables';
@import 'styles/animations';

.loader {
  text-align: center;
  margin: 10% auto;
}

.editorPage {
  max-width: 740px;
}
