@use 'sass:math';

@import 'styles/mixins';
@import 'styles/variables';
@import 'styles/animations';

.summaryContent {
  border-top: 1px solid rgba(0, 0, 0, 0.075);
  display: flex;
  flex-wrap: wrap;
}

.summaryItem {
  flex: 0 0 33%;
  width: 33%;
  max-width: 33%;
  padding: 1.5rem 0 0.5rem 0;

  opacity: 0;
  animation: fade-in 0.2s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
  position: relative;

  @media (max-width: $breakpoint-mobile-max) {
    padding: 0.75rem 0 0.25rem 0;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

.summaryItemTitle {
  color: $color-primary-dark;
  letter-spacing: 0.075em;
  font-weight: 600;
  font-size: pxrem(10);
  margin-bottom: 0.4rem;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;

  &:hover {
    position: relative;
    z-index: 999;
  }
}

.summaryItemValue {
  font-size: pxrem(14);
  padding-right: 1rem;
}

.summaryItemValueExtra {
  color: $color-neutral;
  display: block;
  margin-top: 0.25rem;

  a {
    color: $color-neutral;

    &:hover {
      color: $color-primary;
      text-decoration: none;
    }
  }
}

.wordWrap {
  word-wrap: break-word;
}

.account {
  white-space: nowrap;
  line-height: 1.5;
}

.accountOwner {
  opacity: 0.5;
}

.linkButton {
  background: transparent;
  border: none;
  padding: 0;

  display: inline-flex;
  align-items: center;

  font-size: pxrem(13);
  font-weight: 600;
  color: $color-primary;
  padding: 0;
  outline: none;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: $color-primary-dark;
  }
}

.tooltipIcon {
  margin: -3px 0 0 0.25rem;
}

.tooltip {
  cursor: help;
  background: $color-neutral-darker;
  border-radius: 0.3em;
  color: $color-neutral-lighter;
  font-size: pxrem(13);
  line-height: 1.4;
  margin: 0 0 0 15px;
  max-width: 18em;
  min-width: 18em;
  padding: 1em;
  position: absolute;
  transition: all 0.2s;
  width: auto;
  font-weight: 500;
  display: none;
  z-index: 999;
  text-transform: none;
  letter-spacing: 0;

  .summaryItemTitle:hover & {
    display: block;
  }

  // tooltip
  &::before {
    -webkit-transform: translate(-100%, -50%);
    -moz-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    -o-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    border-bottom: 0.75em solid transparent;
    border-right: 0.75em solid $color-neutral-darker;
    border-top: 0.75em solid transparent;
    content: '';
    display: block;
    height: 0;
    left: 1px;
    position: absolute;
    top: 50%;
    width: 0;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownToggleButton {
  background-color: transparent;
  color: $color-primary;
  font-weight: 600;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 0.875rem;
  display: flex;
  align-items: center;

  &:hover {
    color: $color-primary-dark;
  }
}

.dropdownWrap {
  position: relative;
  background-color: #fff;
  border: 1px solid $color-neutral-light;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 14rem;
  margin-top: 0.5rem;
}

.dropdownLinks {
  display: flex;
  flex-direction: column;

  .navItem {
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 0.875rem;
    color: $color-neutral-dark;

    &:hover {
      background-color: $color-neutral-lighter;
    }
  }
}

.dropdownToggleButtonIcon {
  height: .65rem;
  width: .65rem;
  margin-left: 3px;
}
