@use 'sass:math';

@import 'styles/variables';

.codeEditor {
  border: 1px solid $color-neutral-light;
  border-radius: 0.25rem;
  color: $color-neutral-darker;
  display: block;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: math.div(12, 16) * 1rem;
  min-height: 215px;
  padding: $spacing-unit;
  margin-bottom: $spacing-unit;
  line-height: 1.3;
  width: 100%;

  // calculate size to dodge preview when: screensize > 800
  @media (min-width: 800px) {
    max-width: calc(100vw - ((100vw - 740px) / 2) - 448px);
  }

  // calculate size to dodge preview when: screensize > 1090
  @media (min-width: 1090px) {
    max-width: calc(100vw - ((100vw - 740px) / 2) - 574px);
  }

  &:focus {
    border-color: $color-primary;
    outline: none;
  }

  &:disabled {
    background-color: $color-neutral-lighter;
    border-color: $color-neutral-lighter;
    color: $color-neutral;
    cursor: not-allowed;
  }

  &::placeholder {
    color: $color-neutral;
  }
}
