@import 'styles/variables';
@import 'styles/mixins';

.themeBackgroundFields {
  @include breakpoint(1200px) {
    margin: -1rem 0 0;
    display: flex;
    align-items: center;
  }
}

.themeBackgroundSwitch {
  @include breakpoint(1200px) {
    margin: 1.5rem 0 0rem;
  }
}

.themeBackgroundInput {
  margin: -0.5rem 0 0;
  @include breakpoint(1200px) {
    margin: 1rem 0 0 1rem;
  }
}
