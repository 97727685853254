@use 'sass:math';

@import 'styles/variables';
@import 'styles/animations';

.referralCopier {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  height: 3rem;

  max-width: 30rem;
  margin: 0 0 2 * $spacing-unit;
}

.referralLinkContent {
  opacity: 0;
  position: absolute;
  z-index: 0;
}

input[type='text'].referralLinkInput {
  padding: $spacing-unit;

  display: flex;
  align-items: center;
  position: relative;
  line-height: 1.5;
  font-size: math.div(11, 16) * 1rem;

  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
  box-shadow: none !important;

  @media (min-width: $breakpoint-mobile-min) {
    padding: $spacing-unit $spacing-unit * 2;
    font-size: 1rem;
  }

  .referralCopierSuccess & {
    border-color: #74b93a !important;
  }
}

.referralLinkLoader {
  position: absolute;
  z-index: 2;
  left: 1rem;
  top: 1rem;

  @media (min-width: $breakpoint-mobile-min) {
    left: 1.5rem;
  }
}

.referralLinkAction {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
}

.referralLinkActionButton {
  z-index: 2;
  position: relative;

  user-select: none;
  height: 100%;

  border-radius: 0 0.25rem 0.25rem 0;
  border: none;
  text-align: center;
  font-weight: 700;
  min-width: 12em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: math.div(12, 16) * 1rem;

  background: linear-gradient(to bottom, #74b93a, #6dad36);
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;

  &:hover {
    background: linear-gradient(to bottom, darken(#74b93a, 3%), darken(#6dad36, 3%));
  }

  @media (min-width: $breakpoint-mobile-min) {
    font-size: math.div(14, 16) * 1rem;
    min-width: 12.5em;
  }

  &.success {
    background: linear-gradient(to bottom, darken(#74b93a, 5%), darken(#6dad36, 5%));
  }

  &:disabled {
    pointer-events: none;
  }

  .buttonText {
    animation: subtle-drop 0.2s;
    display: inline-block;
  }

  .icon {
    display: inline-block;
    height: 1em;
    width: 1em;
    margin: 0 2px 0 -4px;
    position: relative;
    top: -2px;
  }
}
