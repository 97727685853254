@use 'sass:math';

@import 'styles/mixins';
@import 'styles/variables';

.field {
  display: block;
  margin-bottom: math.div(12, 16) * 1rem;
  position: relative;
}

.field--switch {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;

  @include breakpoint($breakpoint-mobile-max) {
    flex-direction: row;
  }
}

.error {
  display: block;
}

.disabled {
  display: none;
}
