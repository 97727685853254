@use 'sass:math';

@import 'styles/variables';

.SocialMediaAccount {
  display: inline-flex;
  align-items: flex-start;
  text-align: left;
  min-width: 17rem;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }

  & + & {
    margin-top: $spacing-unit * 2;
  }
}

.SocialMediaAccountAvatar {
  background-color: $color-neutral-lighter;
  border-radius: 100%;
  height: 3rem;
  width: 3rem;
  overflow: hidden;
  margin-right: 1rem;

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.SocialMediaAccountDetails {
  > * + * {
    margin-top: $spacing-unit;
  }
}

.SocialMediaAccountButtonsContaner {
  display: flex;
  justify-content: space-between;
  column-gap: .3rem;
}

.SocialMediaAccountName {
  font-size: math.div(14, 16) * 1rem;
  font-weight: 600;
  margin: $spacing-unit * 0.5 0 0;
  line-height: 1.1;
}

.SocialMediaAccountConnectedAt {
  color: $color-neutral-dark;
  font-size: math.div(12, 16) * 1rem;
}
