@use 'sass:math';

@import 'styles/mixins';
@import 'styles/variables';

.select {
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.5rem;
  width: 100%;

  -moz-appearance: none;
  appearance: none;

  background: #fff;
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%228px%22%20height%3D%2216px%22%20viewBox%3D%222288%20312%208%2016%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cg%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20transform%3D%22translate(2288.000000%2C%20312.000000)%22%3E%3Cpolygon%20fill%3D%22%2337474F%22%20points%3D%220%2010%202.98177083%2010%208%2010%204%2016%22%3E%3C%2Fpolygon%3E%3Cpolygon%20fill%3D%22%2337474F%22%20points%3D%224%200%208%206%205.01822917%206%200%206%22%3E%3C%2Fpolygon%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 8px center;

  border: 1px solid $color-neutral-light;
  border-radius: 0.25rem;
  box-shadow: none;
  color: $color-neutral-dark;
  font-size: math.div(14, 16) * 1rem;
  font-weight: 400;
  cursor: pointer;

  @include breakpoint($breakpoint-mobile-max) {
    width: 50%;
  }

  &:focus {
    border-color: $color-primary;
    outline: none;
  }

  &:disabled {
    background-color: $color-neutral-lighter;
    border-color: $color-neutral-lighter;
    color: $color-neutral;
    cursor: not-allowed;
  }
}
