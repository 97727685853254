@use 'sass:math';

@import 'styles/variables';

@keyframes fadeBgIn {
  0% {
    background-color: rgba($color-neutral-darker, 0);
  }

  100% {
    background-color: rgba($color-neutral-darker, 0.25);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.ModalUnderlay {
  display: flex;
  place-items: center;
  background-color: rgba($color-neutral-darker, 0);
  backdrop-filter: blur(0);
  margin: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 300;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-name: fadeBgIn;
  transform: translate3d(0, 0, 0);
  width: 100vw;
}

.ModalWrapper {
  max-height: 100%;
  overflow: auto;
  padding: 10 * $spacing-unit $spacing-unit;
  width: 100vw;

  @media (min-height: 660px) {
    padding-bottom: 30vh;
  }

  @media (max-width: $breakpoint-mobile-max) {
    padding: 4 * $spacing-unit $spacing-unit;
  }
}

.ModalBox {
  background: #fff;
  border-radius: $spacing-unit;
  box-shadow: 0 0.15rem 0.5rem rgba($color-neutral-darker, 0.1), 0 1rem 2rem rgba($color-neutral-darker, 0.1);
  max-width: 100%;
  margin: 0 auto;
  padding: 2 * $spacing-unit 5 * $spacing-unit 3.5 * $spacing-unit;
  width: 40rem;
  position: relative;

  transform: scale(0);
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.135, 0.86, 0.32, 1);
  animation-delay: 0.2s;
  animation-duration: 0.3s;
  animation-name: scaleIn;

  @media (max-width: $breakpoint-mobile-max) {
    padding: 1.5 * $spacing-unit 3 * $spacing-unit 3 * $spacing-unit;
  }
}

.ModalBoxContent {
  opacity: 0;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  animation-delay: 0.4s;
  animation-name: fadeIn;
}

.ModalTitle {
  font-size: math.div(21, 16) * 1rem;
  font-weight: 700;
  margin: 0.5em 0;

  @media (max-width: $breakpoint-mobile-max) {
    font-size: math.div(18, 16) * 1rem;
  }
}

.ModalContent {
  font-size: 1rem;
  line-height: 3 * $spacing-unit;

  @media (max-width: $breakpoint-mobile-max) {
    font-size: math.div(14, 16) * 1rem;
  }

  > * + * {
    margin-top: 3 * $spacing-unit;
  }
}

.ModalActions {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $breakpoint-mobile-max) {
    flex-wrap: wrap;
  }
}

// Center mode for short message with single action
.CenterModal {
  hr {
    background-color: transparent;
  }

  .ModalBox {
    max-width: 25rem;
    text-align: center;
  }

  .ModalActions {
    justify-content: center;
  }
}
