@import 'styles/variables';

.checkbox {
  align-items: center;
  background: #fff;
  border-radius: 20px;
  display: flex;
  height: 20px;
  justify-content: center;
  margin: 0 2rem 0 0;
  width: 20px;
  transition: all 0.1s;
  border: 2px solid $color-neutral-light;
  border-radius: 0.25rem;
  position: relative;

  &.checked {
    background: $color-secondary;
    border-color: $color-secondary;

    &::after {
      border-color: #fff;
      border-style: solid;
      border-width: 0 2px 2px 0;
      content: '';
      display: block;
      height: 12px;
      left: 5px;
      position: absolute;
      top: 0;
      transform: rotate(45deg);
      width: 6px;
    }
  }
}

.checkboxFieldLabel {
  font-weight: 500;
  align-items: center;
  user-select: none;
  cursor: pointer;
  justify-content: flex-start;

  .checkbox {
    margin-right: 0.75rem;
    top: -1px;

    &:hover:not(.checked) {
      border-color: $color-neutral;
    }
  }
}

.checkboxFieldCheckboxHide {
  opacity: 0;
  overflow: hidden;
  width: 0;
}
