@use 'sass:math';

@import 'styles/variables';

.linkButton {
  background: transparent;
  border: none;
  padding: 0;

  height: 2.5rem;
  line-height: 2.5rem;
  display: inline-flex;
  align-items: center;

  font-size: math.div(14, 16) * 1rem;
  font-weight: 500;
  color: $color-primary;
  margin-left: $spacing-unit;
  padding: 0 2 * $spacing-unit;
  outline: none;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $color-primary-dark;
  }
}

.displayHelpText {
  margin-top: -0.25rem;
  margin-bottom: 0.75rem;
  padding-right: $spacing-unit * 2;
}

.timestampFormatField {
  margin-top: -0.4rem;
  padding-left: 0.25rem;
}

.cssError {
  margin: 1.5 * $spacing-unit 0 0.5 * $spacing-unit;
}

.submitError {
  margin-left: 2 * $spacing-unit;
}

.submitErrorIcon {
  margin-right: 0.5 * $spacing-unit;
  position: relative;
  top: 2px;
}

.lastField {
  border-bottom: 1px solid $color-neutral-lighter;
  padding-bottom: 1.5 * $spacing-unit;
}
