@import './variables';

p {
  line-height: 1.2;
  margin: 0;

  p + p {
    margin-top: 3 * $spacing-unit;
  }
}

strong {
  font-weight: 600;
}

a {
  color: $color-primary;
  text-decoration: none;

  &:focus,
  &:hover {
    color: $color-primary-dark;
    text-decoration: underline;
  }
}
