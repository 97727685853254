@use 'sass:math';

@import 'styles/variables';
@import 'styles/mixins';

.wrap {
  display: inline-block;
  width: 300px;
}

.loader {
  align-items: center;
  background: $color-neutral-lighter;
  border: 1px solid $color-neutral-lighter;
  border-radius: 3px;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.image {
  border: 1px solid $color-neutral-lighter;
  border-radius: 3px;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.placeholder {
  align-items: center;
  background: $color-neutral-lighter;
  border: 1px solid $color-neutral-lighter;
  border-radius: 3px;
  bottom: 0;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;

  svg {
    height: 42px;
    width: 52px;
    color: $color-neutral-light;
  }
}

.uploadPreview {
  height: 169px;
  max-width: math.div(300, 16) * 1rem;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.uploadActionButton {
  background-color: $color-neutral-lighter;
  border: 1px solid $color-neutral-lighter;
  border-radius: 3px;
  display: flex;
  margin-top: $spacing-unit;
  max-width: math.div(300, 16) * 1rem;
  padding: 0.75rem 0.5rem;
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  font-size: math.div(13, 16) * 1rem;
  line-height: math.div(13, 16) * 1rem;
  color: $color-primary-dark;
  font-weight: 600;
  outline: none;
  text-decoration: none;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }
}

.buttonIcon {
  margin-right: $spacing-unit * 0.5;
}
