@use 'sass:math';

@import 'styles/variables';
@import 'styles/animations';
@import 'styles/mixins';

$success-background: #e6f7ea;

.embed {
  background: $color-neutral-lighter;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  padding: $spacing-unit;

  overflow: hidden;
  position: relative;
  cursor: pointer;
  height: 3rem;

  transition: background 0.2s, color 0.2s;
  margin-bottom: 2 * $spacing-unit;

  @media (min-width: $breakpoint-mobile-min) {
    border-radius: 0.5rem 0 0.5rem 0.5rem;
    height: 4rem;

    &.large {
      padding: $spacing-unit 1.5 * $spacing-unit;
      height: 5rem;
      margin-top: 0;
    }
  }

  &.embedSuccess {
    background-color: $success-background;
    color: $color-secondary-dark;
  }
}

.embedCode {
  padding: $spacing-unit 0;

  display: flex;
  align-items: center;
  position: relative;
  font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  overflow: hidden;
  line-height: 1.5;
  font-size: math.div(11, 16) * 1rem;

  top: 0;
  bottom: 0;
  position: absolute;
  white-space: pre;

  @media (min-width: $breakpoint-mobile-min) {
    padding: $spacing-unit;
    font-size: math.div(13, 16) * 1rem;
  }
}

.embedCodeAction {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  padding: $spacing-unit $spacing-unit * 1.5;
  background: $color-neutral-lighter;
  transition: background 0.2s;

  display: flex;
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: -5.5rem;
    width: 6rem;
    background: linear-gradient(90deg, rgba($color-neutral-lighter, 0), $color-neutral-lighter);
    top: 0;
    bottom: 0;
    transition: background 0.2s;

    @media (min-width: $breakpoint-mobile-min) {
      left: -6.5rem;
      width: 7rem;
    }
  }

  .embedSuccess & {
    background: $success-background;

    &:before {
      background: linear-gradient(90deg, rgba($success-background, 0), $success-background);
    }
  }
}

.embedCodeActionButton {
  z-index: 2;
  position: relative;

  user-select: none;
  height: 100%;

  border-radius: 0.25rem;
  border: none;
  text-align: center;
  font-weight: 700;
  min-width: 12em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: math.div(12, 16) * 1rem;

  background: linear-gradient(to bottom, #308fce, #1869ba);
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;

  &:hover {
    background: linear-gradient(to bottom, darken(#308fce, 3%), darken(#1869ba, 3%));
  }

  @media (min-width: $breakpoint-mobile-min) {
    font-size: math.div(14, 16) * 1rem;
    min-width: 14em;

    .large & {
      height: calc(100% - 0.5em);
      margin: 0.25rem 0;
    }
  }

  &.success {
    background: linear-gradient(to bottom, #74b93a, #6dad36);
  }

  .buttonText {
    animation: subtle-drop 0.2s;
    display: inline-block;
  }

  .icon {
    display: inline-block;
    height: 1em;
    width: 1em;
    margin: 0 2px 0 -4px;
    position: relative;
    top: -2px;
  }
}

.tabs {
  display: flex;
  justify-content: flex-start;

  @media (min-width: $breakpoint-mobile-min) {
    justify-content: flex-end;
    margin-top: -1.75rem;

    &.largeMode {
      margin-top: -1rem;
    }
  }
}

.tab {
  background: #fff;
  border: 1px solid $color-neutral-lighter;
  border-radius: 0.25rem 0.25rem 0 0;
  color: $color-neutral-darker;
  cursor: pointer;
  font-size: pxrem(9.5);
  font-weight: 600;
  letter-spacing: 1px;
  padding: 0.5rem 0.75rem;
  text-transform: uppercase;
  transition: all 0.2s;

  &.default {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &.selected {
    background: $color-neutral-lighter;
  }
}
