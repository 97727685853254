@import 'styles/animations';
@import 'styles/variables';
@import 'styles/mixins';

.subscriptionStatusCard {
  color: $color-neutral-darker;
  position: relative;
}

.label {
  color: $color-neutral-dark;
  font-size: pxrem(14);
  font-weight: bold;
  margin-bottom: $spacing-unit * 1;
}

.planName {
  font-size: pxrem(32);
  font-weight: 800;
  margin: 0 0 $spacing-unit * 3;
  text-transform: capitalize;

  @include breakpoint($breakpoint-mobile-min) {
    margin: $spacing-unit 0 $spacing-unit * 6;
  }
}

.planChange {
  color: $color-neutral;
  font-size: pxrem(14);
  font-weight: 600;
  margin-top: 0;
}

.details {
  display: block;
  width: 100%;

  @include breakpoint($breakpoint-mobile-min) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.detail {
  display: block;
  font-weight: 400;
  margin: 0 0 4 * $spacing-unit;
  word-break: break-word;

  @include breakpoint($breakpoint-mobile-min) {
    flex: 1 0 0;
    margin: 0 0 6 * $spacing-unit;

    &.detailFullWidth {
      flex: 100% 0 0;
    }
  }

  .detailLabel {
    color: $color-primary-dark;
    font-size: pxrem(11);
    font-weight: 700;
    letter-spacing: 0.1em;
    margin-bottom: $spacing-unit;
    text-transform: uppercase;
  }
}

.billinCycleValue {
  text-transform: capitalize;
}
