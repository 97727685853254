@import 'styles/variables';

.appWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.appWrapperHeader,
.appWrapperMain,
.appWrapperFooter {
  width: 100%;
}

.appWrapperMain {
  flex-grow: 1;

  .appWrapperHeader + & {
    padding-top: $header-height;
    padding-bottom: $footer-height + 2 * $spacing-unit;

    @media (max-width: $breakpoint-mobile-max) {
      padding-top: $header-height-mobile;
      padding-bottom: $footer-height-mobile + 2 * $spacing-unit;
    }
  }
}

.appWrapperHeader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
}

.appWrapperHeaderWithAlertShown {
  top: 50px;

  @media (max-width: 767px) {
    top: 34px;
  }

  + .appWrapperMain {
    padding-top: 9rem;

    @media (max-width: 767px) {
      padding-top: 7rem;
    }
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  20% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.appWrapperFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
  animation-duration: 1.5s;
  animation-name: slideUp;
}

.appLoader {
  min-height: 50vh;
  display: flex;
  align-items: center;
}
