@use 'sass:math';

@import 'styles/variables';
@import 'styles/mixins';

.radio {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin: 0;
  padding: 2px 0;
  width: auto;
  color: $color-neutral-dark;
  justify-content: flex-start;
  height: 1.75rem;

  input {
    margin-right: $spacing-unit;
    position: relative;
    top: -1px;
  }
}

.note {
  display: block;
  color: $color-neutral;
  font-size: math.div(12, 16) * 1rem;
  font-weight: 400;
  line-height: 1;
  margin-left: $spacing-unit;
  text-transform: uppercase;
}
