@import 'styles/variables';

.displayHelpText {
  margin-top: -0.25rem;
  margin-bottom: 0.75rem;
  padding-right: $spacing-unit * 2;
}

.labelNote {
  display: inline-block;
  margin-left: 10px;
  color: $color-neutral;
  font-size: 0.8em;
  font-weight: 400;
  line-height: 1;
}

.validationError {
  color: $color-error-dark;
  font-weight: bold;
}
