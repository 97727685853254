@import 'styles/variables';
@import 'styles/mixins';

.iframe {
  --content-scale: 1;

  border: 0;
  border-radius: 8px;
  position: absolute;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  transform-origin: 0 0;
  transform: scale(calc(1 / var(--content-scale)));
  height: calc((var(--content-scale) * 100%) - (var(--content-scale) * 10px));
  width: calc((var(--content-scale) * 100%) - (var(--content-scale) * 10px));

  &.embedScreen {
    --content-scale: 2.5;
    pointer-events: none;
    overflow: hidden;

    &.pipMode {
      --content-scale: 3;
    }
  }

  @media (min-width: $breakpoint-mobile-min) {
    left: 15px;
    position: absolute;
    top: 15px;
    bottom: 15px;
    right: 15px;
    width: calc((var(--content-scale) * 100%) - (var(--content-scale) * 30px));
    height: calc((var(--content-scale) * 100%) - (var(--content-scale) * 30px));
    
  }

  &.pipMode {
    @media (min-width: $breakpoint-embed-pip-mode) {
      z-index: 3;
      right: 10px;
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 35px;
      border-radius: 8px;
      width: calc((var(--content-scale) * 100%) - (var(--content-scale) * 20px));
      height: calc((var(--content-scale) * 100%) - (var(--content-scale) * 60px));

      &.embedScreen {
        --content-scale: 4;
      }
    }

    // Bigger mode
    @media (min-width: 1090px) {
      left: 15px;
      right: 15px;
      bottom: 50px;
      top: 15px;
      width: calc((var(--content-scale) * 100%) - (var(--content-scale) * 30px));
      height: calc((var(--content-scale) * 100%) - (var(--content-scale) * 75px));

      &.embedScreen {
        --content-scale: 3;
      }
    }
  }
}
