@import 'styles/variables';
@import 'styles/mixins';

.linkButton {
  background: transparent;
  border: none;
  padding: 0;

  height: 2.5rem;
  line-height: 2.5rem;
  display: inline-flex;
  align-items: center;

  font-size: pxrem(14);
  font-weight: 500;
  color: $color-primary;
  margin-left: $spacing-unit;
  padding: 0 2 * $spacing-unit;
  outline: none;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $color-primary-dark;
  }
}

.displayHelpText {
  margin-top: -0.25rem;
  margin-bottom: 0.75rem;
  padding-right: $spacing-unit * 2;
}

.timestampFormatField {
  margin-top: -0.4rem;
  padding-left: 0.25rem;
}

.sectionSelectField {
  @media (min-width: $breakpoint-mobile-min) {
    max-width: 90%;
  }
}

.cssError {
  margin: 1.5 * $spacing-unit 0 0.5 * $spacing-unit;
}

.submitError {
  margin-left: 2 * $spacing-unit;
}

.submitErrorIcon {
  margin-right: 0.5 * $spacing-unit;
  position: relative;
  top: 2px;
}

.lastField {
  border-bottom: 1px solid $color-neutral-lighter;
  padding-bottom: 1.5 * $spacing-unit;
}

.childField {
  margin: -$spacing-unit 0 2 * $spacing-unit;
}

.fieldsetIntro {
  color: $color-neutral;
  display: block;
  font-size: pxrem(13);
  line-height: 1.4;
  margin: -$spacing-unit 0 $spacing-unit * 2 0;
  font-weight: 400;
  max-width: pxrem(380);

  @media (min-width: 1450px) {
    max-width: pxrem(580);
  }

  a {
    color: $color-neutral;
    text-decoration: underline;

    &:hover {
      color: $color-primary;
    }
  }
}
