@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/variables';

.pricing {
  color: $color-neutral;
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;

  @media (max-width: $breakpoint-tablet-max) {
    margin: 1rem 0 0;
    display: block;
  }
}

.notAllowedMessage {
  color: $color-neutral-dark;
  font-weight: bold;
  font-size: 1.25rem;
  margin: 0 0 $spacing-unit;
}

.pricingVat {
  color: $color-neutral;
  display: inline-block;
  font-size: pxrem(14);
  margin-left: 0.25rem;
}

.pricingInfo {
  color: $color-neutral;
  font-size: pxrem(14);
  font-weight: 500;
  margin: $spacing-unit * 2 0 0;
}

.addonError {
  animation: subtle-drop 0.3s;
  color: $color-error-dark;
  font-size: pxrem(14);
  font-weight: 500;
  margin: $spacing-unit * 2 0;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.addonSuccess {
  animation: subtle-drop 0.3s;
  display: inline-block;
  color: $color-secondary;
  font-size: pxrem(15);
  font-weight: bold;
  margin: 0;
}

.addonSuccessIcon {
  margin-right: 0.25rem;
  float: left;
}
