@import 'styles/variables';
@import 'styles/mixins';

.centeredNoticeBox {
  & > div {
    text-align: center;
    width: 100%;
  }
}

.declineCodeNoticeBox {
  & > div {
    max-width: pxrem(700);
  }
}
