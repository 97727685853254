@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/variables';

.badge {
  animation: subtle-drop 0.4s;
  position: absolute;
  left: -8px;
  top: -5px;
  font-size: pxrem(8);
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  background: $color-primary;
  border-radius: 30px;
  padding: 3px 5px;
  letter-spacing: 0.05em;
  user-select: none;
  pointer-events: none;
}
