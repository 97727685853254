@use 'sass:math';

@import 'styles/variables';

.warningWrapper {
  background: #ffffff;
  border-radius: 0.25rem;
  border: 1px dashed $color-error;
  bottom: 1rem;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.1);
  font-size: math.div(10, 16) * 1rem;
  font-weight: 500;
  left: 1rem;
  letter-spacing: 0.05em;
  padding: 1rem;
  position: fixed;
  text-transform: uppercase;
  z-index: 1000;
}

.highlight {
  color: $color-error;
  font-weight: bold;
  margin-right: $spacing-unit;
}

.emojiIcon {
  font-size: 1.4em;
  margin-right: 0.3rem;
  vertical-align: middle;
}
