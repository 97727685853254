@use 'sass:math';

@import 'styles/variables';

.PillTag {
  display: inline-flex;
  background: $color-neutral-lighter;
  border-radius: 100px;
  color: $color-neutral-darker;
  font-size: math.div(10, 16) * 1rem;
  font-weight: 600;
  height: $spacing-unit * 3.5;
  line-height: $spacing-unit * 3.5;
  padding: 0 2.5 * $spacing-unit;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  white-space: nowrap;

  &[data-variant='blue'] {
    background: $color-primary-lighter;
    color: $color-primary-dark;
  }

  &[data-variant='green'] {
    background: $color-secondary-lighter;
    color: $color-secondary-dark;
  }

  &[data-variant='red'] {
    background: $color-error-lighter;
    color: $color-error-dark;
  }
}
