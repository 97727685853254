@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/variables';

.feedsIntro {
  display: flex;
  justify-content: space-between;
  margin: -2 * $spacing-unit 0 0.5 * $spacing-unit 0;
}

.feedsIntroTextcol {
  flex: 100% 0 0;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  @media (min-width: 900px) {
    max-width: none;
    margin: 0;
    flex: 57% 0 0;
    width: 57%;
  }
}

.feedsIntroList {
  margin: 2 * $spacing-unit 0;
  padding: 0 0 0 0.5 * $spacing-unit;
  list-style: none;

  li {
    font-size: pxrem(13);
    line-height: 1.4;
    margin-bottom: 1.5 * $spacing-unit;
    padding-left: $spacing-unit * 3;
    position: relative;
    color: $color-neutral-darker;

    @media (min-width: 900px) {
      font-size: pxrem(15);
    }

    &:before {
      color: $color-neutral-light;
      content: '•';
      display: inline-block;
      font-size: 1.25rem;
      left: 0;
      line-height: 0.875rem;
      position: absolute;
      top: 1px;
    }

    a {
      text-decoration: underline;
      color: $color-primary;

      &:hover {
        color: $color-primary-dark;
      }
    }
  }
}

.externalLinkIcon {
  color: $color-neutral;
  font-size: 0.75em;
  margin-left: 0.5 * $spacing-unit;
}

.feedsIntroVisualization {
  min-height: 190px;
  overflow: hidden;
  padding-left: 35px;
  position: relative;
  width: 330px;
  z-index: 1;

  @media (max-width: 899px) {
    display: none;
  }

  &:after {
    background: linear-gradient(90deg, rgba(#fff, 0) 0%, rgba(#fff, 0) 0%, rgba(#fff, 0.9) 100%);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
    transform: translate3d(0, 0, 1000px);
  }
}

@keyframes feed-viz-appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.feedsIntroVisualizationItem {
  align-items: center;
  background: #fff;
  border-radius: 30px;
  box-shadow: 4px 8px 20px rgba(57, 75, 107, 0.2);
  color: rgba(137, 164, 181, 0.9);
  display: flex;
  justify-content: center;
  position: absolute;
  transform-origin: 50% 0%;

  // Animation
  opacity: 0;
  animation: feed-viz-appear 1.4s ease-in-out;
  animation-fill-mode: forwards;

  &:first-child {
    z-index: 3;
    height: 140px;
    top: 20px;
    width: 140px;
    transform: perspective(75vw) rotateY(-20deg) rotate(7deg) skew(3deg, -1deg);

    svg {
      height: 85px;
      width: 85px;
    }
  }

  &:nth-child(2) {
    animation-delay: 0.2s;
    transform: perspective(75vw) rotateY(-20deg) rotate(10deg) skew(3deg, -1deg);
    margin-left: 5.8rem;
    z-index: 2;

    height: 126px;
    top: 28px;
    width: 126px;

    svg {
      height: 75px;
      width: 75px;
    }
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
    transform: perspective(75vw) rotateY(-20deg) rotate(14deg) skew(3deg, -1deg);
    margin-left: 11rem;
    top: 50px;
    z-index: 1;

    height: 110px;
    width: 110px;

    svg {
      height: 55px;
      width: 55px;
    }
  }
}
