@use 'sass:math';

@import 'styles/variables';
@import 'styles/animations';

.link {
  display: inline-block;
  text-decoration: none;
  color: $color-primary;
  font-weight: 500;
  font-size: math.div(14, 16) * 1rem;

  &:hover {
    text-decoration: underline;
  }
}
