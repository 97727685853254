@import 'styles/mixins';
@import 'styles/variables';

.Heading1 {
  color: $color-neutral-darker;
  display: flex;
  align-items: center;
  font-size: pxrem(32);
  font-weight: 800;
  letter-spacing: -0.01em;
  text-align: center;
  line-height: 6 * $spacing-unit;
  margin: 0 0 4 * $spacing-unit;

  @media (max-width: $breakpoint-tablet-max) {
    font-size: pxrem(28);
    line-height: 5 * $spacing-unit;
    margin: 0 0 3 * $spacing-unit;
  }

  @media (max-width: $breakpoint-mobile-max) {
    font-size: pxrem(24);
    line-height: 4 * $spacing-unit;
    margin: 0 0 2 * $spacing-unit;
  }

  &::before,
  &::after {
    background-color: $color-neutral-lighter;
    display: block;
    flex-grow: 1;
    content: '';
    height: 2px;
  }

  &::before {
    margin-right: 1em;
  }

  &::after {
    margin-left: 1em;
  }
}

.Heading2 {
  color: $color-neutral-darker;
  border-bottom: 2px solid $color-neutral-lighter;
  font-weight: 700;
  font-size: pxrem(21);
  margin: 0 0 2 * $spacing-unit;
  line-height: 3 * $spacing-unit;
  padding-bottom: $spacing-unit;

  @media (max-width: $breakpoint-tablet-max) {
    font-size: pxrem(18);
  }

  @media (max-width: $breakpoint-mobile-max) {
    font-size: pxrem(16);
  }
}

.Heading3 {
  color: $color-neutral-darker;
  font-weight: 600;
  font-size: pxrem(16);
  line-height: 3 * $spacing-unit;
  margin: 0 0 $spacing-unit * 0.5;
}
