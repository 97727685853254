@use 'sass:math';

@import 'styles/animations';
@import 'styles/variables';
@import 'styles/mixins';

@keyframes toPipMode {
  0% {
    margin: -100px 100px 0 0;
    opacity: 0;
  }

  100% {
    margin: 0;
    opacity: 1;
  }
}

.preview {
  display: block;
  margin: 4 * $spacing-unit 0;

  &.pipMode {
    @media (min-width: $breakpoint-embed-pip-mode) {
      background: #f8f8f8;
      border-radius: 10px;
      min-height: 500px;
      position: relative;
      z-index: 2;
    }

    .previewArea {
      @media (min-width: $breakpoint-embed-pip-mode) {
        animation: toPipMode 0.25s ease-in-out;
        background: $color-neutral-lighter;
        border-radius: 10px;
        height: 415px;
        margin: 0;
        position: fixed;
        right: 15px;
        top: 105px;
        width: 548px;
        z-index: 3;

        &.previewAreaCarousel {
          height: 335px;

          &[data-height='540'] {
            height: 375px;
          }
        }
      }

      @media (max-width: 1090px) and (min-width: $breakpoint-embed-pip-mode) {
        height: 375px;
        width: 423px;
      }

      @media (min-width: 1850px) and (min-width: 800px) {
        right: calc(50% - 915px);
      }
    }

    @media (min-width: $breakpoint-embed-pip-mode) {
      .previewBrowser {
        border-width: 11px;
        height: 345px;
        min-height: 0;
      }

      .previewFooter {
        bottom: 0px;
        display: block;
        padding: 11px;
        position: absolute;
        width: 100%;
        z-index: 3;
      }

      .previewActions {
        margin-top: math.div(12, 16) * 1rem;
        display: inline-block;
        position: relative;
        z-index: 2;
      }

      .previewLink {
        height: 4 * $spacing-unit;
        line-height: 4 * $spacing-unit;
        font-size: math.div(13, 16) * 1rem;
        font-weight: 500;

        &.disabled {
          display: none;
        }
      }

      .unsavedChanges {
        animation: subtle-drop 0.15s;
        margin-top: 1rem;
        float: right;
        display: inline-block;
        color: $color-error-dark;
        font-size: 0.75em;
        font-weight: 400;
        line-height: 20px;
        margin-right: 0.25rem;
      }

      .chatWithUsMessage {
        top: 9px;
        margin-left: 4px;
        font-size: math.div(11, 16) * 1rem;
      }
    }

    // Small version of pip window
    @media (min-width: $breakpoint-embed-pip-mode) and (max-width: 1090px) {
      .previewActions {
        display: flex;
        flex-direction: column;
        width: 45%;
        float: left;
      }
    }

    @media (min-width: 1090px) {
      .chatWithUsMessage {
        font-size: math.div(14, 16) * 1rem;
      }
    }
  }
}

.previewArea {
  position: relative;
}

.chatWithUsMessage {
  color: $color-error-dark;
  font-size: math.div(14, 16) * 1rem;
  line-height: 1.2;
  margin: 0 0 $spacing-unit;
  position: relative;
  top: 12px;
  display: inline-block;

  a {
    color: $color-error-dark;
    text-decoration: underline;

    &:hover {
      color: $color-primary;
    }
  }
}

.previewFooter {
  position: relative;
}

.unsavedChanges {
  display: none;
}

.previewActions {
  display: none;
}

.previewCopyButton {
  margin-left: 11px !important;

  &:not(.success):hover {
    background: linear-gradient(to bottom, darken(#308fce, 3%), darken(#1869ba, 5%));
  }
}

.previewLink {
  color: $color-primary;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-size: math.div(14, 16) * 1rem;
  float: right;
  margin-top: math.div(12, 16) * 1rem;
  position: relative;
  z-index: 2;

  &.disabled {
    color: $color-neutral;
    pointer-events: none;
    touch-action: none;
  }

  &:hover {
    color: $color-primary-dark;
  }
}

.previewLinkIcon {
  height: 0.85rem;
  margin-left: $spacing-unit;
  top: -1px;
  position: relative;
  width: auto;
}
