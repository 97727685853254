@use 'sass:math';

@import 'styles/variables';

.HelpText {
  display: flex;
  color: $color-neutral-darker;
  cursor: help;
  font-size: math.div(13, 16) * 1rem;
  opacity: 0.7;

  a {
    color: inherit;
    text-decoration: underline;
  }

  &:hover {
    opacity: 1;

    a {
      color: $color-primary-dark;
      text-decoration: underline;
    }
  }
}

.HelpTextIcon {
  color: $color-neutral;
  transition: all 0.3s ease-in-out;
  position: relative;
  top: 0.15em;
  margin-right: $spacing-unit;
  flex-shrink: 0;

  .HelpText:hover & {
    color: $color-primary;
  }
}

.HelpTextContent {
  line-height: 2.25 * $spacing-unit;
}
