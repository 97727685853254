@import 'styles/variables';
@import 'styles/mixins';

.gardePromoContainer {
  display: inline-block;
  font-size: .85rem;
  font-weight: 500;
  line-height: 1.2;
  margin-left: .1em;
  position: relative;
  top: .1em;
  vertical-align: middle;

  > fl-icon {
    cursor: help;
  }
}

.gardePromo {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 1rem rgba(#000, 0.1);
  font-size: 0.75rem;
  opacity: 0;
  overflow: hidden;
  position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  transition: 0.5s;
  white-space: nowrap;

  @media (max-width: 400px) {
    margin-bottom: 0;
    max-height: 0;
    max-width: 0;
    left: auto;
    right: 0;
  }

  .gardePromo__lineBreak {
    @media (min-width: 520px) {
      display: none;
    }
  }

  .gardePromoContainer:hover & {
    max-width: 400px;
    max-height: 200px;
    opacity: 1;
    padding: 6px 10px 10px;
    top: 24px;
  }

  fl-icon {
    display: inline-block;
    position: relative;
    top: 3px;
    vertical-align: middle;
  }
}
