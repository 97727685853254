@use 'sass:math';

@import 'styles/mixins';
@import 'styles/variables';

.helpText {
  transition: 200ms;
  color: $color-neutral;
  cursor: help;
  display: block;
  font-size: math.div(13, 16) * 1rem;
  line-height: 1.4;
  margin-top: 0.7 * $spacing-unit;
  font-weight: 400;

  a {
    color: $color-neutral;
    text-decoration: underline;

    &:hover {
      color: $color-primary;
    }
  }

  @include breakpoint($breakpoint-embed-inline-columns) {
    &:not(.noPadding) {
      padding-left: 30%;
    }
  }
}

.tooltipped {
  @include breakpoint($breakpoint-mobile-max, 'max') {
    left: auto !important;
  }

  @include breakpoint($breakpoint-mobile-max) {
    background: $color-neutral-darker;
    border-radius: 0.3em;
    color: $color-neutral-lighter;
    flex: 1;
    font-size: 13px;
    margin: 0 0 0 15px;
    max-width: 20em;
    min-width: 20em;
    padding: 1em;
    position: absolute;
    transition: all 0.2s;
    width: auto;
    font-weight: 500;
    z-index: 99;

    &.withImage {
      max-width: 30em;
    }

    // tooltip
    &::before {
      -webkit-transform: translate(-100%, -50%);
      -moz-transform: translate(-100%, -50%);
      -ms-transform: translate(-100%, -50%);
      -o-transform: translate(-100%, -50%);
      transform: translate(-100%, -50%);
      border-bottom: 0.75em solid transparent;
      border-right: 0.75em solid $color-neutral-darker;
      border-top: 0.75em solid transparent;
      content: '';
      display: block;
      height: 0;
      left: 1px;
      position: absolute;
      top: 50%;
      width: 0;
    }
  }
}
