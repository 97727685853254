@import 'styles/animations';
@import 'styles/variables';

.SaveBanner {
  animation: fade-in 0.5s;

  display: flex;
  flex-direction: row;
  align-items: center;

  background: linear-gradient(0deg, rgba($color-neutral-lighter, 0), $color-neutral-lighter);
  border-radius: 15px;
  margin: 0 0 1rem;
  padding: 0.75rem;
  position: relative;

  @media (min-width: $breakpoint-mobile-min) {
    padding: 0.75rem 4rem 0.75rem 1rem;
    flex-direction: row;
  }
}

.SaveBannerIllustration {
  height: 100%;
  width: 6rem;

  @media (min-width: $breakpoint-mobile-min) {
    height: 6rem;
    width: 8rem;
  }

  @media (min-width: $breakpoint-tablet-min) {
    height: 8rem;
    width: 11rem;
  }
}

.SaveBannerContent {
  padding-left: 1rem;

  @media (min-width: $breakpoint-mobile-min) {
    padding-left: 2.25rem;
  }

  @media (min-width: $breakpoint-tablet-min) {
    padding-left: 3.5rem;
  }
}

.SaveBannerContentTitle {
  font-size: 1rem;
  font-weight: 900;
  color: $color-neutral-darker;

  @media (min-width: $breakpoint-mobile-min) {
    font-size: 1.7rem;
    white-space: nowrap;
  }
}

.SaveBannerContentDescription {
  color: $color-neutral-dark;
  font-size: 0.7rem;
  margin-top: 0.5rem;

  @media (min-width: $breakpoint-mobile-min) {
    font-size: 1rem;
  }
}

.SaveBannerArrow {
  display: none;

  @media (min-width: $breakpoint-mobile-min) {
    display: block;
    position: absolute;
    right: 1.6rem;
    bottom: 0;
    width: 2.5rem;
    height: 5rem;
  }
}
