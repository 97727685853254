@import 'styles/animations';
@import 'styles/variables';
@import 'styles/mixins';

.productSearch {
  display: inline-block;
  position: relative;
  width: 100%;

  @media (min-width: $breakpoint-mobile-min) {
    max-width: 50%;
  }

  input {
    width: 100% !important;
  }
}

.productValue {
  display: inline-block;
  margin: 10px 1rem 0.5rem 0;
  font-weight: bold;
  font-size: pxrem(13);
  color: $color-neutral;

  span {
    display: block;
    font-size: pxrem(12);
    font-weight: normal;
  }
}

.productSearchResults {
  position: absolute;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 9;
  padding: 0.5rem 0;
  width: 100%;
  border-radius: 3px;
  margin-top: 5px;
  max-width: pxrem(355);
}

.noResults {
  font-size: pxrem(13);
  text-align: center;
  padding: 0.75rem 0;
  color: $color-neutral;
}

.product {
  display: flex;
  align-items: flex-start;
  padding: 0.5rem 1rem;

  &:hover {
    background: $color-neutral-lighter;
    cursor: pointer;

    img {
      border-color: rgba(0, 0, 0, 0.15);
    }
  }
}

.selectedProduct {
  display: inline-flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  width: 100%;
}

.productImg {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-right: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background: #fff;
  font-size: 0.5em;
}

.productInfo {
  padding-top: 0.25rem;
}

.productName {
  display: block;
  font-weight: bold;
  font-size: pxrem(12);
}

.productId {
  display: block;
  font-weight: bold;
  font-size: pxrem(12);
  color: $color-neutral;
  margin-top: 0.25rem;
}

.emptyState {
  color: $color-neutral;
  font-size: pxrem(13);
  line-height: 1.4;
  padding-top: 0.5rem;

  a {
    text-decoration: underline;
    color: inherit;

    &:hover {
      color: $color-primary;
    }
  }
}

.productSearchField {
  display: flex;
  position: relative;
}

.productLoader {
  position: absolute;
  right: 0;
  top: 0.25rem;
  width: 3rem;
}

.productFeedSelect {
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: 0;
  background-color: $color-neutral-lighter;
  font-weight: bold;
  font-size: 0.8em;
  line-height: 1.5rem;
  box-shadow: none !important;

  @media (max-width: $breakpoint-mobile-max) {
    max-width: 40%;
  }
}

.productSearchInput {
  .productFeedSelect + & {
    border-radius: 0 0.25rem 0.25rem 0;
    box-shadow: none !important;
  }
}
