@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/variables';

.wrap {
  margin: 2rem 0;
}

.list {
  margin: 0rem 0 2rem;
}

.pausedList {
  animation: subtle-drop 0.3s;
}

.groupTitleRow {
  padding: 0 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: $breakpoint-mobile-max) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.groupTitle {
  padding: 0;
  margin: 0;

  color: $color-neutral-dark;
  font-weight: 700;
  font-size: pxrem(21);

  background: transparent;
  border: none;
  display: inline-flex;
  align-items: center;

  outline: none;
  transition: all 0.2s;

  &:hover {
    color: $color-neutral-darker;
  }

  .typePaused & {
    font-size: 1em;
    opacity: 0.7;
    cursor: pointer;
  }
}

.groupTitleIcon {
  width: 0;
  height: 0;
  margin-right: 0.5rem;
  border-radius: 2px;

  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;

  border-top: 0.4em solid currentColor;
  transform: rotate(-90deg);
  transition: transform 0.2s ease-in-out;

  .open & {
    transform: rotate(0);
  }
}

.emptyState {
  color: $color-neutral;
  display: block;
  font-weight: 500;
  margin: 0;
  text-align: left;
}
