@use 'sass:math';

@import 'styles/variables';
@import 'styles/mixins';

.fieldset {
  margin: 0 0 4 * $spacing-unit;
}

.title {
  display: block;
  width: 100%;
  color: $color-neutral-darker;
  border-bottom: 2px solid $color-neutral-lighter;
  font-weight: 700;
  font-size: 1rem;
  margin: 0 0 2 * $spacing-unit;
  line-height: 3 * $spacing-unit;
  padding-bottom: $spacing-unit;

  @include breakpoint($breakpoint-mobile-max) {
    font-size: math.div(17, 16) * 1rem;
  }

  @include breakpoint($breakpoint-tablet-max) {
    font-size: math.div(18, 16) * 1rem;
  }
}

.content {
  position: relative;
}

.togglable button.title {
  appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: none;
  border-bottom: 2px solid $color-neutral-lighter;
  padding: 0;
  padding-bottom: $spacing-unit;
  cursor: pointer;
  outline: none;
  text-align: left;
  position: relative;

  &:hover {
    color: #000;

    .toggleIcon {
      color: darken($color-neutral, 10%);
    }
  }
}

.toggleIcon {
  background: transparent;
  border: 0;
  color: $color-neutral;
  height: 1rem;
  width: 1rem;

  margin-top: -0.5rem;
  padding: 0;
  position: absolute;
  right: 1px;
  text-align: center;
  top: 50%;
  transition: 0.2s ease;
  transform: rotate(-90deg);

  .open & {
    transform: rotate(0);
  }
}
