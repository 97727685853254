@use 'sass:math';

@import 'styles/variables';

.ReviewFormLabelRichTextEditor {
  .ql-toolbar {
    border: 0;
    padding: 0 !important;
    position: absolute;
    top: -28px;
    right: 0;
    height: 28px;

    &:before {
      content: 'Format text';
      position: absolute;
      top: -2px;
      height: 28px;
      line-height: 28px;
      right: 110%;
      font-size: 9px;
      width: 85px;
      text-align: right;
      pointer-events: none;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      opacity: 0.6;
    }

    button .ql-stroke {
      stroke: $color-neutral;
    }
  }

  .ql-container {
    font-family: inherit;
    border-color: $color-neutral-light;
    border-radius: $spacing-unit * 0.75;
    min-height: 3rem;
    transition: border 0.4s ease-in-out;
    font-size: math.div(15, 16) * 1rem;

    @media (max-width: $breakpoint-mobile-max) {
      font-size: 1rem; // Prevents the zoom effect on mobile
    }
  }

  .ql-focused .ql-container {
    border-color: $color-primary;
  }

  .ql-snow .ql-tooltip {
    border-radius: 56px;
    padding: 6px 18px;
    z-index: 2;
    box-shadow: none;
    background: rgba(255, 255, 255, 0.9);
    margin-top: -8px;
    font-size: math.div(14, 16) * 1rem;
  }

  .ql-snow .ql-tooltip[data-mode='link']::before {
    content: 'URL';
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 10px;
  }

  .ql-action {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
}
