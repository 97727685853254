@import 'styles/variables.scss';

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.logo {
  color: $color-primary;
  margin-top: 1rem;
  width: 9rem;

  svg {
    height: auto;
    width: 100%;
  }
}
