@import 'styles/mixins';
@import 'styles/variables';

.ExplanationBox {
  font-size: pxrem(14);
  color: $color-neutral-light;
  transition: color 0.3s ease-in-out;
}

.ExplanationBoxLabel {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  position: relative;
  top: 2px;
  user-select: none;

  color: $color-neutral;

  &:focus,
  &:hover {
    color: $color-neutral-darker;
  }

  svg {
    opacity: 0.75;
    transition: opacity 0.3s ease-in-out;

    &:focus,
    &:hover {
      opacity: 1;
    }
  }
}

.ExplanationBox__details {
  display: flex;
  flex-wrap: wrap;
  margin: 0;

  dt {
    color: $color-neutral-dark;
    font-weight: 700;
    flex: 0 0 5em;
    width: 5em;
  }

  dd {
    margin: 0;
    padding: 0 0 0 $spacing-unit * 2;
    flex: 1 0 calc(100% - 5em);
  }
}
