@import 'styles/mixins';
@import 'styles/variables';

@keyframes fadeBgIn {
  0% {
    background-color: rgba($color-neutral-darker, 0);
  }

  100% {
    background-color: rgba($color-neutral-darker, 0.25);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.ModalScroller {
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 300;
}

.ModalUnderlay {
  display: flex;
  place-items: center;
  background-color: rgba($color-neutral-darker, 0);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-name: fadeBgIn;
  transform: translate3d(0, 0, 0);
  width: 100vw;
}

.ModalWrapper {
  max-height: 100%;
  overflow: auto;
  padding: 0 $spacing-unit;
  width: 100vw;

  transform: scale(0);
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.135, 0.86, 0.32, 1);
  animation-delay: 0.2s;
  animation-duration: 0.3s;
  animation-name: scaleIn;
}

.ModalBox {
  background: #fff;
  border-radius: $spacing-unit;
  box-shadow: 0 0.15rem 0.5rem rgba($color-neutral-darker, 0.1), 0 1rem 2rem rgba($color-neutral-darker, 0.1);
  max-width: 100%;
  margin: $spacing-unit * 4 auto;
  min-height: 530px;
  padding: $spacing-unit * 6;
  width: 60rem;
  position: relative;
  overflow: hidden;

  @media (max-width: $breakpoint-mobile-max) {
    padding: 1.5 * $spacing-unit 3 * $spacing-unit 3 * $spacing-unit;
  }
}

.title {
  font-size: pxrem(32);
  font-weight: 800;
  margin: 0 0 $spacing-unit * 2;
}

.grid {
  position: relative;
  z-index: 1;
}

.gridA {
  max-width: 90%;

  @media (min-width: 568px) {
    max-width: 50%;
  }

  > * + * {
    margin-top: $spacing-unit * 2;
  }

  li + li {
    margin-top: $spacing-unit;
  }
}

.actions {
  margin: $spacing-unit * 5 0 0;
}

.list {
  margin: $spacing-unit * 3 0;
  list-style-type: none;
  padding-left: $spacing-unit * 2;
}

.options {
  padding-left: $spacing-unit * 2;
  margin: $spacing-unit * 3 0 0;
}

.wallV2BG {
  background: linear-gradient(to right, rgba(#fff, 0.98) 10%, rgba(#fff, 0.9) 30%, rgba(#fff, 0) 100%),
    url('../../../../images/screenshots/wall_v2.jpg') no-repeat;
  background-size: 170%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  @media (min-width: 568px) {
    background: linear-gradient(to right, rgba(#fff, 0.98) 30%, rgba(#fff, 0.9) 50%, rgba(#fff, 0) 70%),
    url('../../../../images/screenshots/wall_v2.jpg');
  }
}

.wallV1BG {
  background: linear-gradient(to right, rgba(#fff, 0.98) 30%, rgba(#fff, 0.85) 50%, rgba(#fff, 0) 70%),
    url('../../../../images/screenshots/wall_v1.jpg');
  background-size: auto 130%;
  background-repeat: no-repeat;
  background-position: 100% 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
