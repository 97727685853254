@use 'sass:math';

@import 'styles/variables';

.header {
  background-color: $color-primary;
  background-position: 0 60%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  color: #fff;
  display: flex;
  align-items: center;
  height: 6 * $spacing-unit;
  padding: $spacing-unit;
  transition: height 0.3s ease-in-out;

  @media (max-width: $breakpoint-mobile-max) {
    height: 5 * $spacing-unit;
  }
}

.headerSpacer {
  flex-grow: 1;
  flex-shrink: 1;
}

.headerPreviewButton {
  margin-right: $spacing-unit;

  @media (max-width: $breakpoint-mobile-max) {
    display: none;
  }
}

.headerNav {
  height: 4 * $spacing-unit;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 4 * $spacing-unit;
  padding-right: 4 * $spacing-unit;
  text-decoration: none;

  @media (max-width: 519px) {
    display: none;
  }
}

.headerNavItem {
  color: #fff;
  display: flex;
  align-items: center;
  font-size: math.div(14, 16) * 1rem;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;

  &:focus,
  &:hover {
    color: #fff;

    .headerNavItemLabel {
      text-decoration: underline;
    }
  }

  & + & {
    margin-left: 3 * $spacing-unit;
  }
}

.headerNavItemIcon {
  height: 1.15em;
  width: 1.15em;
}

.presentIcon {
  position: relative;
  top: -1px;
}

.feedbackIcon {
  position: relative;
  top: 1px;
  height: 1.25em;
  width: 1.25em;
}

.headerNavItemLabel {
  margin-left: $spacing-unit;

  @media (max-width: $breakpoint-tablet-max) {
    display: none;
  }
}
