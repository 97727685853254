@import 'styles/variables.scss';

@keyframes fl-loading-animation {
  0% {
    background: $color-neutral;
    transform: scale(0.5);
  }
  50% {
    background: white;
    transform: scale(1);
  }
  100% {
    background: $color-neutral;
    transform: scale(0.5);
  }
}

.loadingIndicator {
  display: inline-block;
  position: relative;

  & div {
    animation: fl-loading-animation 2s infinite;
    animation-fill-mode: both;
    background: $color-neutral;
    border-radius: 100%;
    display: inline-block;
    height: 0.625rem;
    margin: 0;
    width: 0.625rem;
  }

  & div:nth-child(1n) {
    animation-delay: 0s;
  }

  & div:nth-child(2n) {
    animation-delay: 0.2s;
  }

  & div:nth-child(3n) {
    animation-delay: 0.4s;
  }

  & div + div {
    margin-left: 0.2rem;
  }
}
