@import 'styles/variables';

.share {
  display: flex;
}

.shareLink {
  margin: 0 $spacing-unit * 3 0 0;
  color: $color-neutral;
  display: inline-block;

  .disabled & {
    touch-action: none;
    pointer-events: none;
  }

  &:hover {
    color: $color-neutral;
  }
}

.shareIcon {
  width: 1.25rem;
  height: 1.25rem;
}
