@import 'styles/mixins';
@import 'styles/variables';

.SeoPreview {
  border-radius: $spacing-unit * 0.75;
  background-color: lighten($color-neutral-lighter, 3%);
  border: 1px solid $color-neutral-lighter;
  padding: $spacing-unit * 2;
}

.SeoPreviewWrapper {
  max-width: 70ch;

  > * + * {
    margin-top: $spacing-unit;
  }
}

.SeoPreviewTitle {
  color: $color-primary-dark;
  font-size: pxrem(21);
  font-weight: 600;
}

.SeoPreviewLocation {
  color: $color-neutral;
  font-size: pxrem(14);
}

.SeoPreviewSnippet {
  color: $color-neutral-dark;
  font-size: pxrem(15);
  line-height: 1.3;
}
