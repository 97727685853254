@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/variables';

.item {
  width: 100%;
  border: 1px solid $color-neutral-light;
  border-width: 1px 1px 0;
  position: relative;
  z-index: 0;

  &:last-of-type {
    border-width: 1px;
  }

  &:hover {
    background: #fcfdff;
    border-color: $color-primary;
  }

  &:hover + & {
    border-top-color: $color-primary;
  }

  &.itemPaused {
    background: #fafafa;

    &:hover {
      background: $color-neutral-lighter;
    }
  }

  &.itemDeleted {
    background: #fdf6f7;

    &:hover {
      background: #fdf6f7;
      border-color: #aa7d81;
    }

    &:hover + & {
      border-top-color: #aa7d81;
    }
  }

  &.itemWithError {
    border-left-color: transparent;
    border-left-width: 0;
    margin-left: 1px;

    &:hover {
      background: #fdf6f7;
      border-color: $color-error-dark;
    }

    &:hover + & {
      border-top-color: $color-error-dark;
    }

    &:global(.warning):hover {
      border-color: $color-warning;
      background: rgba($color-warning, 0.05);

      &:hover + & {
        border-top-color: $color-warning;
      }
    }
  }

  &:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    &.itemWithError {
      border-top-left-radius: 0;
    }
  }

  &:last-of-type {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    &.itemWithError {
      border-bottom-left-radius: 0;
    }
  }
}

.mainRow {
  display: flex;
  cursor: pointer;
  position: relative;

  @media (max-width: $breakpoint-mobile-max) {
    flex-direction: column;
  }
}

.col {
  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: pxrem(14);
  padding: 2 * $spacing-unit;

  flex: 1 0 0;

  &:last-child {
    text-align: right;
    justify-content: flex-end;

    @media (max-width: $breakpoint-mobile-max) {
      justify-content: flex-start;
      text-align: left;
      padding: 0 0.3rem 0.25rem 2.25rem;
    }
  }

  &.nameCol {
    flex: 66% 0 0;

    @media (max-width: $breakpoint-mobile-max) {
      padding-bottom: 0.25rem;
    }
  }

  .itemDeleted & {
    padding: 0.75rem 2 * $spacing-unit;

    @media (max-width: $breakpoint-mobile-max) {
      &:last-child {
        padding: 0;
      }
    }

    @media (max-width: $breakpoint-mobile-max) {
      padding: 0.75rem;
    }
  }

  @media (max-width: $breakpoint-mobile-max) {
    padding: 0.75rem;
  }
}

.errorIndicator {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background: $color-error-dark;

  .item:global(.warning) & {
    background: $color-warning;
  }
}

.content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  transition: opacity 0.25s;

  .itemPaused &,
  .itemDeleted & {
    opacity: 0.7;
  }

  .item:hover &,
  .itemWithDetails & {
    opacity: 1;
  }

  @media (max-width: $breakpoint-mobile-max) {
    padding-right: 1rem;
  }
}

.feedBadge {
  animation: subtle-drop 0.4s;
  position: absolute;
  left: -10px;
  top: -10px;
  font-size: pxrem(8);
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  background: $color-primary;
  border-radius: 30px;
  padding: 2px 5px;
  letter-spacing: 0.05em;
  user-select: none;
  pointer-events: none;
}

.label {
  color: $color-neutral-darker;
  font-weight: 600;
  margin-bottom: 0.25rem;
  display: block;
}

.sectionInfo {
  color: $color-neutral;
  display: block;
  font-size: pxrem(13);
}

.dangerButton,
.linkButton {
  padding: 1.5rem 0;
  margin: -1.5rem 0;
  display: inline-flex;
  justify-content: flex-start;
  min-width: 6rem;

  align-items: center;
  border: none;
  background: transparent;
  font-weight: 600;
  user-select: none;
  transition: color 0.1s;

  &:last-of-type {
    margin-right: 1rem;
  }

  span {
    border: 1px solid transparent;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    padding: 0.75em 1em;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    outline: none;

    span {
      border-color: currentColor;
    }
  }

  @media (max-width: $breakpoint-mobile-max) {
    padding: 0;
    margin: 0;
  }

  .icon {
    margin-right: 0.4rem;
  }
}

.linkButton {
  color: $color-primary;

  &:hover,
  &:focus {
    color: $color-primary-dark;
  }

  &.linkButtonSuccess {
    color: $color-secondary;

    &:hover,
    &:focus {
      color: $color-secondary-dark;
    }
  }
}

.dangerButton {
  color: $color-error-dark;

  &:hover,
  &:focus {
    color: $color-error-dark;
  }
}

.toggleIcon {
  height: 0.85rem;
  width: 0.85rem;
  color: $color-neutral-light;
  transition: transform 0.2s ease-in-out;

  .itemWithDetails & {
    transform: rotate(90deg);
  }

  .mainRow:hover & {
    color: $color-primary;
  }

  .itemWithError .mainRow:hover & {
    color: $color-error-dark;
  }

  .itemWithError:global(.warning) .mainRow:hover & {
    color: $color-warning;
  }

  .itemDeleted .mainRow:hover & {
    color: #aa7d81;
  }

  @media (max-width: $breakpoint-mobile-max) {
    position: absolute;
    right: 0.75rem;
    top: 1.4rem;
  }
}

.itemWithError:global(.warning) [data-notice-type="danger"] {
  color: $color-warning-darker;
  background: rgba($color-warning, 0.075);
}

.summaryRow {
  width: 100%;
  font-weight: 500;
  font-size: pxrem(14);

  transition: height 0.2s, min-height 0.2s, max-height 0.2s;
  max-height: 0;
  height: 0;
  min-height: 0;

  &.open {
    max-height: 1000px;
    height: auto;
    min-height: 20px;
    transition: height 0.5s, min-height 0.5s, max-height 0.5s;

    padding: 0 1.5rem 1.5rem 4rem;

    @media (max-width: $breakpoint-mobile-max) {
      padding: 0 1rem 0.5rem 3.25rem;
    }
  }
}

.errorRow {
  position: relative;

  font-weight: 500;
  font-size: pxrem(14);
  padding: 0 1.5rem 1.5rem 4rem;
  position: relative;

  @media (max-width: $breakpoint-mobile-max) {
    padding: 0 1rem 1rem 3.25rem;
  }
}

.itemDeleted .deletedAtCol {
  @media (max-width: $breakpoint-mobile-max) {
    padding: 0 0 0.75rem !important;
  }
}

.deletedAt {
  color: $color-neutral;
  display: block;
  font-size: pxrem(13);
  width: 200px;

  @media (max-width: $breakpoint-mobile-max) {
    width: auto;
    padding-left: 3.25rem;
  }
}
