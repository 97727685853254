@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/variables';

.referralPage {
  position: relative;
  max-width: 550px;
  margin: 0 auto;

  @media (min-width: $breakpoint-tablet-min) {
    max-width: 70rem;
  }
}

.columns {
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-tablet-min) {
    flex-direction: row-reverse;
  }
}

.leftCol {
  padding: 1rem 0;

  @media (min-width: $breakpoint-tablet-min) {
    flex: 1 0 0;
    padding: 3rem 2rem 1rem 0;
  }
}

.rightCol {
  flex: 1 0 0;
}

.referralImage {
  height: 100%;
  max-width: 100%;
  width: 500px;
}

.intro {
  position: relative;
}

.mainTitle {
  text-align: left;
  color: $color-neutral-darker;
  font-weight: 800;
  margin: 0 0 2rem;
  font-size: 1.75rem;
  line-height: 1.1;

  @media (min-width: $breakpoint-tablet-min) {
    font-size: 2.5rem;
  }
}

.referralLink {
  text-align: left;
  margin: 2rem 0;

  & > p {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: $spacing-unit * 2;
  }
}

.errorMessage {
  animation: subtle-drop 0.3s;
  display: block;
  margin: 2rem 0;
  color: $color-neutral;
  font-weight: 600;
  font-size: 1.15rem;
}

.helpLink {
  display: inline-block;
  font-size: 0.875rem;
  margin-top: $spacing-unit * 3;
}
