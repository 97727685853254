@import 'styles/animations';
@import 'styles/variables';

.StripeSepaField {
  :global {
    .StripeElement {
      border: 1px solid $color-neutral-lighter;
      border-radius: 0.25rem;
      padding: 2 * $spacing-unit;
      font-family: 'proxima-nova', 'Helvetica Neue', sans-serif;
      max-height: 3.25rem;
      overflow: hidden;

      &:hover {
        box-shadow: 0 0 0 4px $color-neutral-lighter;
      }
    }

    .StripeElement--focus {
      border-color: $color-primary;
      outline: none;
    }

    .StripeElement--invalid {
      border-color: $color-error;
    }
  }
}
