@import 'styles/animations';
@import 'styles/variables';
@import 'styles/mixins';

$border-color: $color-neutral-light;

.wrap {
  position: relative;

  display: inline-block;
  padding-right: 30px;
  width: 150px;

  // Override component inline styles
  :global {
    .sketch-picker {
      box-shadow: $border-color 0 0 0 1px, rgba(0, 0, 0, 0.15) 0 8px 16px !important;

      input {
        border-radius: 2px !important;
        box-shadow: $border-color 0 0 0 1px inset !important;
      }
    }
  }
}

.input {
  width: 100% !important;
}

.swatch {
  background: transparent;
  border-left: 1px solid #b0bec5;
  border-radius: 0 1px 1px 0;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  position: absolute;
  right: 31px;
  top: 1px;
  width: 31px;
}

.swatchColor {
  background-repeat: repeat;
  border-radius: 2px;
  height: 20px;
  width: 100%;
}

.popover {
  position: absolute;
  top: 100%;
  z-index: 2;
}
