@import 'styles/variables';
@import 'styles/animations';

@keyframes scroll-wall {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, -100px, 0);
  }
}

@keyframes scroll-carousel {
  0% {
    transform: translate3d(0, -50%, 0);
  }

  100% {
    transform: translate3d(-400px, -50%, 0);
  }
}

.mockupWrap {
  height: 100%;
  width: 100%;
}

.mockup {
  background: #fff;
  padding: 2px;
  display: flex;

  height: 100%;
  min-height: 300px;
  width: 100%;

  .animated & {
    animation: none;
  }

  &.screen {
    background: $color-primary;
    background-size: cover;
    background-position: center;
  }
}

.wall {
  display: flex;
  flex-direction: row;
  height: auto;

  .wallColumn {
    display: flex;
    flex-direction: column;
    margin: 1px 0;
    width: 25%;
  }

  .post {
    width: calc(100% - 4px);
    margin: 2px;
  }

  .postText {
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      height: 3em;
      left: 0;
      top: 8.2em;
      right: 0;
      background: linear-gradient(0deg, #fff, rgba(#fff, 0));
      z-index: 1;
    }
  }

  @media (min-width: 65rem) {
    height: 350px;
  }

  @media (min-width: 95rem) {
    height: 400px;
  }
}

.wall .postWallv2 {
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.6em 0.6em;
    min-height: 3.5em;
  }

  .author {
    display: flex;
    align-items: center;
    flex: 1 0 0;
    line-height: 1.7em;

    .username {
      font-size: 1.2em;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .channel {
    flex: 1.7em 0 0;
    margin-left: 1em;
  }

  .placeholder {
    .author {
      opacity: 0.25;
    }
  }

  .postFooter {
    padding: 0.7em 0.6em;
    line-height: 1;
  }
}

.grid,
.carousel {
  display: flex;
  flex-wrap: wrap;

  .post {
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.6em 0.6em;
    min-height: 3.5em;
  }

  .author {
    display: flex;
    align-items: center;
    flex: 1 0 0;
    line-height: 1.7em;

    .username {
      line-height: 1;
      font-size: 1.2em;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .channel {
    flex: 1.7em 0 0;
    margin-left: 1em;
  }

  .placeholder {
    .author {
      opacity: 0.25;
    }
  }

  .postMedia {
    height: 0;
    display: block;
    padding-bottom: 100%;
    position: relative;
  }

  .postImage {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    background: $color-neutral-lighter;
  }

  .postTextWrap {
    background: $color-neutral-lighter;
    background: radial-gradient(farthest-corner at 50% 20%, #fff, $color-neutral-lighter);
    color: $color-neutral-darker;
    display: block;
    height: 0;
    padding-bottom: 100%;
    position: relative;
  }

  .postTextWrap--twitter,
  .postTextWrap--facebook,
  .postTextWrap--linkedin {
    background: radial-gradient(
      farthest-corner at 50% 20%,
      lighten($color-primary, 15%),
      lighten($color-primary, 5%)
    );
    color: #fff;

    .postTextInner p {
      color: #fff;
      text-shadow: 0 1px 1px rgba(#000, 0.1);
    }
  }

  .postText {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 1.1em;
    position: absolute;

    align-items: center;
    display: flex;
    height: 100%;
    padding: 1.5em;
    width: 100%;
    -webkit-mask: linear-gradient(to bottom, #000 calc(100% - 6em), transparent);
    mask: linear-gradient(to bottom, #000 calc(100% - 6em), transparent);
    max-height: none;
  }

  .postTextInner {
    align-items: center;
    display: flex;
    color: inherit;
    flex-direction: column;
    line-height: 1.4;
    margin: auto 0;
    max-height: 100%;
    text-align: center;
    width: 100%;

    p {
      color: $color-neutral-darker;
      width: 100%;
      display: block;
      margin: 0;
      padding: 0;
    }
  }

  .postFooter {
    padding: 0.7em 0.6em;
    line-height: 1;
  }
}

.grid {
  flex-direction: row;
  height: auto;
  align-items: flex-start;
  align-content: flex-start;
}

// Nav arrows
.mockupWrap--carousel {
  position: relative;

  &:after,
  &::before {
    display: inline-block;
    content: '';
    position: absolute;
    z-index: 2;
    left: 5px;
    background: rgba(#fff, 0.9) url('../../../../images/icons/icon-angle-left.svg') center no-repeat;
    background-size: 16px auto;

    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-size: 2.5rem;
    top: 50%;
    margin: -5px 0 0 0;
  }

  &:after {
    left: auto;
    right: 5px;
    background-image: url('../../../../images/icons/icon-angle-right.svg');
  }
}

.carousel {
  flex-direction: row;
  flex-wrap: nowrap;

  .animated & {
    animation: scroll-carousel 15s linear infinite;
    animation-direction: alternate;
    animation-play-state: paused;
  }

  &.hover {
    animation-play-state: running;
  }

  height: auto;
  min-height: 0;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  position: relative;

  .post {
    min-width: 34%;
    flex: 34% 0 0;

    @media (min-width: 80rem) {
      min-width: 29%;
      flex: 29% 0 0;
    }
  }

  .postMedia {
    height: 0;
    display: block;
    padding-bottom: 100%;
    position: relative;
  }

  .postImage {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.slideshow {
  position: relative;
  animation: none;
  min-height: 0;
  height: 100%;
  background: linear-gradient(rgba(#215fd2, 0.6), rgba(#215fd2, 0.6)), #2889e8 url('../../../../images/bg.svg');
  background-size: cover;
  background-position: center;

  .animated & {
    animation: none;
  }

  .post {
    background: transparent;
    border: none;
    font-size: 5px;
    position: absolute;
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3em;

    .animated & {
      opacity: 1;
      transform: none;
      animation: fade-in 0.5s;
      transition: opacity 0.25s, transform 0.25s;
    }

    &:not(:first-child):not(:nth-child(2)) {
      display: none;
    }

    &:nth-child(1) {
      z-index: 1;
    }

    &:nth-child(2) {
      z-index: 2;
      transform: translate3d(0, 1rem, 0);
      opacity: 0;
    }
  }

  &.hover .post:first-child {
    opacity: 0;
  }

  &.hover .post:nth-child(2) {
    opacity: 1;
    transition: opacity 0.5s, transform 0.5s;
    transform: translate3d(0, 0, 0);
  }

  .postText {
    font-size: 1.2em;
    white-space: pre-wrap;
  }

  .postMedia {
    min-height: 40px;
    margin: 0;
    border-radius: 3px;
    border: 1px solid #fff;

    width: 40%;
    flex: 40% 0 0;
    line-height: 0;
    max-height: 90%;
    overflow: hidden;

    img {
      border-radius: 0px;
      width: 100%;
      height: 100%;
      object-position: 50% 50%;
      object-fit: cover;
    }
  }

  .postContent {
    display: flex;
    flex: 80% 0 0;
    width: 80%;
    flex-direction: column;
    padding: 1em;

    background: #fff;
    border-radius: 0.5em;
  }

  .postMedia + .postContent {
    flex: 60% 0 0;
    width: 60%;
    margin: 0 0 0 1rem;
  }

  .profilePic {
    width: 2.5em;
    min-width: 2.5em;
    height: 2.5em;
  }

  .postFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &:before {
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 80%);
      content: '';
      height: 1em;
      left: 0;
      position: absolute;
      right: 0;
      top: -1em;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 80%);
    }
  }

  .profile {
    display: flex;
    align-items: center;
  }

  .channel {
    height: 2.5em;
    width: 2.5em;
    color: rgba(0, 0, 0, 0.3);
  }
}

.mockupWrap--embed_slideshow {
  position: relative;
  &:after,
  &::before {
    display: inline-block;
    content: '';
    position: absolute;
    z-index: 2;
    left: 3%;
    background: url('../../../../images/icons/icon-angle-left.svg') center no-repeat;
    background-size: 16px auto;
    opacity: 0.6;

    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-size: 2.5rem;
    top: 55%;
    margin: 0;
  }

  &:after {
    left: auto;
    right: 3%;
    background-image: url('../../../../images/icons/icon-angle-right.svg');
  }

  .websiteMockup {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;

    .websiteMockupHeader {
      padding: 0 12%;
      background: rgba(255, 255, 255, 0.55);
      height: 1.5em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.8em;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);

      .websiteMockupHeaderLinks {
        display: flex;
      }

      span {
        display: block;
        margin: 0 0 0 0.4em;
        width: 1.5em;
        height: 0.25em;
        background-color: $color-neutral-lighter;
        opacity: 0.6;
      }
    }

    .websiteMockupHeaderLogo {
      background: $color-neutral-lighter;
      width: 0.75em;
      height: 0.75em;
      border-radius: 0.15em;

      &:after {
        content: 'Website';
        font-size: 0.5em;
        margin: 0 0 0 18px;
        position: relative;
        top: -5px;
        font-weight: bold;
        opacity: 0.3;
      }
    }

    .websiteMockupTitles {
      padding-left: 12%;

      span {
        display: block;
        margin: 0 0 4px 0;
        width: 6em;
        height: 5px;
        background-color: $color-neutral-lighter;
        opacity: 0.8;

        & + span {
          width: 8em;
        }
      }
    }
  }
}

.embed_slideshow {
  position: relative;
  animation: none;
  min-height: 0;
  height: 100%;
  background: blue;
  background: rgba($color-neutral-lighter, 0.5);

  .animated & {
    animation: none;
  }

  .post {
    background: transparent;
    border: none;
    font-size: 5px;
    position: absolute;
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 13em 12% 5em;
    background: transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: auto;
    padding: 0;

    max-height: 60%;

    .animated & {
      opacity: 1;
      transform: none;
      animation: fade-in 0.5s;
      transition: opacity 0.25s, transform 0.25s;
    }

    &:not(:first-child):not(:nth-child(2)) {
      display: none;
    }

    &:nth-child(1) {
      z-index: 1;
    }

    &:nth-child(2) {
      z-index: 2;
      transform: translate3d(0, 1rem, 0);
      opacity: 0;
    }
  }

  &.hover .post:first-child {
    opacity: 0;
  }

  &.hover .post:nth-child(2) {
    opacity: 1;
    transition: opacity 0.5s, transform 0.5s;
    transform: translate3d(0, 0, 0);
  }

  .postText {
    font-size: 1.2em;
    white-space: pre-wrap;
  }

  .postMedia {
    min-height: 40px;
    margin: 0;
    border-radius: 0;

    width: 50%;
    flex: 50% 0 0;
    line-height: 0;
    max-height: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-position: 50% 50%;
      object-fit: cover;
    }
  }

  .postContent {
    height: 100%;
    display: flex;
    flex: 80% 0 0;
    width: 80%;
    flex-direction: column;
    padding: 1em;
    position: relative;
    background: #fff;
  }

  .postMedia + .postContent {
    flex: 50% 0 0;
    width: 50%;
    margin: 0;
  }

  .postText {
    max-height: 100%;
    padding-top: 3.4em;

    &:after {
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 80%);
      content: '';
      height: 3em;
      left: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 80%);
    }
  }

  .profilePic {
    width: 2.5em;
    min-width: 2.5em;
    height: 2.5em;
  }

  .postFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0em;
    left: 1em;
    right: 1em;
  }

  .profile {
    display: flex;
    align-items: center;
  }

  .channel {
    height: 2.5em;
    width: 2.5em;
    color: rgba(0, 0, 0, 0.3);
  }
}

.post {
  border: 1px solid $color-neutral-lighter;
  background: #fff;
  margin: 2px;
  width: calc(33.3% - 4px);
  font-size: 4px;

  @media (max-width: $breakpoint-mobile-max) {
    width: calc(25% - 4px);
  }

  @media (min-width: 80rem) {
    width: calc(25% - 4px);
  }

  .animated & {
    opacity: 0;
    transform: translate3d(0, -5px, 0);
    animation: subtle-drop 0.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }
}

.postMedia {
  width: calc(100% + 2px);
  margin: -1px -1px 0;
  background: $color-neutral-lighter;

  .placeholder & {
    background: $color-neutral-lighter;

    img {
      opacity: 0;
    }
  }
}

.postImage {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  background: $color-neutral-lighter;
}

.postText {
  padding: 0.8em 0.6em 0.6em;
  color: #333;
  white-space: pre-wrap;

  max-height: 11.2em;
  overflow: hidden;
  text-overflow: ellipsis;

  .placeholder & {
    opacity: 0.25;
  }
}

.postFooter {
  padding: 0.8em 0.6em;
  display: flex;
  align-items: center;

  .placeholder & {
    opacity: 0.25;
  }
}

.profilePic {
  background: #ddd;
  min-width: 1.7em;
  width: 1.7em;
  height: 1.7em;
  margin: 0;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.75em;

  background-size: cover;
  background-position: 50% 50%;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.channel {
  width: 1.7em;
  height: 1.7em;
  color: $color-neutral;
  position: relative;
  top: 0;

  svg {
    width: 100%;
    height: 100%;
  }
}

.author {
  overflow: hidden;
  text-overflow: ellipsis;
}

.username {
  font-weight: bold;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  opacity: 0.5;
}

.timestamp {
  opacity: 0.8;
}

.reviewStar {
  margin: -1px 1px 0 0;
  height: 0.9em;
  width: 0.9em;
}

.reviewStarBig {
  margin: 0 2px 2px 0;
  height: 1.25em;
  width: 1.25em;
}
