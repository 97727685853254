@use 'sass:math';

@import 'styles/variables';

.errorPage {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  overflow: hidden;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 1680px;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.errorContent {
  display: flex;
  flex-direction: column;
  height: 60%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.box {
  background: #fff;
  padding: 4em;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05), 0 10px 40px rgba(0, 0, 0, 0.05);
  width: 680px;
  margin: 1em;
  text-align: center;
}

.code {
  font-size: 6em;
  font-weight: 700;
  opacity: 0.1;
}

.description {
  font-size: math.div(21, 16) * 1rem;
  opacity: 0.7;
}
