@use 'sass:math';

@import 'styles/variables';
@import 'styles/mixins';

.input[type='text'],
.input[type='email'],
.input[type='number'],
.input[type='password'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background: #fff;
  border: 1px solid $color-neutral-light;
  border-radius: 0.25rem;
  box-shadow: none;
  color: $color-neutral-dark;
  font-family: inherit;
  height: 4 * $spacing-unit;
  line-height: 4 * $spacing-unit;
  padding: 0 $spacing-unit;
  width: 100%;
  transition: all 0.4s ease-in-out;
  font-size: 1rem; // Prevents the zoom effect on mobile

  @include breakpoint($breakpoint-mobile-max) {
    font-size: math.div(14, 16) * 1rem;
    width: 50%;
  }

  @include breakpoint($breakpoint-embed-inline-columns) {
    display: inline-block;
  }

  &:focus {
    border-color: $color-primary;
    outline: none;
  }

  &:disabled {
    background-color: $color-neutral-lighter;
    border-color: $color-neutral-lighter;
    color: $color-neutral;
    cursor: not-allowed;
  }

  &::placeholder {
    color: $color-neutral;
  }

  &[aria-invalid='true'] {
    border-color: $color-error;
  }
}
