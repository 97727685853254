@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/variables';

.createFeedRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: $spacing-unit * 3;

  @media (min-width: 900px) {
    text-align: right;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .createFeedRowButton {
    margin: 0 0 $spacing-unit * 2 0;

    @media (min-width: 900px) {
      margin: 0 $spacing-unit * 4 0 0;
    }
  }
}

.currentFeedCount {
  color: $color-neutral;
  font-size: pxrem(14);
  font-weight: 500;
  line-height: 1.4;

  strong {
    font-weight: 700;
  }
}

.errorTitle {
  font-size: pxrem(16);
  font-weight: bold;
}

.notice {
  position: relative;
}

.noticeLink {
  display: inline-flex;
  align-items: center;
}

.upgradeButton {
  margin: 0.5rem 0 0;
  display: block;
  width: 100%;

  @media (min-width: $breakpoint-mobile-min) {
    margin: 0;
    position: absolute;
    right: 0.7rem;
    top: 0.7rem;
    width: auto;
  }
}
