@import 'styles/variables';
@import 'styles/mixins';

.sectionSelect {
  display: block;

  @include breakpoint($breakpoint-embed-inline-columns) {
    display: inline-block;
    margin-top: 7px;
    width: 66.6%;
    padding-left: 3.3%;
  }
}

.checkboxItems {
  columns: 2;
}

.checkboxItem {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.section {
  height: 1.75em;
  line-height: 1.75em;
  min-height: 0;

  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  margin: 1px 0 0;
  overflow: hidden;
  padding-right: 0.5rem;
  white-space: nowrap;
  width: auto;

  &:hover {
    position: relative;
    text-shadow: 0 1px 1px #fff;
    z-index: 2;
  }
}

.sectionDefault {
  border-bottom: 1px dashed $color-neutral-lighter;
  display: block;
  margin: 0 0 0.6rem;
  padding: 0 0 0.6rem;
  width: 100%;
  height: auto;
  line-height: 1;
}

.checkbox {
  margin-right: $spacing-unit;
}

.checkboxLabel {
  display: inline-block;
  user-select: none;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
