@use 'sass:math';

@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/variables';

.tagInput {
  margin: 0;

  @include breakpoint($breakpoint-mobile-min) {
    width: 50%;
  }

  @include breakpoint($breakpoint-embed-inline-columns) {
    display: inline-block;
  }
}

.tagInputFieldWrapper {
  margin: 0;
  text-align: left;
  position: relative;
  display: block;
  flex: 1 0 0;
}

.tagInputField[type='text'] {
  appearance: none;
  background: transparent;
  border-radius: 0;
  border: none;
  box-shadow: none;
  display: block;
  margin: 0;

  color: $color-neutral-dark;
  font-family: inherit;
  font-size: math.div(14, 16) * 1rem;
  height: 4 * $spacing-unit;
  line-height: 4 * $spacing-unit;
  padding: 0 $spacing-unit;

  min-width: 120px;
  padding-right: 30px !important;
  width: 100% !important;
}

.addButton {
  display: flex;
  height: 2rem;
  justify-content: center;
  position: absolute;
  right: math.div(-86, 16) * 1rem;
  top: 0;
  width: math.div(70, 16) * 1rem;
  z-index: 2;

  @media (max-width: $breakpoint-mobile-max) {
    width: math.div(60, 16) * 1rem;
    right: 0;
    height: 25px;
    margin: 4px 0.4rem 0 0;
  }

  &:hover {
    box-shadow: 0 0 0 4px $color-neutral-lighter;
  }

  &[disabled] {
    background-image: linear-gradient(to bottom, #ccc, #bbb);
    border-color: #bbb;
    cursor: not-allowed;
  }
}
