@use 'sass:math';

@import 'styles/animations';
@import 'styles/variables';

// # Summary
.Summary {
  background: $color-neutral-lighter;
  border-radius: 1rem;
  width: 100%;
  padding: 2 * $spacing-unit;
  margin: 3 * $spacing-unit 0;
  animation: subtle-drop 0.5s;
  position: relative;

  // desktop
  @media (min-width: $breakpoint-tablet-min) {
    padding: 4 * $spacing-unit;

    flex: math.div(290, 16) * 1rem 0 0;
    width: math.div(290, 16) * 1rem;
    min-width: math.div(290, 16) * 1rem;

    position: sticky;
    top: 8rem;
  }
}

.SummaryLoader {
  animation: fade-in 0.1s;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(#fff, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.SummaryTitle {
  padding: 0 0 3 * $spacing-unit;
  margin: 0;
  color: $color-neutral;
  font-weight: bold;
  font-size: 1rem;
}

.SummaryPlanName {
  font-weight: 800;
  font-size: 1.75rem;
  margin-bottom: 0.5 * $spacing-unit;
}

.SummaryPlanPrice {
  font-size: 1rem;
  color: $color-neutral;
}

.SummaryFeatures {
  padding: 3 * $spacing-unit 0;
}

.SummaryFeature {
  display: flex;
  align-self: center;
  margin: 0 0 0.6rem;
  font-size: math.div(14, 16) * 1rem;
  font-weight: 500;
}

.SummaryFeatureIcon {
  color: $color-secondary;
  margin-right: 2 * $spacing-unit;
  font-size: 1rem;
  position: relative;
  top: 2px;
}

.SummaryOverview {
  margin: 0 0 2 * $spacing-unit;
  padding: 2 * $spacing-unit 0;

  border-width: 1px 0;
  border-style: solid;
  border-color: $color-neutral-lighter;
  font-size: math.div(13, 16) * 1rem;
}

.SummaryOverviewRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: $color-neutral-dark;

  &:not(:last-child) {
    margin: 0 0 $spacing-unit;
  }
}

.SummaryTotalRow {
  font-weight: 600;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
