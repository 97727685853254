@import 'styles/animations';
@import 'styles/variables';
@import 'styles/mixins';

$list-breakpoint: 600px;

.addonListTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing-unit * 2;
  font-weight: 700;
  font-size: pxrem(22);
}

.addonList {
  width: 100%;
}

.addonListItem {
  animation: fade-in 0.2s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $spacing-unit * 2 0;
  font-size: pxrem(15);
  border-top: 1px solid $color-neutral-lighter;

  &:last-of-type {
    border-bottom: 1px solid $color-neutral-lighter;
  }

  @media (min-width: $list-breakpoint) {
    flex-direction: row;
    min-height: $spacing-unit * 5;
  }
}

.addonDetails {
  padding-right: $spacing-unit * 3;
  margin: 0;
  flex-grow: 1;
  width: auto;
  order: 1;
}

.addonDetailsInner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;

  @media (min-width: $list-breakpoint) {
    flex-direction: row;
  }
}

.nameCol {
  padding-right: $spacing-unit * 3;
  order: 2;

  @media (min-width: $list-breakpoint) {
    order: 1;
  }
}

.statusCol {
  order: 1;
  padding-top: 0;
  margin-bottom: $spacing-unit * 2;

  @media (min-width: $list-breakpoint) {
    padding-top: $spacing-unit;
    margin-bottom: 0;
    order: 2;
  }
}

.addonName {
  font-size: pxrem(18);
  font-weight: bold;
}

.addonDescription {
  color: $color-neutral-dark;
  padding: 0;
  font-size: pxrem(14);
  margin-top: $spacing-unit;
}

.priceCol {
  flex-shrink: 0;
  margin: 0;
  padding-right: $spacing-unit * 3;
  order: 2;
  font-weight: 600;

  @media (min-width: $list-breakpoint) {
    text-align: right;
  }
}

.addonPrice {
  @media (min-width: $list-breakpoint) {
    white-space: nowrap;
    font-weight: bold;
    font-size: pxrem(18);
    display: block;
  }
}

.addonPricePeriod {
  margin: 0 0 0 0.25rem;
  display: inline-block;

  @media (min-width: $list-breakpoint) {
    margin: 0.25rem 0 0;
    color: $color-neutral;
    font-size: pxrem(14);
    font-weight: 400;
    display: block;
  }
}

.actionCol {
  order: 4;
  padding-right: 0;
  margin: $spacing-unit * 2 0 0 0;

  @media (min-width: $list-breakpoint) {
    margin: 0.25rem 0 0 $spacing-unit * 2;
    text-align: right;
  }

  button {
    width: 100%;
    white-space: nowrap;
  }
}

.addonValidUntil {
  display: block;
  font-size: pxrem(13);
  line-height: 1.3;
  color: $color-neutral;
  font-weight: 400;
  margin-top: $spacing-unit;
}

.loader {
  margin: 3% auto;
}
