@use 'sass:math';

@import 'styles/mixins';
@import 'styles/variables';

.seoWrapper {
  max-width: 100%;
  margin: 0 auto;
  width: 34rem;

  @media (min-width: 768px) {
    width: 100%;
  }
}

.MetaDetailsFormWrap {
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
  }
}

.MetaDetailsFormImage {
  @media (min-width: 768px) {
    flex: 0 0 40%;
  }
}

.MetaDetailsFormImage__Preview {
  background: linear-gradient(160deg, lighten($color-neutral-lighter, 3%), $color-neutral-lighter);
  border-radius: $spacing-unit * 0.75;
  border: 1px solid $color-neutral-lighter;
  color: $color-neutral-darker;
  overflow: hidden;
  padding-top: math.div(630, 1200) * 100%;
  position: relative;
  cursor: pointer;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
  }

  svg {
    opacity: 0.1;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 4.5rem;
    width: 4.5rem;
    transform: translate(-50%, -50%);
  }
}

.MetaDetailsFormImage__Input {
  display: none;
}

.MetaDetailsFormText {
  margin-top: $spacing-unit * 3;

  @media (min-width: 768px) {
    margin-top: 0;
    padding-left: $spacing-unit * 4;
    flex: 1 0 60%;
  }
}

.MetaDetailsFormText__Input {
  width: 100%;
}

.MetaDetailsForm__HelpText__Icon {
  font-size: 0.85em;
  position: relative;
  top: 1px;
  opacity: 0.75;
}
