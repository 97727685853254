@import 'styles/mixins';
@import 'styles/variables';

.step {
  margin: $spacing-unit * 2 0;
}

.title {
  color: $color-primary-dark;
  font-size: pxrem(11);
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0 0 0.5em;
}

.paragraph {
  margin: 0;
}

.shortcode {
  background: $color-neutral-lighter;
  font-size: pxrem(14);
  padding: 1em;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  margin-bottom: $spacing-unit * 4;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba($color-neutral-lighter, 0), rgba($color-neutral-lighter, 1) 80%);
    width: 20%;
  }
}
