@use 'sass:math';

@import 'styles/variables';
@import 'styles/animations';

.item {
  border-radius: $spacing-unit;
  border: 1px dashed $color-neutral-light;
  display: flex;
  margin-bottom: $spacing-unit * 2;
  padding: $spacing-unit * 1.5;
  width: 100%;

  @media (min-width: $breakpoint-mobile-min) {
    padding: $spacing-unit * 2;
  }
}

.preview {
  display: none;

  @media (min-width: $breakpoint-mobile-min) {
    flex: math.div(200, 16) * 1rem 0 0;
    width: math.div(200, 16) * 1rem;
    min-width: math.div(200, 16) * 1rem;
    height: math.div(200, 16) * 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 0rem;
    background: #fff;

    filter: saturate(0.75);
    opacity: 0.8;
    transition:
      filter 0.15s,
      opacity 0.15s;

    &.slideshowPreview {
      height: math.div(180, 16) * 1rem;
    }

    .item:hover & {
      opacity: 1;
      filter: none;
    }
  }
}

.content {
  flex: 1 0 0;
  position: relative;

  @media (min-width: $breakpoint-mobile-min) {
    padding: $spacing-unit 0 0;
    margin-left: 3 * $spacing-unit;
  }
}

.removeButton {
  border: none;
  background: none;
  color: $color-neutral-lighter;
  cursor: pointer;
  line-height: 1;
  padding: $spacing-unit;
  position: absolute;
  right: -0.75rem;
  top: -0.75rem;
  transition: color 0.1s;

  @media (min-width: $breakpoint-mobile-min) {
    right: -0.35rem;
    top: -0.35rem;
  }

  &:focus,
  &:hover {
    color: $color-error;
  }

  svg {
    display: block;
    height: math.div(24, 16) * 1rem;
    width: math.div(24, 16) * 1rem;
  }
}

.name {
  font-weight: 700;
  color: $color-neutral-darker;
  font-size: math.div(20, 16) * 1rem;
  display: inline-block;
  margin-bottom: 1.5 * $spacing-unit;
  max-width: calc(100% - #{$spacing-unit * 5});

  &:active,
  &:focus,
  &:hover {
    color: black;
    text-decoration: none;
  }
}

.details {
  display: flex;
  color: $color-neutral-dark;
  margin-bottom: 2 * $spacing-unit;
  font-size: math.div(14, 16) * 1rem;

  @media (max-width: $breakpoint-mobile-max) {
    display: none;
  }
}

.detail {
  display: flex;
  min-width: math.div(110, 16) * 1rem;
  line-height: 1.5;
  flex-direction: column;

  @media (min-width: $breakpoint-mobile-min) {
    flex-direction: row;
  }

  &:not(:last-child) {
    margin-right: 5 * $spacing-unit;
  }
}

.detailTitle {
  color: $color-neutral-dark;
  white-space: nowrap;
  font-weight: 500;
  margin-right: 2 * $spacing-unit;
}

.slideshowUrl {
  background-color: #d9edf7;
  border-radius: 0.5rem;

  position: relative;
  min-height: 2.75rem;
  margin-bottom: 2 * $spacing-unit;
}

.slideshowUrlLink {
  padding: $spacing-unit;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2.75rem;
  font-size: math.div(12, 16) * 1rem;
  font-weight: 500;
  text-decoration: underline;
  color: $color-primary;

  &:hover {
    color: $color-primary-dark;
  }

  @media (min-width: $breakpoint-mobile-min) {
    font-size: math.div(14, 16) * 1rem;
  }
}

.slideshowUrlLinkIcon {
  margin-left: $spacing-unit;
}

.actions {
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-mobile-min) {
    flex-direction: row;
  }
}

.actionButton {
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 2px;
  color: $color-primary;
  cursor: pointer;
  display: flex;
  font-size: math.div(14, 16) * 1rem;
  font-weight: 600;
  justify-self: center;
  outline: none;
  padding: $spacing-unit * 0.5 0;
  text-decoration: none;
  transition: color 0.1s;

  &:not(:last-child) {
    margin-right: $spacing-unit * 4;
  }

  &:focus,
  &:hover {
    color: darken($color-primary, 5%);
    text-decoration: underline;
  }

  &:focus {
    box-shadow:
      0 0 0 4px #fff,
      0 0 0 6px $color-neutral-lighter;
    outline: none;
  }
}

.primaryActionButton {
  color: $color-secondary;

  &:focus,
  &:hover {
    color: $color-secondary-dark;
  }
}

.actionIcon {
  color: currentColor;
  border-radius: 50%;
  width: math.div(22, 16) * 1rem;
  height: math.div(22, 16) * 1rem;
  display: inline-block;
  margin-right: $spacing-unit;
  opacity: 0.85; // Compensate to visually match the text color
}

.demo {
  display: inline-flex;
  align-items: center;
  font-size: 0.625em;
  border-radius: 0.5em;
  padding: 0.25em 0.5em;
  margin-left: 0.75em;
  border: 1px solid $color-neutral-light;
  font-weight: 500;
  vertical-align: text-bottom;
}
