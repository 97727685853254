@use "sass:math";

@import 'styles/variables';

.linkButton {
  height: 2.5rem;
  line-height: 2.5rem;
  display: inline-flex;
  align-items: center;

  font-size: math.div(14, 16) * 1rem;
  font-weight: 500;
  color: $color-primary;
  padding: 0 2 * $spacing-unit;
  outline: none;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $color-primary-dark;
  }
}
