@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/variables';

.analyticsPage {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;

  max-width: pxrem(1100);
}

.mainHeading {
  font-size: pxrem(38);
  color: $color-neutral-darker;
  font-weight: 800;
  text-align: left;
  margin: 0;
  padding: 0;
  line-height: 1.25em;
  max-width: 800px;

  @media (max-width: $breakpoint-tablet-max) {
    font-size: pxrem(28);
  }

  @media (max-width: $breakpoint-mobile-max) {
    font-size: pxrem(24);
  }
}

.heading {
  font-size: pxrem(32);
  color: $color-neutral-darker;
  font-weight: 800;
  text-align: left;
  margin: 0 0 $spacing-unit;
  line-height: 1.25em;

  @media (max-width: $breakpoint-tablet-max) {
    font-size: pxrem(24);
  }

  @media (max-width: $breakpoint-mobile-max) {
    font-size: pxrem(20);
  }
}

.contentBlocks {
  padding: $spacing-unit * 2 0;

  .content {
    margin: $spacing-unit * 10 0;
  }

  .textCol {
    padding-top: 1rem;
    flex: 60% 0 0;
    width: 60%;
    margin-right: 3%;

    @media (max-width: $breakpoint-mobile-max) {
      flex: 100% 0 0;
      width: 100%;
      padding-top: 1rem;
    }
  }

  .visualization {
    padding: 0;
    border: none;
  }

  .text {
    margin-top: 2 * $spacing-unit;

    @media (max-width: $breakpoint-mobile-max) {
      margin-top: 2 * $spacing-unit;
    }
  }

  // even - odd - styles
  .content:nth-of-type(2n) {
    flex-direction: row-reverse;
    .textCol {
      margin: 0 0 0 3%;
    }

    .visualization {
      transform: perspective(75vw) rotateY(11deg) rotate(-4deg) skew(-4deg, 4deg);
    }
  }
}

.content {
  margin: 2 * $spacing-unit 0 6 * $spacing-unit;
  display: flex;
  justify-content: space-between;

  @media (max-width: $breakpoint-mobile-max) {
    flex-direction: column-reverse;
  }
}

.textCol {
  flex: 45% 0 0;
  width: 45%;
  margin-right: 3%;

  @media (max-width: $breakpoint-mobile-max) {
    flex: 100% 0 0;
    width: 100%;
  }
}

.visualizationCol {
  flex: 1 0 0;
  min-height: pxrem(250);
  padding-left: 0;
  position: relative;
  z-index: 1;

  @media (prefers-reduced-motion: no-preference) {
    animation: subtle-drop 0.65s ease-in-out;
  }

  @media (max-width: $breakpoint-mobile-max) {
    min-height: 0;
  }
}

.visualization {
  align-items: center;
  animation-fill-mode: forwards;
  background: #fff;
  border-radius: 15px;
  border: 1px solid $color-neutral-lighter;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 5px 10px 35px rgba(229, 237, 245, 0.6);
  display: block;
  display: flex;
  height: auto;
  justify-content: center;
  margin: 0;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  padding: 5px;
  position: relative;
  top: -10px;
  right: 10px;
  transform-origin: 50% 0%;
  transform: perspective(75vw) rotateY(-11deg) rotate(4deg) skew(4deg, -4deg);
  width: 100%;

  @media (max-width: $breakpoint-mobile-max) {
    transform: none;
    width: 100%;
    max-width: 500px;
    height: auto;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
}

.text {
  font-size: pxrem(20);
  line-height: 1.45;
  margin: 4 * $spacing-unit 0 4 * $spacing-unit;

  @media (max-width: $breakpoint-mobile-max) {
    font-size: pxrem(18);
    margin-top: 4 * $spacing-unit;
  }
}

.footerActions {
  margin-top: $spacing-unit * 3;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 3rem;

  &::before {
    top: 0;
    left: 50%;
    width: 45%;
    height: 1px;
    background: $color-neutral-lighter;
    transform: translate3d(-50%, 0, 0);
    position: absolute;
    content: '';
  }
}

.footerHeading {
  font-size: pxrem(38);
  color: $color-neutral-darker;
  font-weight: 800;
  text-align: left;
  margin: 0 0 $spacing-unit * 5;
  line-height: 1.25em;

  @media (max-width: $breakpoint-tablet-max) {
    font-size: pxrem(20);
  }

  @media (max-width: $breakpoint-mobile-max) {
    font-size: pxrem(18);
  }
}

.loadingDots:after {
  display: inline-block;
  animation: dotty steps(1,end) 2s infinite;
  content: '';
}

@keyframes dotty {
  0%   { content: ''; margin-right: 0; }
  25%  { content: '.'; margin-right: -0.225em; }
  50%  { content: '..'; margin-right: -0.45em; }
  75%  { content: '...'; margin-right: -0.675em; }
  100% { content: ''; margin-right: 0; }
}
