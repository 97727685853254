@use 'sass:math';

@import 'styles/variables';

.nav {
  background-color: $color-neutral-lighter;
  height: 6 * $spacing-unit;
  padding: 0 3 * $spacing-unit;
  display: flex;
  position: relative;

  @media (max-width: $breakpoint-mobile-max) {
    height: 5 * $spacing-unit;
    padding: 0;
    text-align: left;
  }
}

.horizontalNavigation {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: $breakpoint-mobile-max) {
    justify-content: flex-start;
  }
}

.navItem {
  background-color: rgba($color-neutral-lighter, 0);
  color: $color-neutral-dark;
  height: 6 * $spacing-unit;
  line-height: 6 * $spacing-unit;
  padding: 0 2.5 * $spacing-unit;
  text-decoration: none;
  font-size: math.div(15, 16) * 1rem;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  will-change: background-color, color;
  white-space: nowrap;

  svg {
    color: $color-neutral;
  }

  .horizontalNavigation & svg + span {
    display: none;
  }

  @media (max-width: $breakpoint-mobile-max) {
    font-size: math.div(13, 16) * 1rem;
    height: 5 * $spacing-unit;
    line-height: 5 * $spacing-unit;
    padding: 0 2 * $spacing-unit;

    &[data-hide-on-mobile] {
      display: none;
    }

    &[data-nav-item='dashboard'] {
      order: -2;
    }

    &[data-nav-item='feeds'] {
      order: -1;
    }
  }

  &:focus,
  &:hover {
    background-color: rgba($color-neutral-lighter, 0.25);
    color: $color-neutral-darker;
    text-decoration: none;

    svg {
      color: $color-primary;
    }
  }
}

.navItemActive {
  background: #fff;
  color: $color-neutral-darker;

  svg {
    color: $color-primary;
  }

  &:focus,
  &:hover {
    background: #fff;
    color: $color-neutral-darker;

    svg {
      color: $color-primary-dark;
    }
  }

  .navItemLabel {
    border-bottom: 2px solid $color-primary;
  }
}

.navItemLabel {
  padding-bottom: 2px;
}

.dropdown {
  position: relative;
}

.dropdownToggleButton {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: $color-primary;
  font-weight: 700;
  height: 6 * $spacing-unit;
  line-height: 6 * $spacing-unit;
  padding: 0 2.5 * $spacing-unit;
  border: 0;
  font-size: math.div(15, 16) * 1rem;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;

  @media (max-width: $breakpoint-mobile-max) {
    font-size: math.div(13, 16) * 1rem;
    height: 5 * $spacing-unit;
    padding: 0 2 * $spacing-unit;
  }

  &:hover {
    color: $color-primary-dark;
  }
}

.dropdownToggleButtonIcon {
  margin-left: 0.25em;
  height: 0.6em;
  width: 0.6em;
}

.dropdownWrap {
  padding: math.div(6, 16) * 1rem 0;
  position: absolute;
  z-index: 2;
  right: 0;
  background-clip: padding-box;
  background-color: #fff;

  border: 1px solid $color-neutral-light;
  border-radius: 0.25rem;
  margin-top: 0.25rem;

  @media (max-width: $breakpoint-mobile-max) {
    margin-right: 5px;
  }

  // tooltip
  &:before,
  &:after {
    border-style: solid;
    content: '';
    height: 0;
    position: absolute;
    bottom: 100%;
    width: 0;

    @media (max-width: $breakpoint-mobile-max) {
      margin-right: -10px;
    }
  }

  &:before {
    border-color: transparent transparent $color-neutral-light;
    border-width: 0 6px 6px;
    right: 21px;
  }

  &:after {
    border-color: transparent transparent #fff;
    border-width: 0 5px 5px;
    right: 22px;
  }
}

.dropdownLinks {
  min-width: 10rem;
  max-height: math.div(300, 16) * 1rem;
  overflow-x: auto;

  @media (max-width: $breakpoint-mobile-max) {
    min-width: 50vw;
  }

  .navItem {
    font-size: math.div(13, 16) * 1rem;
    height: 3.5 * $spacing-unit;
    line-height: 3.5 * $spacing-unit;
    font-weight: 500;
    width: 100%;
    transition: none;
    padding: 0 math.div(12, 16) * 1rem;
    display: block;

    svg {
      display: none;
    }

    @media (max-width: $breakpoint-mobile-max) {
      height: 5 * $spacing-unit;
      line-height: 5 * $spacing-unit;
      font-size: 1rem;
    }

    &:hover {
      background: $color-neutral-lighter;
    }
  }

  .navItemActive {
    color: $color-primary;
    font-weight: 600;

    &:focus,
    &:hover {
      background: #fff;
    }

    .navItemLabel {
      border-bottom: 0;
    }
  }
}
