@use 'sass:math';

@import 'styles/mixins';
@import 'styles/variables';

@keyframes fadeBgIn {
  0% {
    background-color: rgba($color-neutral-darker, 0);
  }

  100% {
    background-color: rgba($color-neutral-darker, 0.25);
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.ModalScroller {
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 300;
}

.ModalUnderlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color-neutral-darker, 0);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-name: fadeBgIn;
  transform: translate3d(0, 0, 0);
  width: 100vw;
}

.ModalWrapper {
  max-height: 100%;
  overflow: auto;
  padding: 0 $spacing-unit;
  width: 100vw;

  transform: scale(0);
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.135, 0.86, 0.32, 1);
  animation-delay: 0.2s;
  animation-duration: 0.3s;
  animation-name: scaleIn;
}

.ModalBox {
  background: #fff;
  border-radius: $spacing-unit;
  box-shadow: 0 0.15rem 0.5rem rgba($color-neutral-darker, 0.1), 0 1rem 2rem rgba($color-neutral-darker, 0.1);
  max-width: 100%;
  margin: $spacing-unit * 4 auto;
  padding: $spacing-unit * 5 $spacing-unit * 6;
  width: 43rem;
  position: relative;
  overflow: hidden;

  @media (max-width: $breakpoint-mobile-max) {
    padding: 2 * $spacing-unit;
  }
}

.close {
  background: transparent;
  color: $color-neutral-lighter;
  border: none;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: bold;
  position: absolute;
  top: 0.4rem;
  right: 0.25rem;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  padding: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $breakpoint-mobile-min) {
    top: 0.75rem;
    right: 0.75rem;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:hover {
    color: $color-neutral-light;
  }
}

.title {
  font-weight: 800;
  margin-top: 0;
  padding-top: 0;
  padding-right: 1.5rem;

  & + p {
    margin-bottom: 1rem;
  }

  @media (max-width: $breakpoint-mobile-max) {
    font-size: 1.75rem;
  }
}

.iframeHeight {
  max-width: 10rem;
  margin-bottom: $spacing-unit * 2;
  padding-right: 0;
}

$success-background: #e6f7ea;

.embed {
  background: $color-neutral-lighter;
  border-radius: 0.5rem;
  padding: $spacing-unit;

  overflow: hidden;
  position: relative;
  cursor: pointer;
  height: auto;

  transition: background 0.2s, color 0.2s;
  margin-bottom: 2 * $spacing-unit;

  &.embedSuccess {
    background-color: $success-background;
    color: $color-secondary-dark;
  }
}

.embedCode {
  padding: 0.1rem;

  display: flex;
  align-items: center;
  position: relative;
  font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  overflow: hidden;
  line-height: 1.5;
  font-size: math.div(11, 16) * 1rem;

  top: 0;
  bottom: 0;
  position: relative;
  white-space: pre;

  @media (min-width: $breakpoint-mobile-min) {
    padding: $spacing-unit;
    font-size: math.div(13, 16) * 1rem;
  }

  &:after {
    background: linear-gradient(90deg, rgba($color-neutral-lighter, 0), $color-neutral-lighter);
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 3rem;
    z-index: 2;

    .embedSuccess & {
      background: linear-gradient(90deg, rgba($success-background, 0), $success-background);
    }
  }

  pre {
    background: transparent !important;
    margin: 0 !important;
  }

  code {
    padding-left: 0 !important;
  }
}

.iframeCopyButton {
  min-width: 12rem;
}

.footer {
  @media (min-width: $breakpoint-mobile-min) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.footerLink {
  font-weight: 600;
  color: $color-neutral;
  font-size: pxrem(14);
  display: flex;
  align-items: center;

  @media (max-width: $breakpoint-mobile-max) {
    margin-top: 1.5rem;
  }

  svg {
    width: 1.1rem;
    height: 1.1rem;
    opacity: 0.8;
    margin-right: 0.5 * $spacing-unit;
  }
}
