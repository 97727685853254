@import 'styles/animations';
@import 'styles/variables';
@import 'styles/mixins';

.SubscriptionPlanSelect {
  text-align: center;
  padding: 3 * $spacing-unit 0 0;
}

.SubscriptionViewLoader {
  text-align: center;
  margin: 10% auto;
}

.SubscriptionIntro {
  margin-bottom: 5 * $spacing-unit;
  font-size: 1rem;
}

.section {
  margin: $spacing-unit 0 5 * $spacing-unit;
  transition: opacity 0.5s;

  @media (min-width: $breakpoint-mobile-min) {
    margin: $spacing-unit -#{$spacing-unit} 6 * $spacing-unit;
  }
}

.sectionDisabled {
  opacity: 0.4;
  user-select: none;
  pointer-events: none;
  touch-action: none;
}

.SubscriptionActions {
  text-align: center;
}

.SubscriptionFooter {
  border-top: 1px solid $color-neutral-lighter;
  margin-top: 2rem;
  padding-top: 2rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;

  a {
    color: $color-primary;

    &:hover {
      color: $color-primary-dark;
    }
  }

  @media (min-width: $breakpoint-mobile-min) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.SubmitButton {
  @media (min-width: $breakpoint-mobile-min) {
    min-width: 23rem;
  }
}

.ButtonPlanName {
  text-transform: capitalize;
}

.SubscriptionFeaturesLink {
  font-size: pxrem(14);
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: underline;
  }
}

.SubscriptionFeaturesLinkIcon {
  color: $color-neutral;
  width: 0.8rem;
  margin-left: 0.25rem;
}

.SubscriptionContactMessage {
  font-size: pxrem(14);
  margin-top: 3 * $spacing-unit;

  @media (min-width: $breakpoint-mobile-min) {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.SubscriptionContactMessageQuestion {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $breakpoint-mobile-min) {
    display: flex;
    align-items: center;
    margin: 0;
  }
}

.SubscriptionContactMessageIcon {
  color: $color-primary;
  margin-right: $spacing-unit;
  font-size: 1.15em;
}

.SubscriptionContactHelpscoutLink {
  margin: 0 $spacing-unit;
  text-decoration: underline;
}

.SubscriptionOptions {
  display: flex;
  flex-wrap: wrap;
}

.SubscriptionOption {
  align-items: center;
  border: 1px solid $color-neutral-lighter;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex: 100% 0 0;
  height: auto;
  justify-content: flex-start;
  line-height: initial;
  margin: $spacing-unit 0 0;
  padding: 2 * $spacing-unit;
  transition: border 0.1s;
  width: 100%;

  @media (min-width: $breakpoint-mobile-min) {
    margin: $spacing-unit;
    width: calc(50% - 1rem);
    flex: calc(50% - 1rem) 0 0;
    padding: 2.5 * $spacing-unit 3 * $spacing-unit;
  }

  &:hover {
    border-color: $color-primary;
  }

  &.selected {
    border-color: $color-primary;
    border-style: solid;
    box-shadow: inset 0 0 0 1px $color-primary;
  }

  input {
    display: none;
  }

  &.billingTypeOption {
    padding: 1rem 1.5rem;
  }

  .optionIcon {
    height: 1.75rem;
    width: 1.75rem;
    color: $color-neutral;
    margin-right: $spacing-unit * 2;
  }

  .radioReplacement {
    width: $spacing-unit * 3;
    height: $spacing-unit * 3;
    border-radius: 50%;
    border: $spacing-unit * 0.25 solid $color-neutral-lighter;
    margin-right: $spacing-unit * 2;
    position: relative;
    display: none;

    @media (min-width: $breakpoint-mobile-min) {
      margin-right: $spacing-unit * 3;
    }

    &:after {
      background: #fff;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      transform: scale(0.5);
      transition: all 0.15s ease-in-out;
    }
  }

  &.selected .radioReplacement:after {
    background: $color-primary-dark;
    transform: scale(0.8);
    transition: all 0.05s ease-in-out;
  }

  .content {
    display: flex;
    flex: 1 0 0;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }

  .name {
    color: $color-neutral-darker;
    display: flex;
    font-size: pxrem(18);
    font-weight: 700;
    gap: 0.4rem;
    line-height: $spacing-unit * 2.5;
    margin-bottom: 0.25rem;

    @media (min-width: $breakpoint-mobile-min) {
      font-size: pxrem(19);
      line-height: $spacing-unit * 3;
    }
  }

  .nameText {
    display: inline-block;
    vertical-align: middle;
  }

  .description {
    font-weight: 400;
    color: $color-neutral-dark;
    font-size: pxrem(13);
    line-height: $spacing-unit * 2.5;

    @media (min-width: $breakpoint-mobile-min) {
      font-size: pxrem(15);
    }
  }

  .price {
    margin-left: $spacing-unit * 2;
    min-width: 4rem;
    text-align: right;
  }

  .monthlyPrice {
    display: block;
    font-size: pxrem(16);

    font-weight: 700;
    line-height: $spacing-unit * 2.5;
    text-align: right;
    margin-bottom: 0.25rem;
    color: $color-neutral-darker;

    @media (min-width: $breakpoint-mobile-min) {
      font-size: pxrem(19);
      line-height: $spacing-unit * 3;
    }
  }

  .saveTag {
    background: #2aa051;
    border-radius: 30px;
    color: #fff;
    font-weight: 700;
    padding: 0.4rem 0.75rem;
    font-size: 1.1rem;
  }

  .priceLoader {
    position: relative;
    top: -4px;
    right: 5px;
  }

  .priceDescription {
    font-weight: 400;
    color: $color-neutral-dark;
    font-size: pxrem(12);
    line-height: $spacing-unit * 2.5;

    @media (min-width: $breakpoint-mobile-min) {
      font-size: pxrem(15);
    }
  }
}

.SubscriptionOptionPlaceholder {
  background: rgba($color-neutral-lighter, 0.5);
  font-size: 1rem;
  border: 1px solid $color-neutral-lighter !important;
  color: $color-neutral-dark;
  cursor: initial;

  @media (max-width: $breakpoint-mobile-max) {
    order: -1;
  }

  .content {
    align-items: center;
    justify-content: center;
  }
}

.currencyOptions {
  @media (min-width: $breakpoint-mobile-min) {
    display: inline-flex;
    align-items: center;
  }
}

.currencyOptionsTitle {
  @media (max-width: $breakpoint-mobile-max) {
    display: block;
    margin: 0 0 0.5rem;
    text-align: center;
  }
}

.currencyButton {
  background: transparent;

  border: none;
  color: $color-neutral-darker;
  font-size: 1em;
  display: inline-flex;

  cursor: pointer;
  font-weight: 500;
  align-items: center;
  margin: 0 1rem -0.5rem;
  padding: 0.5rem;

  @media (min-width: $breakpoint-mobile-min) {
    margin: 0 0 0 1.5rem;
    padding: 0;
  }

  &:hover {
    cursor: pointer;

    .radio {
      border-color: $color-neutral;
    }
  }

  &.selected {
    pointer-events: none;
    touch-action: none;

    .radio {
      border-color: $color-primary;

      &:after {
        background: $color-primary;
        transform: scale(1);
      }
    }
  }

  .radio {
    margin-right: 0.5rem;
    border: 2px solid $color-neutral-light;
    display: inline-block;
    position: relative;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    transition: border-color 0.2s;

    &:after {
      background: transparent;
      content: '';
      display: block;
      position: absolute;
      left: 3px;
      top: 3px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      transform: scale(0);
      transition: transform 0.2s;
    }
  }
}

.subTitle {
  color: $color-neutral-darker;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 0 1rem;
  text-align: center;
}

.discountBlock {
  line-height: 1.5;
  margin: 1rem 0;
}
