@use 'sass:math';

@import 'styles/animations';
@import 'styles/variables';

.loader {
  text-align: center;
  margin: 10% auto;
}

.displayPage {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;

  max-width: 98rem;
}

.mainHeading {
  font-size: math.div(32, 16) * 1rem;
  color: $color-neutral-darker;
  font-weight: 800;
  text-align: center;
  margin: 0 0 $spacing-unit;
  line-height: 1.25em;

  @media (max-width: $breakpoint-tablet-max) {
    font-size: math.div(28, 16) * 1rem;
  }

  @media (max-width: $breakpoint-mobile-max) {
    font-size: math.div(24, 16) * 1rem;
  }
}

.reliefStressText {
  color: $color-neutral-dark;
  text-align: center;
  font-size: math.div(18, 16) * 1rem;
  margin: 0;
  line-height: 1.25em;

  @media (max-width: $breakpoint-mobile-max) {
    font-size: math.div(15, 16) * 1rem;
  }
}

.chooseSectionForm {
  animation: fade-in 0.3s;
  margin: 3rem auto;
  padding: $spacing-unit 0.5 * $spacing-unit;
  max-width: math.div(660, 16) * 1rem;
  font-size: 1.2rem;

  &.slideshowSectionForm {
    max-width: math.div(500, 16) * 1rem;
  }

  @media (min-width: $breakpoint-mobile-min) {
    padding: 2.5 * $spacing-unit 3 * $spacing-unit 3 * $spacing-unit;
    border: 1px solid $color-neutral-lighter;
    border-radius: $spacing-unit;

    label {
      font-size: 1rem;
    }
  }
}

.sectionDropdown {
  display: block;
  margin-bottom: 1.25rem;
}

.sectionDropdownSelect {
  width: 100%;
  font-size: 1.1rem;
  height: 2.5rem;
  line-height: 2.5rem;
}

.sectionSelect {
  margin-bottom: 1rem;
  width: 100%;
  padding-left: 0;
}

.confirmSectionSelectButton {
  min-width: math.div(250, 16) * 1rem;
}

.displayType {
  text-transform: capitalize;
}

.back {
  margin: 2rem auto 1rem;
  text-align: center;

  @media (min-width: $breakpoint-mobile-min) and (min-height: 55rem) {
    margin: 3rem auto 1rem;
  }
}

.backLink {
  animation: fade-in 0.5s;
  align-items: center;
  display: inline-flex;

  background: transparent;
  border: 0;
  border-radius: 0.25rem;
  color: $color-neutral;
  cursor: pointer;
  font-size: 1em;
  font-weight: 500;
  padding: 0.65em 1.2em;
  text-decoration: none;
  transition: all 0.1s;
  outline: 0;
  user-select: none;

  &:hover,
  &:active {
    text-decoration: none;
    color: $color-primary;
  }
}
