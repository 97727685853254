.toolbar {
  color: rgba(50, 64, 70, 1);
  position: fixed;
  left: 1.5rem;
  bottom: 1.5rem;
  background: white;
  padding: 0.75rem 1rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 5px 20px rgba(0, 0, 0, 0.1), 0 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  font-weight: 700;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.icon {
  font-size: 1.1em;
  margin-right: 0.5em;
  margin-left: 1.5em;

  &:first-of-type {
    margin-left: 0;
  }
}

.label {
  text-decoration: underline;
}
