@use 'sass:math';

@import 'styles/variables';

$mobile-breakpoint: 768px;

.AccountTypeWrapper {
  margin-top: $spacing-unit * 4;

  & + & {
    border-top: 1px solid $color-neutral-lighter;
    margin-top: $spacing-unit * 4;
    padding-top: $spacing-unit * 4;
  }
}

.AccountTypeHeading {
  color: $color-neutral-darker;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;

  svg {
    height: 1.25em;
    width: 1.25em;
    margin-right: 0.5em;
    text-align: center;

    [data-channel='facebook'] & {
      color: $color-brand-facebook;
    }

    [data-channel='instagram business'] &,
    [data-channel='instagram basic'] & {
      color: $color-brand-instagram;
    }

    [data-channel='linkedin'] & {
      color: $color-brand-linkedin;
      position: relative;
      top: -2px;
      height: 1.15em;
      width: 1.15em;
    }

    [data-channel='mastodon'] & {
      color: $color-brand-mastodon;
    }

    [data-channel='tiktok'] & {
      color: #000;

      position: relative;
      top: -1px;

      path:first-of-type {
        fill: #fe2c55;
      }

      path:last-of-type {
        fill: #26f4ee;
      }
    }

    [data-channel='twitter'] & {
      color: $color-brand-twitter;
    }

    [data-channel='youtube'] & {
      color: $color-brand-youtube;
      height: 1.8em;
      width: 1.8em;
    }

    [data-channel='yammer'] & {
      color: $color-brand-yammer;
    }
  }
}

.AccountTypeContent {
  @media (min-width: $mobile-breakpoint) {
    display: flex;
    gap: $spacing-unit * 4;
  }
}

.AccountTypeDetails {
  flex-grow: 1;
}

.AccountTypeDescription {
  color: $color-neutral-dark;
  font-size: math.div(15, 16) * 1rem;
  margin-top: 0.5em;

  > * {
    margin-top: 1rem;
  }
}

.AccountTypeAccounts {
  margin-top: $spacing-unit * 4;

  @media (min-width: $mobile-breakpoint) {
    margin-top: 0;
    text-align: right;
  }
}
