@import 'styles/mixins';
@import 'styles/variables';

$mobile-breakpoint: 660px;
$avatar-width: pxrem(48);
$avatar-space: $spacing-unit * 2;
$avatar-area: $avatar-width + $avatar-space;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.listItem {
  border-top: 1px solid $color-neutral-lighter;
  align-items: center;
  display: block;
  width: 100%;
  padding: $spacing-unit * 1.5 0;
  opacity: 0;

  animation: fadeIn 0.6s ease-in-out;
  animation-fill-mode: forwards;

  @for $i from 1 through 20 {
    &:nth-child(#{$i}n) {
      animation-delay: #{$i * 0.1}s;
    }
  }

  &:first-child {
    border-top-color: transparent;
  }

  @media (min-width: $mobile-breakpoint) {
    align-items: center;
    justify-content: space-between;
    display: flex;
  }
}

.listItemRemoving {
  border-radius: $spacing-unit * 0.5;
  padding-left: $spacing-unit * 1.5;
  padding-right: $spacing-unit * 1.5;
  margin-left: $spacing-unit * -1.5;
  width: calc(100% + #{$spacing-unit * 1.5 * 2});
  background-color: #fbe9e7;
  border-color: #ead7d1 !important;
}

// User details ----------------------------------------------------------------

.user {
  display: inline-flex;
  align-items: center;
  width: 100%;

  @media (min-width: $mobile-breakpoint) {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

.avatar {
  flex-shrink: 0;
  height: $avatar-width;
  width: $avatar-width;
  margin-right: $avatar-space;

  img {
    border-radius: 100%;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.details {
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
}

.details__primary {
  display: block;
  font-weight: 600;
  font-size: pxrem(14);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.details__secondary {
  display: block;
  font-size: pxrem(13);
  color: $color-neutral;
  margin-top: $spacing-unit * 0.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

// Role ------------------------------------------------------------------------

.role {
  margin-left: $avatar-area;

  @media (min-width: $mobile-breakpoint) {
    flex: 0 0 auto;
    width: $spacing-unit * 28;
    margin-left: 0;
  }
}

// Actions ---------------------------------------------------------------------

.actions {
  flex: 0 0 auto;
  display: inline-flex;
  justify-content: flex-end;
  margin: $spacing-unit * 2 0 0 $avatar-area;

  @media (min-width: $mobile-breakpoint) {
    margin: 0;
    width: $spacing-unit * 12;
  }
}
