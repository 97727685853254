@import 'styles/animations';
@import 'styles/variables';
@import 'styles/mixins';

.subscriptionStatusCard {
  color: $color-neutral-darker;
  margin: $spacing-unit * 8 0;
  position: relative;

  @media (min-width: 740px) {
    display: flex;
  }
}

.subscriptionStatusCardLoadingLayer {
  position: absolute;
  border-radius: inherit;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
  animation: fade-in 0.2s;
  background: rgba(#fff, 0.7);
}

.leftColumn {
  @media (min-width: 740px) {
    flex: 1 0 0;
    width: 100%;
  }
}

.label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: $spacing-unit * 1;
  color: $color-neutral-dark;
  font-size: pxrem(14);
}

.planName {
  font-weight: 800;
  font-size: pxrem(32);
  margin: $spacing-unit 0 $spacing-unit * 3;
}

.planChange {
  font-weight: 600;
  color: $color-neutral;
  margin-top: 0;
  font-size: pxrem(14);
}

.details {
  width: 100%;

  @media (min-width: $breakpoint-mobile-min) {
    display: flex;
    justify-content: space-between;
  }

  > div {
    flex: 1 0 0;

    @media (min-width: $breakpoint-mobile-min) {
      &:not(:last-of-type) {
        padding-right: $spacing-unit;
      }
    }

    @media (max-width: $breakpoint-mobile-max) {
      margin-bottom: $spacing-unit * 3;
    }

    .detail + .detail {
      margin-top: $spacing-unit * 3;
    }
  }
}

.detail {
  display: block;
  word-break: break-word;
  font-weight: 400;

  & > div {
    margin-bottom: 0.25rem;
  }

  .detailHighlightValue {
    font-weight: bold;
  }

  li {
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: 1.3rem;
  }

  .detailLabel {
    color: $color-primary-dark;
    font-size: pxrem(11);
    font-weight: 700;
    letter-spacing: 0.1em;
    margin-bottom: $spacing-unit;
    text-transform: uppercase;
  }
}

.detailsActions {
  margin-top: 1.3rem; // to align with "Update credit card" button
}

@mixin link-button {
  text-decoration: underline;
  background: transparent;
  background: transparent;
  border: none;
  appearance: none;
  font-size: pxrem(14);
  font-weight: 600;
  cursor: pointer;
  outline: none;
  padding: 0;
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  svg {
    width: 1.2rem;
    height: 1.2rem;
    display: inline-block;
    margin-right: $spacing-unit;
  }

  &:hover {
    text-decoration: underline;
  }
}

.linkButton {
  @include link-button();
  color: $color-primary;

  &:hover {
    color: $color-primary-dark;
  }
}

.actions {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;

  & > *:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.actionHelp {
  font-size: pxrem(14);
  font-weight: 500;
  color: $color-neutral-dark;
}

.cancelAction {
  @include link-button();
  color: $color-error;

  &:hover {
    color: $color-error-dark;
  }
}

.updateAction {
  @include link-button();
  color: $color-primary;
  &:hover {
    color: $color-primary-dark;
  }
}

.rightCol {
  max-width: pxrem(360);
  margin-top: 2rem;

  @media (min-width: 740px) {
    margin: 0 0 0 1.5rem;
    width: pxrem(360);
    flex: pxrem(360) 0 0;
  }
}

.creditCard {
  background-color: #fff;
  background-image: linear-gradient(to bottom, rgba($color-neutral-lighter, 0.1), rgba($color-neutral-lighter, 0.55)),
    linear-gradient(120deg, transparent 60%, rgba($color-neutral-lighter, 0.35) 60%);
  border: 1px solid $color-neutral-lighter;

  padding: 0.75rem 1.5rem;
  border-radius: 1rem;
  height: 100%;
  color: $color-neutral-dark;
  font-weight: bold;

  font-size: pxrem(14);
  transition: all 0.5s;

  @media (min-width: 400px) {
    max-height: pxrem(220);
    min-height: pxrem(220);
  }

  &.disabled {
    opacity: 0.3;
  }
}

.creditCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0.25rem;
}

.creditCardBrand {
  animation: fade-in 0.2s;
  color: $color-neutral-light;

  width: pxrem(38);
  height: pxrem(38);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
}

.creditCardPrice {
  font-weight: 800;
  font-size: pxrem(40);
  margin: 0 0 $spacing-unit * 5;
}

.creditCardPriceVat {
  font-size: 0.35em;
  font-weight: 600;
  display: inline-block;
  margin-left: $spacing-unit;
  position: relative;
  top: -1px;
  color: $color-neutral;
}

.creditCardPriceLoader {
  opacity: 0.5;
  position: relative;
  top: -0.5rem;
}

.creditCardHolder {
  min-height: 1rem;
  margin-bottom: $spacing-unit;
  color: $color-neutral-dark;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.creditCardNumbers {
  animation: fade-in 0.2s;

  display: flex;
  justify-content: space-between;
  min-height: 1rem;
  letter-spacing: 0.5px;
  color: $color-neutral-dark;
  opacity: 0.5;
  flex-direction: column;
  font-weight: 600;

  @media (min-width: 400px) {
    flex-direction: row;
  }
}

.creditCardAsterisk {
  font-weight: 400;
  margin-right: $spacing-unit;
}

.creditCardActions {
  margin-top: $spacing-unit * 2;
  text-align: center;
}

.unsubscribedActions {
  margin-top: $spacing-unit * 2;
  text-align: center;

  @media (min-width: 480px) {
    text-align: left;
  }

  > * {
    margin: $spacing-unit * 2 $spacing-unit * 2 0 0;
  }

  span {
    display: inline-block;
  }
}
