@import 'styles/variables';
@import 'styles/mixins';

.browser {
  background: $color-neutral-lighter;
  border: 9px solid $color-neutral-lighter;
  border-radius: 10px;
  min-height: 500px;
  overflow: hidden;
  position: relative;

  // preview scroll-prevent-layer
  &::after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 33px;
    z-index: 2;
  }

  @include breakpoint($breakpoint-mobile-max, 'max') {
    background: $color-neutral-lighter;
    border: none;
    border-radius: 0.2rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.075), 0 8px 16px rgba(0, 0, 0, 0.1);
    height: 44.5vh;
    margin: 0 -0.5rem !important;
    min-height: 0;
    overflow: hidden;
    padding: 20px 0 0;
    position: relative;
  }

  .iframe {
    background: #fff;
    border: none;
    height: 800px;
    left: 101px;
    margin: 10px 0 0 -100px;
    position: absolute;
    top: 25px;
    transform-origin: 0 0;
    transform: scale(calc(700 / 1300)); // ~700 is actual width of iframe in desktop mode
    width: 1300px;

    @include breakpoint($breakpoint-mobile-max, 'max') {
      background: #fff;
      border: none !important;
      border-radius: 0 !important;
      bottom: 0 !important;
      height: 125vh !important;
      left: 0 !important;
      margin: 0;
      position: absolute !important;
      right: 0 !important;
      top: 20px !important;
      transform-origin: 0 0 !important;
      transform: scale(0.33) !important;
      width: 291vw !important;
    }
  }

  &.pipMode .iframe {
    @include breakpoint($breakpoint-embed-pip-mode) {
      margin-top: 0;
      top: 0;
      transform: scale(0.31);
    }

    @include breakpoint(1090px) {
      transform: scale(0.407);
    }
  }
}

.browserButtons {
  position: absolute;
  left: 12px;
  top: 8px;

  @include breakpoint($breakpoint-mobile-max, 'max') {
    left: 7px;
    top: 6px;
  }

  @include breakpoint($breakpoint-embed-pip-mode) {
    .pipMode & {
      display: none;
    }
  }

  span {
    background: $color-neutral-light;
    border-radius: 50%;
    content: '';
    display: block;
    height: 12px;
    position: absolute;
    top: 0;
    width: 12px;

    &:first-child {
      // background: #f06767;
      left: 0;
    }

    &:nth-child(2) {
      // background: #eed06d;
      left: 20px;
    }

    &:nth-child(3) {
      // background: #6dee9e;
      left: 40px;
    }

    @include breakpoint($breakpoint-mobile-max, 'max') {
      height: 8px;
      width: 8px;

      &:first-child {
        left: 0;
      }

      &:nth-child(2) {
        left: 12px;
      }

      &:nth-child(3) {
        left: 24px;
      }
    }
  }
}

.browser.carousel {
  background: transparent;
  border: 1px solid $color-neutral-lighter;
  border-radius: 3px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.025);
  margin: 0;
  min-height: 0;
  overflow: hidden;
  position: static;

  @media (min-width: 920px) {
    margin: 0 -90px;
  }

  @media (min-width: 1120px) {
    margin: 0 -180px;
  }

  @include breakpoint($breakpoint-mobile-max, 'max') {
    height: 260px;
  }

  .iframe {
    background: #fff;
    border: none;
    height: 440px;
    left: 0;
    margin: 0;
    position: relative;
    top: 0;
    transform: none;
    width: 100%;

    @include breakpoint($breakpoint-mobile-max, 'max') {
      background: #fff;
      border: none !important;
      border-radius: 0 !important;
      bottom: 0 !important;
      height: 480px !important;
      left: 0 !important;
      margin: 0;
      position: absolute !important;
      right: 0 !important;
      top: 10px !important;
      transform-origin: 0 0 !important;
      transform: scale(0.5) !important;
      width: 200% !important;
    }

    @media (min-width: 920px) {
      width: 100%;
    }

    @media (min-width: 1120px) {
      width: 100%;
    }
  }

  &.pipMode {
    margin: 0;

    .iframe {
      width: 1024px;
      height: 450px;
      border-radius: 4px;

      @include breakpoint($breakpoint-embed-pip-mode) {
        margin-top: 0;
        top: 0;
        transform: scale(0.391);
      }

      @include breakpoint(1090px) {
        transform: scale(0.514);
      }
    }
  }

  .browserButtons {
    display: none;
  }
}

.browser.slideshow {
  .iframe {
    width: 1280px;
  }
}

.browser[data-height='540'] {
  .iframe,
  &.pipMode .iframe {
    height: 540px;
  }
}
