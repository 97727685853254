@use 'sass:math';

@import 'styles/variables';
@import 'styles/mixins';

.error {
  color: $color-error-dark;
  display: inline-block;
  font-size: math.div(14, 16) * 1rem;
  font-weight: 600;
}
