@use 'sass:math';

$form-field-font-size: math.div(15, 16) * 1rem;
$form-field-font-size--mobile: 1rem;
$form-field-height: 6 * $spacing-unit;
$form-field-padding-horizonal: 1.5 * $spacing-unit;

.input,
input[type='text'],
input[type='email'],
input[type='password'],
input[type='url'],
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid $color-neutral-light;
  border-radius: $spacing-unit * 0.75;
  box-shadow: 0 0 0 4px rgba($color-neutral-lighter, 0);
  box-sizing: border-box;
  color: $color-neutral-darker;
  font-family: inherit;
  font-size: $form-field-font-size;
  height: $form-field-height;
  line-height: $form-field-height;
  padding: 0 $form-field-padding-horizonal;
  width: 100%;
  transition: all 0.4s ease-in-out;
  will-change: box-shadow;

  @media (prefers-reduced-motion) {
    transition: none;
  }

  @media (max-width: $breakpoint-mobile-max) {
    font-size: $form-field-font-size--mobile; // Prevents the zoom effect on mobile
  }

  &:hover {
    box-shadow: 0 0 0 4px $color-neutral-lighter;
  }

  &:disabled:hover {
    box-shadow: 0 0 0 4px rgba($color-neutral-lighter, 0);
  }

  &:focus {
    border-color: $color-primary;
    outline: none;
  }

  &:disabled {
    background-color: $color-neutral-lighter;
    background-image: linear-gradient(
      135deg,
      $color-neutral-lighter 12.5%,
      #fff 12.5%,
      #fff 50%,
      $color-neutral-lighter 50%,
      $color-neutral-lighter 62.5%,
      #fff 62.5%,
      #fff 100%
    );
    background-size: 6px 6px;
    border-color: $color-neutral-lighter;
    color: $color-neutral;
    cursor: not-allowed;
  }

  &::placeholder {
    color: $color-neutral;
  }

  &[aria-invalid='true'] {
    border-color: $color-error;
  }

  // Approximate this to Button component small height
  &.input--small {
    font-size: math.div(13, 16) * 1rem;
    height: 4.47 * $spacing-unit;
    line-height: 4.4 * $spacing-unit;
  }

  &.input--large {
    font-size: 1.25rem;
    height: 3.5rem;
    line-height: 3.5rem;
  }
}

textarea {
  padding-bottom: 1.5 * $spacing-unit;
  padding-top: 1.5 * $spacing-unit;
  height: 12 * $spacing-unit;
  min-height: 12 * $spacing-unit;
  line-height: 3 * $spacing-unit;
  resize: vertical;
}

input {
  &:disabled {
    cursor: not-allowed;
  }
}

select {
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%228px%22%20height%3D%2216px%22%20viewBox%3D%222288%20312%208%2016%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cg%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20transform%3D%22translate(2288.000000%2C%20312.000000)%22%3E%3Cpolygon%20fill%3D%22%2337474F%22%20points%3D%220%2010%202.98177083%2010%208%2010%204%2016%22%3E%3C%2Fpolygon%3E%3Cpolygon%20fill%3D%22%2337474F%22%20points%3D%224%200%208%206%205.01822917%206%200%206%22%3E%3C%2Fpolygon%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 8px center;
  padding-right: 3 * $spacing-unit;

  &.input--small {
    background-size: 6px auto;
  }
}

.input-prepend {
  color: $color-neutral;
  position: absolute;
  top: 0;
  left: $form-field-padding-horizonal;
  font-size: $form-field-font-size;
  height: $form-field-height;
  line-height: $form-field-height;

  @media (max-width: $breakpoint-mobile-max) {
    font-size: $form-field-font-size--mobile; // Prevents the zoom effect on mobile
  }
}

label,
.label__placeholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 4 * $spacing-unit;
  line-height: 1.3;

  font-size: math.div(14, 16) * 1rem;
  font-weight: 600;

  input[type='radio'] + &,
  input[type='checkbox'] + & {
    display: inline-block;
    font-weight: 400;
    margin-left: $spacing-unit;
  }

  &.justify-start {
    justify-content: flex-start;
  }
}

.label__context {
  display: inline-block;
  color: $color-neutral;
  font-size: math.div(13, 16) * 1rem;
  font-weight: 400;
}

.form__error {
  color: $color-error;
  font-size: math.div(14, 16) * 1rem;
  font-weight: 500;
  line-height: 4 * $spacing-unit;
}

.form__submit-row {
  display: flex;
  align-items: center;

  @media (max-width: 440px) {
    display: block;

    > * + * {
      margin-top: 2 * $spacing-unit;
    }
  }
}
