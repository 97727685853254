.aiModerationStatusCard {
  padding-bottom: 2rem;
}

.aiModerationStatusCard h2 {
  margin-bottom: 0.625rem;
  font-weight: bold;
  font-size: 1.5rem;
}

.aiModerationStatusCard p {
  margin: 0.625rem 0;
}

.aiModerationStatusCard strong {
  font-weight: bold;
}

.aiModerationStatusCard a {
  color: #007bff;
  text-decoration: none;
}

.aiModerationStatusCard a:hover {
  text-decoration: underline;
}
