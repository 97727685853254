@use 'sass:math';

@import 'styles/variables';
@import 'styles/mixins';

.wrap {
  display: block;
  margin: 0 0 3 * $spacing-unit;
}

.embedCode {
  background: $color-neutral-lighter;
  border: 1px dashed transparent;
  cursor: pointer;
  display: block;
  font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  font-size: 13px;
  margin: 0 0 12px;
  overflow: hidden;
  padding: 0.75em 0.25em 0.5em;
  position: relative;
  transition: all 0.1s;

  @include breakpoint($breakpoint-mobile-min) {
    padding: 1.5em 1em 1em;
  }

  pre {
    background-color: inherit !important;
  }

  code {
    overflow: hidden !important;
  }

  // Hover copy link
  &::before {
    background: rgba(0, 0, 0, 0.075);
    color: #263238;
    content: 'Copy embed code';
    font-family: 'proxima-nova', 'Helvetica Neue', sans-serif;
    font-size: 0.5625rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    padding: 0.75em 1em;
    position: absolute;
    top: 0;
    right: 0;
    text-transform: uppercase;
    z-index: 3;
    opacity: 0;
    transform: translate3d(0, -2px, 0);
    transform-origin: 0 0;
    transition: all 0.1s;
  }

  &.iframeEmbedCode::before {
    content: 'Copy iframe code';
  }

  // Fade-out effect
  &::after {
    background: linear-gradient(90deg, rgba($color-neutral-lighter, 0), $color-neutral-lighter);
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 3rem;
    z-index: 2;
  }

  &:hover {
    border-color: $color-neutral;

    &::before {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

.actions {
  margin-top: 2 * $spacing-unit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: math.div(75, 16) * 1rem;

  @include breakpoint($breakpoint-mobile-max) {
    min-height: auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.tabs {
  display: flex;
  justify-content: flex-start;

  @media (min-width: $breakpoint-mobile-min) {
    justify-content: flex-end;
    margin-top: -1rem;
  }
}

.tab {
  background: #fff;
  border: 1px solid $color-neutral-lighter;
  border-radius: 0.25rem 0.25rem 0 0;
  color: $color-neutral-darker;
  cursor: pointer;
  font-size: pxrem(9.5);
  font-weight: 600;
  letter-spacing: 1px;
  padding: 0.5rem 0.75rem;
  text-transform: uppercase;
  transition: all 0.2s;

  &.default {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &.selected {
    background: $color-neutral-lighter;
  }
}
