@use 'sass:math';

@import 'styles/variables';

.userProfile {
  color: #fff;
  font-size: math.div(14, 16) * 1rem;
  height: 4 * $spacing-unit;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 4 * $spacing-unit;
  text-decoration: none;

  @media (min-width: 768px) {
    padding-right: 2 * $spacing-unit;
  }

  &:focus,
  &:hover {
    color: #fff;

    .userProfileName {
      text-decoration: underline;
    }
  }
}

.userProfileNavToggle {
  appearance: none;
  background: none;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  height: 100%;
  padding: 0;
  position: relative;
  z-index: 201;
}

.userProfileNavUnderlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
}

.userProfileNav {
  background-color: #fff;
  border-radius: $spacing-unit * 0.5;
  box-shadow: 0 2px 1rem rgba(#000, 0.1);
  border: 1px solid $color-neutral-lighter;
  position: absolute;
  top: 5.5 * $spacing-unit;
  right: 0.5 * $spacing-unit;
  min-width: 8rem;
  overflow: hidden;
  z-index: 202;
  padding: $spacing-unit * 0.5 0;
}

.userProfileNavItem {
  background-color: #fff;
  color: $color-neutral-dark;
  display: block;
  padding: $spacing-unit * 0.5 2 * $spacing-unit;
  text-decoration: none;

  &:focus,
  &:hover {
    background-color: $color-neutral-lighter;
    color: $color-neutral-darker;
  }
}

.userProfileAvatar {
  height: 100%;

  @media (min-width: 768px) {
    margin-right: $spacing-unit;
  }

  img {
    border: $spacing-unit * 0.25 solid #fff;
    border-radius: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
    width: auto;
  }
}

.userProfileName {
  font-weight: 600;

  @media (max-width: $breakpoint-tablet-max) {
    display: none;
  }
}
