@use 'sass:math';

@import 'styles/animations';
@import 'styles/variables';

$success-icon-color: $color-secondary-light;

.subscriptionSuccess {
  padding: $spacing-unit * 2;
  text-align: center;
  max-width: math.div(600, 16) * 1rem;
  margin: 0 auto;
}

@keyframes round {
  from {
    stroke-dashoffset: 452;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes scale-in {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.successIcon {
  display: block;
  text-align: center;
  margin: -5% auto;
  transform: scale(0.7);
}

.okSign {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  position: relative;
}

.icon {
  position: absolute;
  width: 70px;
  height: 70px;
  left: 50%;
  top: 50%;
  width: 70px;
  height: 70px;
  text-align: center;
  margin: -35px 0 0 -35px;

  transform: scale(0);
  animation: scale-in 0.35s cubic-bezier(0.75, -0.5, 0, 1.75);
  animation-fill-mode: forwards;
  animation-delay: 0.7s;
  transform-origin: 50% 50%;
  z-index: 11;

  &::after {
    border-color: $success-icon-color;
    border-style: solid;
    border-width: 0 6px 6px 0;
    content: '';
    display: block;
    height: 60px;
    left: 20px;
    position: absolute;
    top: -5px;
    transform: rotate(45deg);
    width: 30px;
  }
}

.progress {
  -webkit-transform: rotate(-90deg) scale(1);
  transform: rotate(-90deg) scale(1);
  transform-origin: 50% 50%;
  display: block;
  position: relative;
  background: transparent;
  margin: 0 auto;
  top: 25px;
  z-index: 10;
  width: 150px;
  height: 150px;
}

.circleAnimation {
  position: absolute;
  z-index: 2;
  stroke-dasharray: 452;
  stroke-dashoffset: 452;
  stroke: $success-icon-color;
  stroke-width: 5;
  animation: round 0.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.25s;
}

.title {
  color: $color-neutral-darker;
  font-weight: 800;
  font-size: math.div(28, 16) * 1rem;
  margin: 0.5rem 0 1rem;
}

.text {
  font-size: 1.1rem;
  margin-bottom: $spacing-unit * 3;
  color: $color-neutral-dark;
}

.content {
  opacity: 0;
  animation: subtle-drop 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 1.25s;
}
