@import 'styles/animations';
@import 'styles/variables';
@import 'styles/mixins';

.SubscriptionManageFeeds {
  text-align: center;
  padding: 3 * $spacing-unit 0 0;
}

.section {
  margin: $spacing-unit 0;
  transition: opacity 0.5s;

  @media (min-width: $breakpoint-mobile-min) {
    margin: $spacing-unit -#{$spacing-unit};
  }
}

.SubscriptionActions {
  text-align: center;
}

.SubmitButton {
  @media (min-width: $breakpoint-mobile-min) {
    min-width: 23rem;
  }
}

.feedOption {
  align-items: center;
  border: 1px solid $color-neutral-lighter;

  cursor: pointer;
  display: flex;
  flex: 100% 0 0;
  height: auto;
  justify-content: flex-start;
  line-height: initial;
  margin: 0;
  padding: 2 * $spacing-unit 2 * $spacing-unit;
  transition: border 0.1s;
  width: 100%;
  position: relative;

  &:first-of-type {
    border-radius: 6px 6px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 6px 6px;
  }

  &:not(:first-of-type) {
    margin-top: -1px;
  }

  &:hover {
    border-color: $color-primary;
    z-index: 2;
  }

  &.selected {
    border-color: $color-primary;
    border-style: solid;
    z-index: 3;
  }

  input {
    display: none;
  }

  .checkboxReplacement {
    width: $spacing-unit * 3;
    height: $spacing-unit * 3;
    border-radius: 3px;
    border: $spacing-unit * 0.25 solid $color-neutral-lighter;
    margin-right: $spacing-unit * 2;
    position: relative;

    &:after {
      background: #fff;
      width: 100%;
      height: 100%;
      border-radius: 2px;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      transform: scale(0.5);
      transition: all 0.15s ease-in-out;
    }
  }

  &.selected .checkboxReplacement:after {
    background: $color-secondary;
    background: $color-primary;
    transform: scale(0.8);
    transition: all 0.05s ease-in-out;
  }

  .content {
    display: flex;
    flex: 1 0 0;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
  }

  .name {
    display: block;
    font-size: pxrem(14);
    font-weight: 600;
    line-height: $spacing-unit * 2.5;
    color: $color-neutral-darker;

    @media (min-width: $breakpoint-mobile-min) {
      font-size: pxrem(14);
      line-height: $spacing-unit * 3;
    }
  }

  .description {
    font-weight: 400;
    color: $color-neutral;
    font-size: pxrem(13);
    line-height: $spacing-unit * 2.5;
  }
}

.subTitle {
  color: $color-neutral-darker;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 0 1rem;
  text-align: center;
}

.textGreen {
  color: $color-secondary;
}
