@import 'styles/variables';
@import 'styles/mixins';

.displayListPreview {
  background: transparent;
  border: 1px solid $color-neutral-lighter;
  border-radius: 0.4rem;
  overflow: hidden;
  position: relative;
  height: 0;
  pointer-events: none;
  touch-action: none;
  user-select: none;
  width: 100%;

  padding-top: 100%;

  &.slideshow {
    padding-top: 65%;
  }

  &.themeDark {
    background: #000;
  }

  &::after {
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
}

.iframe {
  background: #fff;
  border: none;
  height: 1350px;
  top: 0;
  left: 0;
  margin: 0;
  position: absolute;
  transform-origin: 0 0;
  transform: scale(0.15);
  width: calc(100% * (10 / 1.5) + 10px);

  .carousel & {
    transform: scale(0.25);
    width: calc(100% * (10 / 2.5));
    top: 3rem;
    height: 700px;
  }

  .themeDark & {
    background: #000;
  }

  .slideshow & {
    transform: scale(0.125);
    width: 1600px;
    height: 1040px;
  }
}
