@import 'styles/variables';

.page {
  margin: 0 auto;
  max-width: 120 * $spacing-unit;
  padding: 6 * $spacing-unit 2 * $spacing-unit;

  @media (max-width: $breakpoint-mobile-max) {
    padding: 5 * $spacing-unit 2 * $spacing-unit;
  }
}
