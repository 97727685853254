@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/variables';

.deleteActions,
.cancelAction {
  @media (max-width: $breakpoint-mobile-max) {
    flex: 100% 0 0;
    width: 100%;
    min-width: 100%;

    button {
      width: 100%;
      margin: 0 0 0.5rem !important;
    }
  }
}

.deleteActions {
  @media (max-width: $breakpoint-mobile-max) {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
  }
}
