@import 'styles/animations';
@import 'styles/variables';
@import 'styles/mixins';

.invoiceList {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  tr {
    animation: fade-in 0.2s;
  }

  td,
  th {
    padding: $spacing-unit;
  }

  th {
    font-size: pxrem(14);
    font-weight: 600;
    text-align: left;
    border-bottom: 2px solid $color-neutral-lighter;
  }

  td {
    vertical-align: middle;
    font-size: pxrem(15);

    border-bottom: 1px solid $color-neutral-lighter;
  }

  tbody tr {
    &:last-child td {
      border-bottom-width: 2px;
    }
    &:hover {
      background: $color-neutral-lighter;
    }
  }

  // mobile styles
  @media (max-width: $breakpoint-mobile-max) {
    thead {
      display: none;
    }

    tbody tr {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border-bottom: 1px solid $color-neutral-lighter;
      padding: $spacing-unit * 2 0;
      position: relative;

      &:first-of-type {
        padding-top: $spacing-unit;
      }

      &:hover {
        background: #fff;
      }

      &.dueInvoiceRow {
        &:after {
          display: block;
          position: absolute;
          left: -$spacing-unit * 2;
          bottom: 0;
          top: 0;
          content: '';
          width: 4px;
          background: $color-error;
        }
      }
    }

    td {
      display: block;
      font-size: pxrem(15);
      border: none;
      padding: 0;
      flex: 50%;
    }

    // date
    .dateColumn {
      order: 1;
      flex: 75% 0 0;
      width: 100%;
      font-weight: 600;
    }

    // description
    .descriptionColumn {
      order: 3;
      flex: calc(100% - 3.25rem) 0 0;
      font-size: pxrem(13);
      padding-top: 0.2rem;
      color: $color-neutral;

      margin-bottom: $spacing-unit * 2;
    }

    // total
    .totalColumn {
      order: 2;
      flex: 25% 0 0;
      text-align: right;
      margin: 0 0 $spacing-unit;
      font-weight: 600;
    }

    .statusColumn {
      order: 4;
      flex: 3.25rem 0 0;

      border-radius: 30px;
      font-size: pxrem(10);
      text-transform: uppercase;
      letter-spacing: 0.1em;
      height: 1.35rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      text-align: center;
      line-height: 1;

      // Paid style as default
      background: #daece9;
      color: $color-secondary-dark;

      &.dueStatusColumn {
        background: #f2dede;
        color: $color-error-dark;
      }

      &.voidStatusColumn {
        background: $color-neutral-lighter;
        color: $color-neutral;
      }
    }

    // link
    .actionColumn {
      order: 5;
      text-align: left;
      flex: 100% 0 0;

      a {
        font-size: pxrem(15);
        text-decoration: underline;
      }
    }
  }

  .voidInvoiceRow {
    color: $color-neutral;
  }
}

.disabled {
  pointer-events: none;

  td {
    border: none !important;
  }
}

.loader {
  margin: 3% auto;
}
